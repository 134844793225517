import { Box } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import FormProvider from 'src/components/hook-form';
import Image from 'src/components/image';
import { useApplicationFlowContext } from 'src/contexts';

import ControlRow from '../control-row';
import QuestionCard from '../partials/question-card';
import formDefaults from '../pcdl-wiz/form-defaults';
import formOptions from '../pcdl-wiz/form-options';
import formSchemas from '../pcdl-wiz/form-schemas';
import { TabExperienceForm } from '../pcdl-wiz/form-types';
import formValues from '../pcdl-wiz/form-values';
import RHFRadioGroupStyled from '../pcdl-wiz/rhf-radio-group-styled';

export interface TabExperienceProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabExperience(props: TabExperienceProps) {
  const {
    loading,
    onBack,
    onNext,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();

  const methods = useForm<TabExperienceForm>({
    resolver: !applicationFlowContext.isPreview
      ? yupResolver(formSchemas.tabExperience)
      : undefined,
    defaultValues: applicationFlowContext.application
      ? formDefaults.tabExperience(applicationFlowContext.application.profile)
      : undefined,
  });

  const onSubmit = methods.handleSubmit(
    (data: TabExperienceForm) => {
      if (!applicationFlowContext.application?.profile) {
        throw new Error('Failed to update experience');
      }

      const updatedCandidateProfile = formValues.onNextExperience(
        applicationFlowContext.application.profile,
        data,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileExperience']({
        experience: updatedCandidateProfile.experience,
      });
      onNext();
    },
    (i) => console.error(i),
  );

  return (
    <FormProvider methods={methods}>
      <Box display="flex" flexDirection="column" rowGap={2}>

        <Box display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 3 }}>
          <Image
            height="120px"
            src="/assets/illustrations/candidate-application-job-experience.svg"
          />
        </Box>

        <QuestionCard
          title="How much related work experience do you have for this position?"
          subTitle="Only consider related work experience"
        >
          <RHFRadioGroupStyled
            name="experienceLevel"
            options={formOptions.experienceLevel}
          />
        </QuestionCard>

        <ControlRow
          onBack={onBack}
          onNext={onSubmit}
          loading={loading}
          isEditing={applicationFlowContext.isEditing}
        />
      </Box>

    </FormProvider>
  );
}
