import services from '@piccadilly-cloud/connect-platform-services';

import { Box, TextField, Typography, useTheme } from '@mui/material';

import { useState } from 'react';

import { useSessionContext } from 'src/contexts';
import { useLoading, useToast } from 'src/hooks';

import CustomDialog from './custom-dialog';

export interface RequestCreditsDialogEmits {
  close?: () => void;
}

export interface RequestCreditsDialogProps {
  open: boolean;
  emit?: RequestCreditsDialogEmits;
}

export default function RequestCreditsDialog(props: RequestCreditsDialogProps) {
  const { emit, open } = props;
  const session = useSessionContext();
  const theme = useTheme();
  const loading = useLoading();
  const toast = useToast();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [amountOfAssessments, setAmountOfAssessments] = useState<number>(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === '') {
      setAmountOfAssessments(0);
      setErrorMessage('');
      return;
    }

    const numericValue = Number(value);
    if (Number.isNaN(numericValue)) {
      setErrorMessage('Please enter a valid number.');
      return;
    }

    if (numericValue < 0) {
      setAmountOfAssessments(numericValue);
      setErrorMessage('Please enter a positive number.');
    } else {
      setAmountOfAssessments(numericValue);
      setErrorMessage('');
    }
  };

  const handleClose = () => {
    setErrorMessage(null);
    setAmountOfAssessments(0);
    emit?.close?.();
  };

  const handleSubmit = async () => {
    try {
      loading.start();
      await services.edge.manage.assessmentsRequest({
        amountOfAssessments: amountOfAssessments,
      })({ token: session.token });
      toast.success('Request submitted successfully. You will be notified by email.');
      handleClose();
    } catch (error) {
      console.error(error);
      toast.error('Failed to submit request. Please try again.');
    } finally {
      loading.stop();
    }
  };

  return (
    <CustomDialog
      open={open}
      confirmText="Submit"
      loading={loading.isLoading}
      title="Request more credits"
      emit={{
        close: handleClose,
        confirm: handleSubmit,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap={2}
      >
        <Typography
          variant="body1"
          fontWeight={400}
          color={theme.palette.grey[700]}
        >
          Additional credits are required to view new candidate scorecards.
          Select the amount of assessments below to send the request.
          Once approved, you&apos;ll be notified by email.
        </Typography>
        <Box>
          <TextField
            size="small"
            value={amountOfAssessments}
            label="Credits"
            onChange={handleInputChange}
            error={!!errorMessage}
            helperText={errorMessage}
          />
        </Box>
      </Box>
    </CustomDialog>
  );
}
