import {
  DynamicVendor,
  DynamicVendorAssessments,
  DynamicVendorPrimaryContact,
  DynamicVendorPublic,
} from '@piccadilly-cloud/connect-platform-services';

import { generateId } from 'src/utils/general';

const initDynamicVendorPrimaryContact = (): DynamicVendorPrimaryContact => ({
  firstName: '',
  lastName: '',
  email: '',
});

const initDynamicVendorAssessments = (): DynamicVendorAssessments => ({
  total: 0,
  remaining: 0,
  expiredAt: new Date(),
  canAssign: false,
});

export const initDynamicVendor = (): DynamicVendor => ({
  id: generateId(),
  vendorId: '',
  parentVendorId: '',
  companyName: '',
  companyUrl: '',
  primaryContact: initDynamicVendorPrimaryContact(),
  assessments: initDynamicVendorAssessments(),
  createdAt: new Date(),
  updatedAt: new Date(),
  deleted: false,
  isActivated: false,
  isGettingStarted: false,
});

export const initDynamicVendorPublic = (): DynamicVendorPublic => ({
  id: generateId(),
  vendorId: '',
  parentVendorId: '',
  companyName: '',
  assessments: initDynamicVendorAssessments(),
  isActivated: false,
  isGettingStarted: false,
});
