import { AppConfig, DynamicVendorPublic, UserAccount } from '@piccadilly-cloud/connect-platform-services';

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  styled,
} from '@mui/material';

export interface SessionDebugProps {
  appConfig: AppConfig;
  dynamicVendor?: DynamicVendorPublic;
  userAccount: UserAccount;
}

const StyledCard = styled(Card)(() => ({
  margin: 1,
  borderRadius: '16px',
}));

const renderAppConfigInfo = (appConfig: AppConfig) => {
  const demoExpiration = appConfig.behavior.demoExpiresAt
    ? new Date(appConfig.behavior.demoExpiresAt).toLocaleString()
    : 'N/A';
  const { candidateBehavior, genericCandidatePoolEnabled, recruiterBehavior, useDemoMode } = appConfig.behavior;
  return (
    <StyledCard>
      <CardHeader title="App Config" />
      <CardContent>
        <Box display="flex" flexDirection="column" rowGap={1}>
          <Typography><b>Info</b></Typography>
          <Typography variant="caption"><b>Id:</b> {appConfig.id}</Typography>
          <Typography variant="caption"><b>Vendor:</b> {appConfig.vendor}</Typography>
          <Divider />
          <Typography><b>Behavior</b></Typography>
          <Typography variant="caption"><b>Demo Mode:</b> {useDemoMode.toString()}</Typography>
          <Typography variant="caption"><b>Demo Expiration:</b> {demoExpiration}</Typography>
          <Typography variant="caption">
            <b>Generic Candidate Pool:</b> {genericCandidatePoolEnabled.toString()}
          </Typography>
          <Typography variant="body2"><b>Candidate Behavior</b></Typography>
          <Typography variant="caption">
            <b>Demographic Collection:</b> {candidateBehavior.useDemographicCollection.toString()}
          </Typography>
          <Typography variant="caption">
            <b>Demographic Collection Extended:</b> {candidateBehavior.useDemographicCollectionExtended.toString()}
          </Typography>
          <Typography variant="body2"><b>Recruiter Behavior</b></Typography>
          <Typography variant="caption">
            <b>Use Generic Profiles:</b> {recruiterBehavior.useGenericProfiles.toString()}
          </Typography>
          <Typography variant="caption">
            <b>Prequalify Candidates:</b> {recruiterBehavior.prequalifyCandidatesEnabled.toString()}
          </Typography>
          <Typography variant="caption">
            <b>Assessment Purchases:</b> {recruiterBehavior.assessmentPurchasesEnabled.toString()}
          </Typography>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

const renderDynamicVendorInfo = (dynamicVendor?: DynamicVendorPublic) => {
  const content = !dynamicVendor
    ? <>No dynamic vendor</>
    : (
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Typography><b>Info</b></Typography>
        <Typography variant="caption"><b>Id:</b> {dynamicVendor.id}</Typography>
        <Typography variant="caption"><b>Parent Vendor Id:</b> {dynamicVendor.parentVendorId}</Typography>
        <Typography variant="caption"><b>Vendor Id:</b> {dynamicVendor.vendorId}</Typography>
        <Typography variant="caption"><b>Company Name:</b> {dynamicVendor.companyName}</Typography>
        <Typography variant="caption"><b>Total Assessments:</b> {dynamicVendor.assessments.total}</Typography>
        <Typography variant="caption"><b>Remaining Assessments:</b> {dynamicVendor.assessments.remaining}</Typography>
        <Typography variant="caption">
          <b>Getting Started:</b> {dynamicVendor.isGettingStarted.toString()}
        </Typography>
      </Box>
    );

  return (
    <StyledCard>
      <CardHeader title="Dynamic Vendor" />
      <CardContent>
        {content}
      </CardContent>
    </StyledCard>
  );
};

const renderUserAccountInfo = (userAccount: UserAccount) => {
  const content = !userAccount.id
    ? <>No user account</>
    : (
      <Box display="flex" flexDirection="column" rowGap={1}>
        <Typography><b>Info</b></Typography>
        <Typography variant="caption"><b>Id:</b> {userAccount.id}</Typography>
        <Typography variant="caption"><b>Name:</b> {' '}
          {userAccount.personalInfo.firstName} {userAccount.personalInfo.lastName}
        </Typography>
        <Typography variant="caption"><b>Email:</b> {userAccount.email}</Typography>
        <Typography variant="caption"><b>Role:</b> {userAccount.accountRole}</Typography>
      </Box>
    );

  return (
    <StyledCard>
      <CardHeader title="User Account" />
      <CardContent>
        {content}
      </CardContent>
    </StyledCard>
  );
};

export default function SessionDebug(props: SessionDebugProps) {
  const { appConfig, dynamicVendor, userAccount } = props;

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item xs={12} md={4}>
        {renderAppConfigInfo(appConfig)}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderDynamicVendorInfo(dynamicVendor)}
      </Grid>
      <Grid item xs={12} md={4}>
        {renderUserAccountInfo(userAccount)}
      </Grid>
    </Grid>
  );
}
