import services, {
  AddressAutocompleteResponse,
  AddressDetailsResponse,
  Session,
} from '@piccadilly-cloud/connect-platform-services';

const getAutocompleteSuggestions = (
  session: Session,
  input: string,
  requestToken: string,
  citiesOnly: boolean,
  countryCode: string,
): Promise<AddressAutocompleteResponse> => services.edge.address.getAutocompleteSuggestions({
  input,
  sessionToken: requestToken,
  citiesOnly,
  countryCode,
})({ token: session.token });

const getAddressDetails = (
  session: Session,
  id: string,
  requestToken: string,
): Promise<AddressDetailsResponse> => services.edge.address.getAddressDetails({
  id,
  sessionToken: requestToken,
})({ token: session.token });

const addressAutocomplete = {
  getAutocompleteSuggestions,
  getAddressDetails,
};

export default addressAutocomplete;
