import {
  Box,
  LinearProgress,
  Typography,
  styled,
  useTheme,
} from '@mui/material';

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.grey[50],
  padding: '8px 12px',
}));

interface AnswerProgressProps {
  progress: number;
}

export default function AnswerProgress({ progress }: AnswerProgressProps) {
  const theme = useTheme();
  return (
    <StyledBox>
      <Typography
        variant="caption"
        color={theme.palette.grey[600]}
        fontWeight={600}
        letterSpacing={0.4}
      >
        Answer progress
      </Typography>
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            width: '75%',
            height: 16,
            borderRadius: 5,
            backgroundColor: theme.palette.background.paper,
            '& .MuiLinearProgress-bar': {
              backgroundColor: theme.palette.primary.light,
            },
          }}
        />
      </Box>
    </StyledBox>
  );
}
