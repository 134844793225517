import {
  AssessmentResponseType,
  EducationLevel,
  EmploymentType,
  ExperienceType,
  LocationType,
  PositionType,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import * as Yup from 'yup';

const tabExperience = Yup.object().shape({
  experienceLevel: Yup.number()
    .oneOf(Object.values(ExperienceType).filter((val) => typeof val === 'number') as number[],
      'Experience level is required',
    )
    .required('Experience level is required'),
}).required();

const tabLocation = Yup.object().shape({
  location: Yup.string().required('Location is required'),
  locationType: Yup.array().of(Yup.mixed<LocationType>().oneOf(['ONSITE', 'REMOTE']).required('This field is required'))
    .min(1, 'This field is required')
    .required('This field is required'),
  listingRequiresRelocation_28: Yup.number()
    .default(0)
    .min(1, 'This field is required')
    .max(5, 'This field is required')
    .required('This field is required'),
  scheduleWillingToTravel_24: Yup.number()
    .default(0)
    .min(0, 'This field is required')
    .max(5, 'This field is required')
    .required('This field is required'),
}).required();

const getTabAvailability = (profile: UniformProfileDefinition) => {
  const profileAttributeSchema: { [key: string]: any } = {};
  profile.availability.fields.forEach((k) => {
    profileAttributeSchema[k.id] = Yup.number().default(0).min(1, 'Required').max(5, 'Required')
      .required('Required');
  });
  const dynamicTabAvailability = Yup.object().shape({
    employmentType: Yup.array().of(Yup.mixed<EmploymentType>().oneOf(['FULL_TIME', 'PART_TIME'])
      .required(),
    )
      .min(1, 'Status is required')
      .required('Status is required'),
    positionType: Yup.array().of(Yup.mixed<PositionType>().oneOf(['PERMANENT', 'TEMPORARY'])
      .required(),
    )
      .min(1, 'Duration is required')
      .required('Duration is required'),
    profileAttributes: Yup.object().shape(profileAttributeSchema).required('Required'),
  }).required();
  return dynamicTabAvailability;
};

const tabEligibility = Yup.object().shape({
  listingAgeRequirement_13: Yup.boolean().required(),
  authorizedToWork_12: Yup.boolean().required(),
  profileAttributes: Yup.object().required(),
}).required();

const tabEducation = Yup.object().shape({
  educationLevel: Yup.number()
    .typeError('Education level is required')
    .oneOf(Object.values(EducationLevel).filter((val) => typeof val === 'number') as number[],
      'Education level is required',
    ).required('Education level is required'),
  additionalEducation: Yup.array().default([]),
}).required();

const tabCredentials = Yup.object().shape({
  certificates: Yup.object().required(),
  notes: Yup.string().default(''),
  canProvideCertificates: Yup.boolean().default(false),
}).required();

const tabAssessmentType = Yup.object().shape({
  responseType: Yup.string().oneOf(Object.values(AssessmentResponseType)).required(),
}).required();

const formSchemas = {
  tabExperience,
  tabLocation,
  getTabAvailability,
  tabEligibility,
  tabEducation,
  tabCredentials,
  tabAssessmentType,
};

export default formSchemas;
