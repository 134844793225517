import {
  Box,
  IconButton,
  Slide,
  Typography,
  styled,
} from '@mui/material';

import { ReactNode } from 'react';

import { useBoolean } from 'src/hooks/use-boolean';

import Iconify from '../iconify';

const StyledContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  right: 0,
  height: '35vh',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  zIndex: 1201,
}));

const StickyHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: '16px 16px 0',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
}));

export interface DebugProps {
  children: ReactNode;
}

export default function Debug(props: DebugProps) {
  const { children } = props;
  const isOpen = useBoolean();
  const buttonElement = document.querySelector('aside > button');

  const icon = isOpen.value
    ? 'mdi:bug-stop'
    : 'mdi:bug';

  return (
    <>
      <IconButton
        onClick={isOpen.onToggle}
        sx={{
          width: 40,
          height: 40,
          position: 'fixed',
          margin: '0.5em',
          bottom: (buttonElement?.clientHeight ?? 0) + 16,
          right: 0,
          zIndex: 1202,
        }}
      >
        <Iconify icon={icon} />
      </IconButton>
      <Slide
        direction="up"
        in={isOpen.value}
        mountOnEnter
        unmountOnExit
      >
        <StyledContainer>
          <StickyHeader variant="h6">Debug Info</StickyHeader>
          <Box
            padding="16px"
            overflow="auto"
            height="calc(100% - 32px)"
          >
            {children}
          </Box>
        </StyledContainer>
      </Slide>
    </>
  );
}
