import { Session } from '@piccadilly-cloud/connect-platform-services';

import { lazy } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import SessionGuard from 'src/contexts/session/guards/session-guard';
import { isUniversalLogin } from 'src/contexts/session/utils';
import AuthClassicLayout from 'src/layouts/auth/classic';

import { adminRoutes } from './admin';
import { universalLoginRoutes } from './auth';
import { candidateRoutes } from './candidate';
import { mainRoutes } from './main';
import { recruiterRoutes } from './recruiter';
import { vendorAdminRoutes } from './vendorAdmin';
import { workspaceAdminRoutes } from './workspace-admin';

const Page404 = lazy(() => import('src/pages/404'));

export interface RouterProps {
  session: Session;
}

export default function Router(props: RouterProps) {
  const { session } = props;
  const isAuthenticated = session.sessionType === 'AUTHENTICATED';
  const admin: RouteObject[] = [
    ...((isAuthenticated && session.account.accountRole === 'ADMIN')
      ? adminRoutes
      : []),
  ];
  const workspaceAdmin: RouteObject[] = [
    ...((isAuthenticated && session.account.accountRole === 'WORKSPACE_ADMIN')
      ? workspaceAdminRoutes
      : []),
  ];
  const recruiter: RouteObject[] = [
    ...((isAuthenticated && session.account.accountRole === 'RECRUITER')
      ? recruiterRoutes
      : []),
  ];
  const vendorAdmin: RouteObject[] = [
    ...((isAuthenticated && session.account.accountRole === 'VENDOR_ADMIN')
      ? vendorAdminRoutes
      : []),
  ];
  const candidate: RouteObject[] = [
    ...((isAuthenticated && session.account.accountRole === 'CANDIDATE')
      ? candidateRoutes
      : []),
  ];

  let routes: RouteObject[] = [
    ...mainRoutes,
    ...admin,
    ...workspaceAdmin,
    ...recruiter,
    ...candidate,
    ...vendorAdmin,
    {
      path: '*',
      element: (
        <SessionGuard>
          <AuthClassicLayout>
            <Page404 />
          </AuthClassicLayout>
        </SessionGuard>
      ),
    },
  ];
  if (isUniversalLogin()) {
    routes = [
      ...universalLoginRoutes,
      {
        path: '*',
        element: (
          <SessionGuard>
            <AuthClassicLayout>
              <Page404 />
            </AuthClassicLayout>
          </SessionGuard>
        ),
      },
    ];
  }

  return useRoutes(routes);
}
