import {
  ApplicationAssessmentResponse,
  AssessmentResponseType,
  CandidateProfile,
  EducationProgress,
  EducationTypeExt,
} from '@piccadilly-cloud/connect-platform-services';

import {
  TabAvailabilityForm,
  TabCredentialsForm,
  TabEducationForm,
  TabEligibilityForm,
  TabExperienceForm,
  TabLocationForm,
} from './form-types';

const onNextExperience = (
  original: CandidateProfile,
  form: TabExperienceForm,
): CandidateProfile => {
  const next = { ...original };
  next.experience.completeAt = new Date();
  next.experience.isComplete = true;
  next.experience.experienceLevel = Number(form.experienceLevel);
  return next;
};

const onNextLocation = (
  original: CandidateProfile,
  form: TabLocationForm,
): CandidateProfile => {
  const next = { ...original };
  next.location.completeAt = new Date();
  next.location.isComplete = true;
  next.location.location = form.location;
  next.location.locationType = form.locationType;
  next.location.listingRequiresRelocation_28 = form.listingRequiresRelocation_28;
  next.location.scheduleWillingToTravel_24 = form.scheduleWillingToTravel_24;
  return next;
};

const onNextAvailability = (
  original: CandidateProfile,
  form: TabAvailabilityForm,
): CandidateProfile => {
  const next = { ...original };
  next.availability.completeAt = new Date();
  next.availability.isComplete = true;
  next.availability.employmentType = form.employmentType;
  next.availability.positionType = form.positionType;
  next.availability.profileAttributes = form.profileAttributes;
  return next;
};

const onNextEligibility = (
  original: CandidateProfile,
  form: TabEligibilityForm,
): CandidateProfile => {
  const next = { ...original };
  next.eligibility.completeAt = new Date();
  next.eligibility.isComplete = true;
  next.eligibility.authorizedToWork_12 = form.authorizedToWork_12 ? 5 : 1;
  next.eligibility.listingAgeRequirement_13 = form.listingAgeRequirement_13 ? 5 : 1;
  next.eligibility.profileAttributes = {};
  Object.keys(form.profileAttributes).forEach((attrKey) => {
    const attrValue = form.profileAttributes[attrKey];
    next.eligibility.profileAttributes[attrKey] = attrValue ? 5 : 1;
  });

  return next;
};

const onNextEducation = (
  original: CandidateProfile,
  form: TabEducationForm,
): CandidateProfile => {
  const next = { ...original };
  next.education.completeAt = new Date();
  next.education.isComplete = true;
  next.education.educationLevel = form.educationLevel;

  // filter out any education objects in the array that are not completed
  const isValidEducationType = (value: string): value is EducationTypeExt =>
    Object.values(EducationTypeExt).includes(value as EducationTypeExt);

  const isValidEducationProgress = (value: number): value is EducationProgress =>
    Object.values(EducationProgress).includes(value as EducationProgress);

  next.education.additionalEducation = form.additionalEducation
    .filter((item) => isValidEducationType(item.type) && isValidEducationProgress(item.progress ?? -1))
    .map((item) => ({
      type: item.type as EducationTypeExt,
      progress: item.progress as EducationProgress,
    }));
  return next;
};

const onNextCredentials = (
  original: CandidateProfile,
  form: TabCredentialsForm,
): CandidateProfile => {
  const next = { ...original };
  next.education.completeAt = new Date();
  next.education.isComplete = true;
  next.credentials.certificates = form.certificates;
  next.credentials.notes = form.notes;
  next.credentials.canProvideCertificates = form.canProvideCertificates;
  next.credentials.completeAt = new Date();
  next.credentials.isComplete = true;
  return next;
};

const onNextAssessmentType = (
  original: CandidateProfile,
  responseType: AssessmentResponseType,
): CandidateProfile => {
  const next = { ...original };
  next.assessment.completeAt = new Date();
  next.assessment.isComplete = true;
  next.assessment.responseType = responseType;
  return next;
};

const onNextAssessmentQuestionAnswered = (
  original: CandidateProfile,
  question: ApplicationAssessmentResponse,
): CandidateProfile => {
  const next = { ...original };
  if (!next.assessment.responseAnswers) {
    next.assessment.responseAnswers = [];
  }
  const alreadyExists = next.assessment.responseAnswers.findIndex((i) =>
    i.jobProfileQuestionId === question.jobProfileQuestionId,
  );
  if (alreadyExists === -1) {
    next.assessment.responseAnswers.push(question);
  }
  next.assessment.responseAnswers[alreadyExists] = question;
  return next;
};

const onNextAssessmentQuestionDeleted = (
  original: CandidateProfile,
  questionId: string,
): CandidateProfile => {
  const next = { ...original };
  if (!next.assessment.responseAnswers) {
    next.assessment.responseAnswers = [];
  }
  next.assessment.responseAnswers = next.assessment.responseAnswers.filter((i) => i.id !== questionId);
  return next;
};

const formValues = {
  onNextExperience,
  onNextLocation,
  onNextAvailability,
  onNextEligibility,
  onNextEducation,
  onNextCredentials,
  onNextAssessmentType,
  onNextAssessmentQuestionAnswered,
  onNextAssessmentQuestionDeleted,
};

export default formValues;
