import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';

import { useCallback } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';

import { fData } from 'src/utils/format-number';

import Iconify from '../iconify';
import { LoadingInline } from '../loading-screen';

import MultiFilePreview from './preview-multi-file';
import { ExtendedUploadProps } from './types';

const Root = styled('div')(({ theme }) => ({
  width: '100%',
  ...theme.typography.body2,
  '& > :not(style) ~ :not(style)': {
    marginTop: theme.spacing(3),
  },
}));

export default function UploadAudio({
  disabled,
  multiple = false,
  error,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  sx,
  maxSize,
  onUploadAsync,
  loading,
  ...other
}: ExtendedUploadProps) {
  const mTheme = useTheme();

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      onUploadAsync(acceptedFiles, rejectedFiles);
    },
    [onUploadAsync],
  );

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,
    disabled,
    onDrop,
    accept: {
      'audio/*': [],
    },
    maxSize,
    ...other,
  });

  const hasFile = !!file && !multiple;

  const hasError = isDragReject || !!error;

  const renderMultiPreview = () => {
    if (!hasFile) {
      return undefined;
    }
    let previewFile = file;
    if (typeof previewFile === 'string') {
      const splitFile = previewFile.split('/');
      previewFile = splitFile[splitFile.length - 1];
    }
    return (
      <Box>
        <MultiFilePreview files={[previewFile]} thumbnail={thumbnail} onRemove={onRemove} />
      </Box>
    );
  };

  const renderPlaceholder = (
    <Stack
      spacing={1}
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      <Iconify icon="material-symbols:cloud-upload" width={1} maxWidth={100} sx={{ color: mTheme.palette.grey[300] }} />
      <Stack spacing={2} sx={{ textAlign: 'center', width: '100%' }}>
        <Typography
          variant="subtitle1"
          sx={{ color: error ? mTheme.palette.error.main : mTheme.palette.grey[700] }}
        >
          Drag & drop file here
        </Typography>
        <Box display="flex" flexDirection="column">
          <Root sx={{ width: 1 }}>
            <Divider sx={{ color: mTheme.palette.grey[400] }}>
              <Typography variant="subtitle2">
                OR
              </Typography>
            </Divider>
          </Root>
        </Box>
        <Box>
          <Button
            variant="outlined"
          >
            Select file
          </Button>
        </Box>
        {maxSize && (
          <Box sx={{ pt: 3 }}>
            <Typography sx={{ color: mTheme.palette.grey[600] }} variant="subtitle2">
              Maximum file size is
              {' '}
              {fData(maxSize)}
              .
            </Typography>
          </Box>
        )}
      </Stack>
    </Stack>
  );

  const renderContent = (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {loading && (
        <Box display="flex" flexDirection="row" justifyContent="center">
          <LoadingInline />
        </Box>
      )}
      {!loading && !hasFile && renderPlaceholder}
    </Box>
  );

  return (
    <>
      {!hasFile && (
        <Box
          {...getRootProps()}
          sx={{
            p: 3,
            outline: 'none',
            borderRadius: 1,
            cursor: 'pointer',
            overflow: 'hidden',
            position: 'relative',
            bgcolor: (theme) => alpha(theme.palette.grey[500], 0.08),
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.2)}`,
            transition: (theme) => theme.transitions.create(['opacity', 'padding']),
            '&:hover': {
              opacity: 0.72,
            },
            ...(isDragActive && {
              opacity: 0.72,
            }),
            ...(disabled && {
              opacity: 0.48,
              pointerEvents: 'none',
            }),
            ...(hasError && {
              color: 'error.main',
              borderColor: 'error.main',
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
            }),
            ...sx,
          }}
        >
          <input {...getInputProps()} />

          {renderContent}
        </Box>

      )}
      {renderMultiPreview()}

      {helperText && helperText}
    </>
  );
}
