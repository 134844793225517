import services, { TEQApplicationStatus } from '@piccadilly-cloud/connect-platform-services';

import { Button, Typography, useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';

import { useEffect, useState } from 'react';

import { RequestCreditsDialog } from 'src/components';
import { useSettingsContext } from 'src/components/settings';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useResponsive } from 'src/hooks';
import { typography } from 'src/theme/mui/typography';

import HeaderBanner from '../_common/header-banner';
import { HEADER, NAV } from '../config-layout';

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext();
  const session = useSessionContext();
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');
  const [showBanner, setShowBanner] = useState(false);
  const isDemo = session.appConfig.behavior.useDemoMode;
  const [unprocessedApplicationsCount, setUnprocessedApplicationsCount] = useState(0);
  const [isRequestCreditsDialogOpen, setIsRequestCreditsDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await services.edge.teq.applications.getApplicationsCount(
          TEQApplicationStatus.SUBMITTED,
        )({ token: session.token });
        setUnprocessedApplicationsCount(res);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [session.token]);

  useEffect(() => {
    if (!session.dynamicVendor || !session.dynamicVendor.parentVendorId) {
      return;
    }

    const hasRemainingAssessments = session.dynamicVendor.assessments.remaining > 0;
    if (hasRemainingAssessments) {
      return;
    }

    if (session.account.behaviorSettings.useGettingStarted && !isDemo) {
      setShowBanner(true);
    }
  }, [session.dynamicVendor, isDemo, session.account.behaviorSettings.useGettingStarted, session.account.accountRole]);

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';

  const renderBanner = () => {
    const renderSubtitle = () => !!unprocessedApplicationsCount && (
      <Box sx={{ pb: 2 }}>
        <Typography variant="body2" color="#914C07" fontWeight={400}>
          You have candidates on hold that might be a good fit.
        </Typography>
      </Box>
    );

    return (
      <HeaderBanner>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          rowGap={1}
        >
          <Box
            component="img"
            src="/assets/credit-warn.png"
            alt="assessment limit"
            width={24}
            height={24}
          />
          <Typography
            variant="body2"
            color="#914C07"
            fontWeight={typography.fontWeightBold}
          >
            You have reached your assessment limit
          </Typography>
          {renderSubtitle()}
          <Button
            variant="contained"
            onClick={() => setIsRequestCreditsDialogOpen(true)}
            sx={{
              backgroundColor: theme.palette.warning.main,
              '&:hover': {
                backgroundColor: theme.palette.warning.dark,
              },
            }}
          >
            Request credits
          </Button>
        </Box>
      </HeaderBanner>
    );
  };

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {renderBanner()}
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          // px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {showBanner ?? renderBanner()}
      {children}
      <RequestCreditsDialog
        open={isRequestCreditsDialogOpen}
        emit={{
          close: () => setIsRequestCreditsDialogOpen(false),
        }}
      />
    </Box>
  );
}
