import type { TextFieldProps } from '@mui/material/TextField';
import TextField from '@mui/material/TextField';

import { forwardRef, useState } from 'react';
import PhoneNumberInput from 'react-phone-number-input/input';

import type { PhoneInputProps } from './types';
import { getCountryCode } from './utils';

// ----------------------------------------------------------------------

export const PhoneInput = forwardRef<HTMLDivElement, PhoneInputProps>(
  ({ value, onChange, placeholder, country: inputCountryCode, disableSelect, ...other }, ref) => {
    const defaultCountryCode = getCountryCode(value, inputCountryCode);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedCountry, setSelectedCountry] = useState(defaultCountryCode);

    return (
      <PhoneNumberInput
        ref={ref}
        country={selectedCountry}
        inputComponent={CustomInput}
        value={value}
        onChange={onChange}
        placeholder={placeholder ?? 'Enter phone number'}
        {...other}
      />
    );
  },
);

// ----------------------------------------------------------------------

const CustomInput = forwardRef<HTMLInputElement, TextFieldProps>(({ ...props }, ref) => (
  <TextField inputRef={ref} {...props} />
));
