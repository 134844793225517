import { UserAccountAnalyticsInfo } from '@piccadilly-cloud/connect-platform-services';

import { LazyExoticComponent, ReactNode } from 'react';

export enum SurveyFlowStepId {
  WELCOME = 'welcome',
  DEMOGRAPHICS_GENDER = 'demographics-gender',
  DEMOGRAPHICS_RACE = 'demographics-race',
  DEMOGRAPHICS_AGE = 'demographics-age',
  SURVEY_EXTENDED = 'survey-extended',
}

export interface SurveyFlowStepProps {
  onNext: (updatedAnalytics?: UserAccountAnalyticsInfo) => Promise<void>;
  onPrevious: VoidFunction;
}

export const surveyFlowStepRoute: Record<SurveyFlowStepId, string> = {
  [SurveyFlowStepId.WELCOME]: 'getting-started',
  [SurveyFlowStepId.DEMOGRAPHICS_GENDER]: 'demographics/gender',
  [SurveyFlowStepId.DEMOGRAPHICS_RACE]: 'demographics/race',
  [SurveyFlowStepId.DEMOGRAPHICS_AGE]: 'demographics/age',
  [SurveyFlowStepId.SURVEY_EXTENDED]: 'extended',
};

export interface SurveyFlowStepConfig {
  id: SurveyFlowStepId;
  component: LazyExoticComponent<(props: SurveyFlowStepProps) => ReactNode>;
  route: string;
}
