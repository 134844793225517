import { LocationType } from '@piccadilly-cloud/connect-platform-services';

import { Box, FormHelperText } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import FormProvider from 'src/components/hook-form';
import Image from 'src/components/image/image';
import { useApplicationFlowContext } from 'src/contexts';

import ControlRow from '../control-row';
import FormButton from '../form-controls/form-button';
import FormSlider from '../form-controls/form-slider';
import QuestionCard from '../partials/question-card';
import formDefaults from '../pcdl-wiz/form-defaults';
import formSchemas from '../pcdl-wiz/form-schemas';
import { TabLocationForm } from '../pcdl-wiz/form-types';
import formValues from '../pcdl-wiz/form-values';
import RHFAddressStyled from '../pcdl-wiz/rhf-address-styled';

export interface TabLocationProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
  isPreview?: boolean;
}

export default function TabLocation(props: TabLocationProps) {
  const {
    loading,
    onBack,
    onNext,
    isPreview,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();

  const methods = useForm<TabLocationForm>({
    resolver: !applicationFlowContext.isPreview
      ? yupResolver(formSchemas.tabLocation)
      : undefined,
    defaultValues: applicationFlowContext.application?.profile
      ? formDefaults.tabLocation(applicationFlowContext.application.profile)
      : undefined,
  });

  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(
    (data: TabLocationForm) => {
      if (!applicationFlowContext.application?.profile) {
        throw new Error('Failed to update location');
      }

      const updatedCandidateProfile = formValues.onNextLocation(
        applicationFlowContext.application.profile,
        data,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileLocation']({
        location: updatedCandidateProfile.location,
      });
      onNext();
    },
    (i) => console.error(i),
  );

  return (
    <FormProvider methods={methods}>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Box display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 3 }}>
          <Image
            height="120px"
            src="/assets/illustrations/candidate-application-background-1.svg"
          />
        </Box>

        <QuestionCard title="Where are you located?">
          <Box maxWidth="500px">
            <RHFAddressStyled
              placeholder="Enter city"
              name="location"
              disableDefaultSubmit
              citiesOnly
              size="medium"
            />
          </Box>
        </QuestionCard>

        <QuestionCard
          title="Where would you like to work?"
          subTitle="Select all that apply"
        >
          <Box>
            <Box display="flex" flexDirection="row" columnGap={1} justifyContent="flex-start">
              <FormButton
                selected={values.locationType.includes('ONSITE')}
                onClick={() => {
                  const v = getValues();
                  const nextValues: LocationType[] = v.locationType.includes('ONSITE')
                    ? v.locationType.filter((i) => i !== 'ONSITE')
                    : [...v.locationType, 'ONSITE'];
                  setValue('locationType', nextValues);
                }}
              >
                On site
              </FormButton>
              <FormButton
                selected={values.locationType.includes('REMOTE')}
                onClick={() => {
                  const v = getValues();
                  const nextValues: LocationType[] = v.locationType.includes('REMOTE')
                    ? v.locationType.filter((i) => i !== 'REMOTE')
                    : [...v.locationType, 'REMOTE'];
                  setValue('locationType', nextValues);
                }}
              >
                Remote
              </FormButton>
            </Box>
            <FormHelperText error sx={{ mx: 0 }}>
              {errors?.locationType?.message ? `${errors?.locationType?.message}` : ''}
            </FormHelperText>
          </Box>
        </QuestionCard>

        <QuestionCard
          title="Are you willing to relocate?"
        >
          <Box>
            <Box display="flex" flexDirection="row" columnGap={1} justifyContent="flex-start">
              <FormButton
                selected={values.listingRequiresRelocation_28 === 1}
                onClick={() => {
                  setValue('listingRequiresRelocation_28', 1);
                }}
              >
                No
              </FormButton>
              <FormButton
                selected={values.listingRequiresRelocation_28 === 5}
                onClick={() => {
                  setValue('listingRequiresRelocation_28', 5);
                }}
              >
                Yes
              </FormButton>
            </Box>
            <FormHelperText error sx={{ mx: 0 }}>
              {errors?.listingRequiresRelocation_28?.message ? `${errors?.listingRequiresRelocation_28?.message}` : ''}
            </FormHelperText>
          </Box>
        </QuestionCard>

        <QuestionCard title="How often are you willing to travel?">
          <Box display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 2 }}>
            <FormSlider
              name="scheduleWillingToTravel_24"
              step={1}
              min={1}
              max={5}
              marks={[{ value: 1, label: '0%' }, { value: 5, label: '>80%' }]}
              getAriaValueText={(value) => `${value}`}
              valueLabelFormat={(value) => `${value}`}
              sx={{ alignSelf: 'center', width: 'calc(100% - 25px)' }}
            />
          </Box>
        </QuestionCard>

        <ControlRow
          onBack={isPreview ? undefined : onBack}
          onNext={onSubmit}
          loading={loading}
          isEditing={applicationFlowContext.isEditing}
        />
      </Box>
    </FormProvider>
  );
}
