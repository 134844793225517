import Banner from './banner';
import Header from './header';
import Main from './main';

type Props = {
  children: React.ReactNode;
};

export default function ApplicationPreviewLayout({ children }: Props) {
  return (
    <>
      <Banner />
      <Header />
      <Main>{children}</Main>
    </>
  );
}
