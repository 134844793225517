import { Box, ButtonBase, Typography, useTheme } from '@mui/material';

import { FieldError } from 'react-hook-form';

export default function TwoBox(props: {
  title?: string;
  description?: string;
  value: number;
  onChange: (next: number) => void;
  error?: FieldError;
}) {
  const {
    title,
    description,
    value,
    onChange,
    error,
  } = props;

  const theme = useTheme();

  return (
    <Box
      sx={{
        textAlign: 'left',
      }}
    >
      {title && (
        <Box display="flex" flexDirection="row" alignItems="center" columnGap={1}>
          <Typography variant="h6">
            {title}
          </Typography>
        </Box>
      )}
      {description && (
        <Box>
          <Typography variant="body2">
            {description}
          </Typography>
        </Box>
      )}
      <Box display="flex" flexDirection="row" alignItems="center" textAlign="center" sx={{ pt: 1 }}>
        <ButtonBase
          sx={{
            py: 1,
            width: '50%',
            border: `1px solid ${value === 1 ? theme.palette.text.primary : theme.palette.grey[200]}`,
            backgroundColor: value === 1 ? theme.palette.secondary.main : 'unset',
            '&:hover': {
              cursor: 'pointer',
              border: `1px solid ${theme.palette.secondary.main}`,
              backgroundColor: theme.palette.secondary.light,
            },
          }}
          onClick={() => onChange(1)}
        >
          <Box>
            <Typography variant="body1">
              No
            </Typography>
          </Box>
        </ButtonBase>
        <ButtonBase
          sx={{
            py: 1,
            width: '50%',
            border: `1px solid ${value === 5 ? theme.palette.text.primary : theme.palette.grey[200]}`,
            backgroundColor: value === 5 ? theme.palette.secondary.main : 'unset',
            '&:hover': {
              cursor: 'pointer',
              border: `1px solid ${theme.palette.secondary.main}`,
              backgroundColor: theme.palette.secondary.light,
            },
          }}
          onClick={() => onChange(5)}
        >
          <Box>
            <Typography variant="body1">
              Yes
            </Typography>
          </Box>
        </ButtonBase>
      </Box>
      {error && value === 0 && (
        <Typography variant="body2" color={theme.palette.error.main} sx={{ pt: 1 }}>
          *Required
        </Typography>
      )}
    </Box>
  );
}
