import { Box, Typography } from '@mui/material';

import { useSessionContext } from 'src/contexts/session/useSessionContext';

import CustomDialog, { CustomDialogEmits, CustomDialogProps } from './custom-dialog';

export interface VendorAdminWelcomeDialogEmits extends CustomDialogEmits {
  next?: VoidFunction;
}

export interface VendorAdminWelcomeDialogProps extends CustomDialogProps {
  emit?: VendorAdminWelcomeDialogEmits;
}

export default function VendorAdminWelcomeDialog(props: VendorAdminWelcomeDialogProps) {
  const { open, emit } = props;
  const session = useSessionContext();

  const title = (
    <Typography variant="h3">
      {`Welcome, ${session.account.personalInfo.firstName}`}
    </Typography>
  );

  return (
    <CustomDialog
      open={open}
      title={title}
      confirmText="Next"
      confirmEndIcon="mdi:chevron-right"
      hideCancel
      emit={{
        close: emit?.close,
        confirm: emit?.next,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1}
      >
        <Typography variant="body2">
          Thank you for signing up for TEQ Connect. You will be able to add
          new vendors and their workspace by sending them an invite, allocate
          assessments per vendor and archive vendors.
        </Typography>
        <Typography variant="body2">
          We recommend getting started by inviting your first vendor.
        </Typography>
      </Box>
    </CustomDialog>
  );
}
