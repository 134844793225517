import {
  AppConfig,
  AppConfigBehavior,
  AppConfigBranding,
  DynamicVendor,
} from '@piccadilly-cloud/connect-platform-services';

import { generateId } from 'src/utils/general';

export const initAppConfigBehavior = (): AppConfigBehavior => ({
  useDemoMode: false,
  genericCandidatePoolEnabled: true,
  candidateBehavior: {
    useDemographicCollection: false,
    useDemographicCollectionExtended: false,
  },
  recruiterBehavior: {
    useGenericProfiles: false,
    prequalifyCandidatesEnabled: false,
    assessmentPurchasesEnabled: false,
  },
});

export const initAppConfigBranding = (): AppConfigBranding => ({
  name: '',
  primaryLogoUrl: '',
  secondaryLogoUrl: '',
  landingHeroUrl: '',
});

export const initAppConfig = (): AppConfig => ({
  id: generateId(),
  vendor: '',
  appHost: '',
  deleted: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  branding: initAppConfigBranding(),
  behavior: initAppConfigBehavior(),
});

export const initInvitee = (
  dynamicVendor?: DynamicVendor,
  appConfig?: AppConfig,
): Invitee => ({
  firstName: dynamicVendor?.primaryContact.firstName || '',
  lastName: dynamicVendor?.primaryContact.lastName || '',
  email: dynamicVendor?.primaryContact.email || '',
  url: dynamicVendor?.companyUrl || '',
  companyName: dynamicVendor?.companyName || '',
  assessments: dynamicVendor?.assessments.total || 30,
  useDemographics: appConfig?.behavior.candidateBehavior.useDemographicCollection ?? false,
  useDemographicsExtended: appConfig?.behavior.candidateBehavior.useDemographicCollectionExtended ?? false,
  useGenericProfiles: appConfig?.behavior.genericCandidatePoolEnabled ?? false,
  usePrequalifyCandidates: appConfig?.behavior.recruiterBehavior.prequalifyCandidatesEnabled ?? false,
  useAssessmentPurchases: appConfig?.behavior.recruiterBehavior.assessmentPurchasesEnabled ?? false,
});

export interface Invitee {
  firstName: string;
  lastName: string;
  email: string;
  url: string;
  companyName: string;
  assessments: number;
  useDemographics: boolean;
  useDemographicsExtended: boolean;
  useGenericProfiles: boolean;
  usePrequalifyCandidates: boolean;
  useAssessmentPurchases: boolean;
}
