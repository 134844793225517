import { Box, IconButton, Typography, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';

export default function ButtonResume(props: {
  onResume: VoidFunction;
}) {
  const { onResume } = props;

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <IconButton
          onClick={onResume}
        >
          <Iconify icon="material-symbols:resume-rounded" width="32px" color={theme.palette.primary.main} />
        </IconButton>
        <Typography variant="body2" color={theme.palette.grey[700]}>
          Resume
        </Typography>
      </Box>
    </Box>
  );
}
