/* eslint-disable max-len */
// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    login: '/login',
    loginAndReturnTo: (returnTo: string) => `/login?returnTo=${returnTo}`,
    loginPasscode: '/login/passcode',
    register: '/register',
    registerCandidate: '/register/candidate/application',
    registerAccessCode: '/register/access-code',
    registerCreateAccount: '/register/create-account',
    forgotPassword: '/forgot-password',
    forgotPasswordReset: '/forgot-password/reset',
    verifyAddress: '/verify-address',
  },
  notFound: '/404',
  notAuthorized: '/403',
  termsOfService: '/terms-and-conditions',
  privacyPolicy: '/privacy-policy',

  dashboard: '/dashboard',
  profile: '/profile',
  billingDashboard: '/billingDashboard',
  appSettings: '/app-settings',
  demoExpired: '/demo-expired',
  payments: '/payments',
  userManagement: {
    root: () => '/user-management',
    invitationsList: () => `${paths.userManagement.root()}/pending-users`,
    inviteUsers: () => `${paths.userManagement.root()}/invite-users/invite`,
    inviteUsersResults: () => `${paths.userManagement.root()}/invite-users/invite/results`,

    // all users list
    allUsers: () => `${paths.userManagement.root()}/all-users`,
    viewUser: (userId: string) => `${paths.userManagement.root()}/all-users/${userId}`,
  },

  admin: {
    root: () => '/admin',
    resources: {
      root: () => `${paths.admin.root()}/resources`,
      knowledgeBase: () => `${paths.admin.resources.root()}/knowledge-base`,
      support: () => `${paths.admin.resources.root()}/support`,
    },
    experimental: {
      root: () => `${paths.admin.root()}/experimental`,
      audioUpload: () => `${paths.admin.experimental.root()}/audio-upload`,
      teqProcessing: () => `${paths.admin.experimental.root()}/teq-processing`,
    },
  },

  candidate: {
    root: () => '/candidate',
    application: {
      root: () => `${paths.candidate.root()}/application`,
      view: (id: string) => `${paths.candidate.root()}/application/${id}`,
    },
    candidateProfile: {
      root: () => `${paths.candidate.root()}/profile`,
    },
    talentProfile: {
      root: () => `${paths.candidate.root()}/talent-profile`,
      answerQuestions: () => `${paths.candidate.talentProfile.root()}/answer-questions`,
      myVideos: () => `${paths.candidate.talentProfile.root()}/my-videos`,
    },
    matches: {
      root: () => `${paths.candidate.root()}/matches`,
      smartRecommendations: () => `${paths.candidate.matches.root()}/smart-recommendations`,
      viewJobs: () => `${paths.candidate.matches.root()}/view-jobs`,
    },
    resources: {
      root: () => `${paths.candidate.root()}/resources`,
      knowledgeBase: () => `${paths.candidate.resources.root()}/knowledge-base`,
      support: () => `${paths.candidate.resources.root()}/support`,
    },
  },

  recruiter: {
    root: () => '/recruiter',

    members: {
      root: (index: number) => `${paths.recruiter.root()}/w/${index}/members`,
      all: (index: number, tab?: string) => `${paths.recruiter.members.root(index)}${tab ? `?tab=${tab}` : ''}`,
    },

    workspace: {
      root: (index: number) => `${paths.recruiter.root()}/w/${index}/workspace`,
      new: '/workspace/new',
      dashboard: (index: number) => `${paths.recruiter.root()}/w/${index}/dashboard`,
      builder: (index: number) => `${paths.recruiter.workspace.root(index)}/builder`,
      builderPreview: (index: number) => `${paths.recruiter.workspace.root(index)}/builder/preview`,
      memberProfile: (index: number, accountId: string) => `${paths.recruiter.workspace.root(index)}/members/${accountId}`,
      memberInvitation: (index: number, invitationId: string) => `${paths.recruiter.workspace.root(index)}/members/invitation/${invitationId}`,
      settings: (index: number) => `${paths.recruiter.workspace.root(index)}/settings`,
      invite: (index: number) => `${paths.recruiter.workspace.root(index)}/invite`,

      jobs: {
        root: (index: number, createJobModalOpen?: boolean) => `${paths.recruiter.root()}/w/${index}/jobs${createJobModalOpen ? '?createJob=open' : ''}`,

        drafts: (index: number) => `${paths.recruiter.root()}/w/${index}/jobs?filter=${JSON.stringify({ status: 'DRAFT' })}`,
        active: (index: number) => `${paths.recruiter.root()}/w/${index}/jobs?filter=${JSON.stringify({ status: 'ACTIVE' })}`,
        closed: (index: number) => `${paths.recruiter.root()}/w/${index}/jobs?filter=${JSON.stringify({ status: 'CLOSED' })}`,

        editor: (index: number, listingId: string, printModalOpen?: boolean) => `${paths.recruiter.root()}/w/${index}/jobs/${listingId}${printModalOpen ? '?print=open' : ''}`,
        profiles: (index: number) => `${paths.recruiter.root()}/w/${index}/job/create`,

        templates: (index: number) => `${paths.recruiter.workspace.jobs.root(index)}/templates`,
        templatesEdit: (index: number, jobTemplateId: string) => `${paths.recruiter.workspace.jobs.root(index)}/templates/edit/${jobTemplateId}`,
        listings: (index: number) => `${paths.recruiter.workspace.jobs.root(index)}/listings`,
        edit: (index: number, jobId: string) => `${paths.recruiter.workspace.jobs.root(index)}/listings/edit/${jobId}`,
      },

      jobDashboard: {
        root: (index: number, listingId: string) => `${paths.recruiter.root()}/w/${index}/dashboard/job/${listingId}`,
        all: (index: number, listingId: string, tab?: string) => `${paths.recruiter.workspace.jobDashboard.root(index, listingId)}${tab ? `?tab=${tab}` : ''}`,
        scorecard: (index: number, listingId: string, teqScoreId: string) => `${paths.recruiter.root()}/w/${index}/dashboard/job/${listingId}/candidate/${teqScoreId}`,
        compare: (index: number, listingId: string, candidateIds?: string[]) => {
          let query = '?candidates=';
          if (candidateIds) {
            query = `${query}${candidateIds.join(',')}`;
          }
          return `${paths.recruiter.root()}/w/${index}/dashboard/job/${listingId}/compare${candidateIds ? query : ''}`;
        },
      },

      candidates: {
        root: (index: number) => `${paths.recruiter.root()}/w/${index}/candidates`,
        filtered: (index: number, listingLabel?: string) => {
          const query: { [key: string]: string } = {};
          if (listingLabel) {
            query.jobListingLabel = listingLabel;
          }
          return `${paths.recruiter.root()}/w/${index}/candidates?filter=${JSON.stringify(query)}`;
        },
        scorecard: (index: number, teqScoreId: string) => `${paths.recruiter.root()}/w/${index}/candidates/results/${teqScoreId}`,
        scorecardPrint: (index: number, teqScoreId: string) => `${paths.recruiter.root()}/w/${index}/candidates/results/${teqScoreId}/print`,
      },

      assessmentPreview: {
        root: (index: number, id: string) => `${paths.recruiter.root()}/w/${index}/listing/${id}/assessment/preview`,
      },
    },

    resources: {
      root: (_?: number) => `${paths.recruiter.root()}/resources`,
      knowledgeBase: (_?: number) => `${paths.recruiter.resources.root(-1)}/knowledge-base`,
      support: (_?: number) => `${paths.recruiter.resources.root(-1)}/support`,
    },
  },

};
