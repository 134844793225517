import { PublicListing } from '@piccadilly-cloud/connect-platform-services';

import { Box, Button, IconButton } from '@mui/material';

import Iconify from 'src/components/iconify';

export default function DevMode(props: {
  devModeAvailable?: boolean;
  isDevMode: boolean;
  setIsDevMode: (next: boolean) => void;
  active: string;
  setActive: (id: string) => void;
  tabList: { id: string; name: string; isComplete: boolean; partialCompletePercent?: number }[];
  progress: number;
  jobListing?: PublicListing;
}) {
  const {
    devModeAvailable,
    isDevMode,
    setIsDevMode,
    active,
    setActive,
    tabList,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    progress,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    jobListing,
  } = props;

  return (
    <>
      {devModeAvailable && (

        <Box display="flex" flexDirection="row" columnGap={1} sx={{ pt: 2 }}>
          <IconButton
            size="small"
            // variant="outlined"
            color="primary"
            onClick={() => {
              setIsDevMode(!isDevMode);
            }}
          >
            <Iconify icon="iconoir:laptop-dev-mode" />
          </IconButton>
          {isDevMode && tabList.map((t) => (
            <Button
              size="small"
              variant={active === t.id ? 'contained' : 'outlined'}
              color="primary"
              key={t.id}
              onClick={(() => {
                setActive(t.id);
              })}
            >
              {t.name}
            </Button>
          ))}
        </Box>
      )}
    </>
  );
}
