// form
import { FroalaUploadRequest } from '@piccadilly-cloud/connect-platform-services';

import { Controller, useFormContext } from 'react-hook-form';

// @mui
// import { FormHelperText } from '@mui/material';
import WYSIWYG from 'src/components-piccadilly/wysiwyg-froala/WYSIWYG';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
//

// ----------------------------------------------------------------------

interface Props {
  name: string;
  dataKey?: string;
  initialValue?: string;
  onChange?: (nextValue: string) => void;
  assetManagerImageOpts?: () => FroalaUploadRequest;
  defaultText?: string;
  richTextOnly?: boolean;
  helperText?: string;
}

export default function RHFEditor({ name, helperText, ...other }: Props) {
  const session = useSessionContext();
  const { control } = useFormContext();

  const getAssetOpts = (): FroalaUploadRequest => ({
    vendor: session.appConfig.vendor,
    userId: session.account.email,
    token: session.token,
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <WYSIWYG
          {...other}
          assetManagerImageOpts={getAssetOpts}
          dataKey={field.name}
          initialValue={field.value}
          onChange={(nextValue: string) => {
            field.onChange(nextValue);
          }}
          error={error}
          helperMessage={error ? error?.message : helperText}
        />
      )}
    />
  );
}
