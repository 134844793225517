import { Box, Typography, useTheme } from '@mui/material';

import { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import Twitter from 'react-color/lib/components/twitter/Twitter';

export default function RHFColorPicker(props: {
  helperText?: string;
  color: string;
  onChange: (nextHex: string) => void;
  twitter?: boolean;
  width?: string;
}) {
  const { helperText, color, onChange, twitter, width } = props;
  const [currentColor, setCurrentColor] = useState(color);
  const theme = useTheme();

  const onChangeColor = (nextColor: ColorResult) => {
    setCurrentColor(nextColor.hex);
    onChange(nextColor.hex);
  };

  if (twitter) {
    return (
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        sx={{ width: 1 }}
      >
        <Typography
          variant="caption"
          sx={{
            mb: 1,
          }}
        >
          {helperText}
        </Typography>
        <Twitter
          triangle="hide"
          color={currentColor}
          onChange={onChangeColor}
          width={width || '276px'}
          styles={{
            default: {
              card: {
                background: 'unset',
                boxShadow: 'unset',
                border: `1px dashed ${theme.palette.divider}`,
                borderRadius: 1.5,
              },
            },
          }}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="flex-start" flexDirection="column" sx={{ width: 1, zIndex: 100001 }}>
      <Typography
        variant="caption"
        sx={{
          mb: 1,
        }}
      >
        {helperText}
      </Typography>
      <SketchPicker
        color={currentColor}
        onChange={onChangeColor}
        disableAlpha
      />
    </Box>
  );
}
