import {
  Application,
  PublicListing,
  PublicListingForApplication,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import {
  Box,
  Container,
  LinearProgress,
  Typography,
  useTheme,
} from '@mui/material';

import { useState } from 'react';

import { useDevMode } from 'src/hooks';

import DevMode from './dev-mode';

export default function ViewBox(props: {
  children: React.ReactNode;
  application: Application;
  jobProfile?: UniformProfileDefinition;
  jobListing?: PublicListingForApplication;
  jobListingPublic?: PublicListing;
  progress: number;
  hideDevMode?: boolean;
  hideCompanyName?: boolean;
  hideDescription?: boolean;
  hideProgress?: boolean;
  active?: string;
  setActive?: (id: string) => void;
  tabList?: { id: string; name: string; isComplete: boolean; partialCompletePercent?: number }[];
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    children,
    jobListing,
    progress,
    hideDevMode,
    hideDescription,
    hideProgress,
    hideCompanyName,
    active,
    setActive,
    tabList,
  } = props;

  // const isDesktop = useResponsive('up', 'md');
  const theme = useTheme();

  const devModeAvailable = useDevMode();
  const [isDevMode, setIsDevMode] = useState(false);

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: theme.palette.primary.darker,
          padding: 3,
          width: '100%',
        }}
      >
        <Container>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            sx={{ borderRadius: '16px' }}
          >
            {!hideCompanyName && (
              <Typography variant="body2" color={theme.palette.background.paper}>
                {jobListing?.companyName}
              </Typography>
            )}
            <Typography variant="h3" color={theme.palette.background.paper}>
              {jobListing?.name}
            </Typography>
            {!hideDescription && (
              <Typography variant="body1" color={theme.palette.background.paper} sx={{ pt: 2 }}>
                {jobListing?.description}
              </Typography>
            )}
            {!hideProgress && (
              <Box>
                {progress !== -1 && (
                  <Box sx={{ pt: 3 }}>
                    <Box display="flex" flexDirection="column" rowGap={1}>
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress
                          color="primary"
                          variant="determinate"
                          value={progress === 0 ? 1 : progress * 100}
                          sx={{
                            height: '12px',
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            <Box>
              {active && setActive && tabList && !hideDevMode && (
                <DevMode
                  devModeAvailable={devModeAvailable}
                  isDevMode={isDevMode}
                  setIsDevMode={setIsDevMode}
                  active={active}
                  setActive={setActive}
                  tabList={tabList}
                  progress={progress}
                  jobListing={jobListing}
                />
              )}
            </Box>

          </Box>
        </Container>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: theme.palette.background.paper,
          width: '100%',
          height: '100%',
          borderBottomLeftRadius: '16px',
          borderBottomRightRadius: '16px',
        }}
      >
        <Container>
          {children}
        </Container>
      </Box>
    </Box>
  );
}
