import { EducationTypeExt } from '@piccadilly-cloud/connect-platform-services';

import { Button } from '@mui/material';

import { useEffect } from 'react';

import Iconify from 'src/components/iconify';
import { piccIcon } from 'src/utils';
import AdditionalEducationSelect from 'src/views/listing/listing-editor/steps/partials/additional-education-select';

interface AdditionalEducationFormProps {
  fields: { type: string; progress: number | null }[];
  onAdd: () => void;
  onRemove: (index: number) => void;
  onUpdate: (index: number, type: string, progress: number) => void;
}

export default function AdditionalEducationForm({
  fields,
  onAdd,
  onRemove,
  onUpdate,
}: AdditionalEducationFormProps) {
  useEffect(() => {
    if (fields.length === 0) {
      onAdd();
    }
  });

  return (
    <>
      {fields.map((field, index) => (
        <AdditionalEducationSelect
          key={`${field}-${index}`}
          index={index}
          type={field.type as EducationTypeExt}
          progress={field.progress}
          onUpdate={(type, progress) => onUpdate(index, type, progress)}
          onRemove={() => onRemove(index)}
          selected={fields}
        />
      ))}
      <Button
        startIcon={<Iconify icon={piccIcon.actions.add} />}
        color="primary"
        onClick={onAdd}
      >
        Add education
      </Button>
    </>
  );
}
