import { ActionsType, AuthStateType, SessionTypes } from '../types';

const dynamicVendorReducer = (state: AuthStateType, action: ActionsType): AuthStateType => {
  if (action.type !== SessionTypes.UPDATE_DYNAMIC_VENDOR) {
    return state;
  }

  return {
    ...state,
    dynamicVendor: action.payload.dynamicVendor,
  };
};

const dynamicVendor = {
  reducers: {
    [SessionTypes.UPDATE_DYNAMIC_VENDOR]: dynamicVendorReducer,
  },
};

export default dynamicVendor;
