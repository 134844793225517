import { PublicListingForApplication, UniformProfileDefinition } from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography } from '@mui/material';

import { useEffect } from 'react';

import { LoadingScreen } from 'src/components/loading-screen';
import { useApplicationFlowContext, useSessionContext } from 'src/contexts';
import Controller from 'src/views/candidate/application/controller';
import NotAvailable from 'src/views/candidate/application/not-available';

interface DataWrapperProps {
  listing?: PublicListingForApplication;
  profile?: UniformProfileDefinition;
  emit?: {
    close?: () => void;
  };
  isPreview?: boolean;
}

export default function DataWrapper(props: DataWrapperProps) {
  const { listing, profile, emit, isPreview } = props;
  const {
    application,
    isLoading,
    jobListing,
    jobProfile,
    dispatch,
  } = useApplicationFlowContext();
  const session = useSessionContext();

  useEffect(() => {
    if (isPreview && listing && profile) {
      dispatch['applicationFlow/setIsPreview']({
        isPreview: true,
      });
      dispatch['applicationFlow/setListingName']({
        listingName: listing.name,
      });
      dispatch['applicationFlow/setListingCompanyName']({
        companyName: session.appConfig.branding.name,
      });

      dispatch['applicationFlow/setJobListing']({
        jobListing: listing,
      });

      dispatch['applicationFlow/setJobProfile']({
        jobProfile: profile,
      });
    }
  }, [dispatch, isPreview, listing, profile, session.appConfig.branding.name]);

  if (isLoading) {
    return <LoadingScreen />;
  }

  const isValid = application && jobProfile && jobListing
    && jobListing.id !== '' && jobProfile.profileId !== '';

  if (!isValid) {
    return (
      <Box>
        <Typography variant="caption">
          Something went wrong. Please try again.
        </Typography>
      </Box>
    );
  }

  if (!jobListing.isAvailable) {
    return (
      <NotAvailable
        application={application}
        jobProfile={jobProfile}
        jobListing={jobListing}
      />
    );
  }

  return (
    <Controller emit={emit} />
  );
}
