import { Box, IconButton, Typography, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';

export default function ButtonStop(props: {
  onStop: VoidFunction;
  disabled: boolean;
}) {
  const { onStop, disabled } = props;

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <IconButton
          onClick={onStop}
          disabled={disabled}
        >
          <Iconify
            icon="material-symbols:stop-circle-outline-rounded"
            width="44px"
            color={disabled ? theme.palette.error.light : theme.palette.error.main}
          />
        </IconButton>
        <Typography variant="body2" color={theme.palette.grey[700]}>
          Stop
        </Typography>
      </Box>
    </Box>
  );
}
