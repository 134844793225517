import {
  Autocomplete,
  Box,
  InputAdornment,
  Paper,
  PaperProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { debounce } from 'lodash';
import React, { useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import addressAutocomplete from 'src/ctrl/addressAutocomplete';

// ----------------------------------------------------------------------

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  helperText?: React.ReactNode;
  countryCode?: string;
  citiesOnly?: boolean;
  disableDefaultSubmit?: boolean;
  size?: 'small' | 'medium';
};

export default function RHFAddressStyled({
  name,
  label,
  placeholder,
  helperText,
  countryCode,
  citiesOnly,
  disableDefaultSubmit,
  size,
}: Props) {
  const { control, setValue, watch } = useFormContext();
  const session = useSessionContext();
  const optionSelected = useRef(false);
  const settings = useSettingsContext();
  const theme = useTheme();

  // Set a session token for Maps API when component mounts
  const requestToken = useMemo(() => uuidv4(), []);

  const value = watch(name) ?? '';
  const suggestions = watch('suggestions') ?? [];

  const fetchSuggestions = useMemo(() => debounce((input: string) => {
    const trimmedInput = input.trim();
    const uniqueInput = trimmedInput !== '' && trimmedInput !== value;
    if (uniqueInput && !optionSelected.current) {
      setValue('suggestions', []);
      addressAutocomplete.getAutocompleteSuggestions(
        session,
        input,
        requestToken,
        citiesOnly ?? false,
        countryCode ?? '',
      )
        .then((response) => {
          setValue('suggestions', response.predictions);
        });
    } else {
      setValue('suggestions', []);
    }

    optionSelected.current = false;
  }, 400), [citiesOnly, countryCode, requestToken, session, setValue, value]);

  const fetchDetails = (id: string) => addressAutocomplete.getAddressDetails(session, id, requestToken)
    .then((response) => {
      optionSelected.current = true;
      setValue(name, response.formattedAddress, { shouldValidate: true });
    });

  const customPaperComponent = ({ children, ...paperProps }: PaperProps) => {
    let imgSrc = '/assets/icons/google/google_on_white.png';
    if (settings.themeMode === 'dark') {
      imgSrc = '/assets/icons/google/google_on_non_white.png';
    }

    const googleLogo = (
      <img
        src={imgSrc}
        alt="Google Logo"
        style={{ maxWidth: 50 }}
      />
    );

    const poweredByText = (
      <Box display="flex" sx={{ width: '100%', alignItems: 'flex-end' }}>
        <Typography variant="caption" sx={{ py: 1, px: 2, textAlign: 'right', width: '100%' }}>
          Powered By
          {' '}
          {googleLogo}
        </Typography>
      </Box>
    );

    return (
      <Paper {...paperProps} style={{ ...paperProps.style }}>
        {children}
        {suggestions.length > 0 && poweredByText}
      </Paper>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          freeSolo
          getOptionLabel={(option) => typeof option === 'string' ? option : option.fullAddress}
          options={suggestions}
          autoComplete
          noOptionsText="No results"
          onInputChange={(_, newInputValue) => {
            fetchSuggestions(newInputValue);
          }}
          onChange={(_, newValue) => {
            if (newValue && typeof newValue === 'object') {
              fetchDetails(newValue.id);
            } else {
              setValue(name, newValue, { shouldValidate: true });
            }
          }}
          PaperComponent={customPaperComponent}
          renderInput={(params) => (
            <TextField
              {...params}
              size={size || 'small'}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error ? error?.message : helperText}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="end">
                    <Iconify icon="mdi:map-marker" sx={{ color: theme.palette.grey[300] }} />
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && disableDefaultSubmit) {
                  e.preventDefault();
                }
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <Box display="flex" flexDirection="column">
                <Typography variant="body1">
                  {option.mainText || option}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {option.secondaryText}
                </Typography>
              </Box>
            </li>
          )}
        />
      )}
    />
  );
}
