import services, {
  ApplicationAssessmentResponse,
  ApplicationAssessmentResponseData,
  AssessmentQuestion,
  AssessmentResponseType,
  PrivateDocumentRequest,
  SupportedAudioEncoding,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography, useTheme } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FileRejection } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import FormProvider, { RHFUploadAudio } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import { LoadingInline } from 'src/components/loading-screen';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import ctrl from 'src/ctrl';
import {
  TrackingEvent,
  UseBoolType,
  useEventTracker,
  useResponsive,
  useToast,
} from 'src/hooks';

import AnswerDialogActions from '../answer-control-box';

interface FormType {
  uploadUrl: string;
  uploadId: string;
}

export default function AnswerUploadAudio(props: {
  onSave: (data: ApplicationAssessmentResponseData) => void;
  loading: boolean;
  loadingDelete: boolean;
  onDelete: (id: string) => Promise<void>;
  onClose: VoidFunction;
  currentAnswer?: ApplicationAssessmentResponse;
  minLengthSeconds: number;

  answerType: string;
  question: AssessmentQuestion & {
    main: string;
    lighter: string;
  } & { currentAnswer?: ApplicationAssessmentResponse | undefined };
  activeChange: UseBoolType;
  setActiveChangeDialogOpen: (next: string) => void;
  setAnswerType: (next: string) => void;
  jobProfile: UniformProfileDefinition;
}) {
  const theme = useTheme();
  const session = useSessionContext();
  const eventTracker = useEventTracker();
  const isDesktop = useResponsive('up', 'md');
  const toast = useToast();
  const {
    onSave,
    currentAnswer,
    loading,
    onClose,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');

  const methods = useForm<FormType>({
    resolver: yupResolver(Yup.object().shape({
      uploadUrl: Yup.string().required(),
      uploadId: Yup.string().required(),
    })),
    defaultValues: {
      uploadUrl: currentAnswer ? currentAnswer.documentPath : '',
      uploadId: currentAnswer ? currentAnswer.id : '',
    },
  });

  const {
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    watch,
  } = methods;

  const values = watch();

  useEffect(() => {
    if (values.uploadUrl !== '') {
      setLoadingDownloadUrl(true);
      services.edge.record.privateDocument.downloadFromUrl({ rawUrl: values.uploadUrl })({ token: session.token })
        .then((res) => {
          setDownloadUrl(res.downloadUrl);
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          setLoadingDownloadUrl(false);
        });
    }
  }, [session.token, values.uploadUrl]);

  const onSubmit = () => {
    const onValid = (data: FormType) => {
      const newData: ApplicationAssessmentResponseData = {
        jobProfileQuestionId: '',
        type: AssessmentResponseType.AUDIO,
        recordedInBrowser: false,
        processed: false,
        rawText: '',
        encoding: SupportedAudioEncoding.MP3,
        language: 'en-US',
        documentId: data.uploadId,
        documentPath: data.uploadUrl,
        sampleRateHertz: 16000,
      };
      onSave(newData);
    };

    eventTracker.trackEvent(TrackingEvent.UPLOAD_ASSESSMENT_ANSWER_AS_AUDIO, {
      applicationId: currentAnswer?.applicationId,
      answerId: currentAnswer?.id,
    });
    handleSubmit(onValid)();
  };

  const onUploadAsync = async (acceptedFiles: File[], rejectedFiles?: FileRejection[]): Promise<string> => {
    clearErrors('uploadUrl');
    if (rejectedFiles && rejectedFiles.length !== 0) {
      const rejectedFile = rejectedFiles[0];
      if (rejectedFile.errors.length !== 0) {
        const err = rejectedFile.errors[0];
        setError('uploadUrl', { message: err.message });
        return Promise.resolve('');
      }
    }
    if (acceptedFiles.length === 0) {
      return Promise.resolve('');
    }
    const file = acceptedFiles[0];
    const request: PrivateDocumentRequest = {
      tags: ['teq-audio-upload'],
      filename: file.name,
      directory: '',
      contentType: file.type,
      provider: '',
    };
    return ctrl.fileManager.uploadSignedPrivateDocument(session, file, request)
      .then((v) => {
        setValue('uploadUrl', v.rawUrl);
        setValue('uploadId', v.id);
        return v.id;
      }).catch((err) => {
        console.error(err);
        toast.error('Something went wrong, please try again');
        setError('uploadUrl', { message: 'Something went wrong, please try again' });
        return Promise.reject(new Error(err));
      });
  };

  return (
    <FormProvider methods={methods}>
      <Box
        sx={{
          px: 2,
          pb: isDesktop ? 0 : 3,
        }}
      >
        {values.uploadUrl && downloadUrl === '' && (
          <Box sx={{ width: '100%', pt: 2, pb: 3 }} display="flex" flexDirection="row" justifyContent="center">
            <LoadingInline />
          </Box>
        )}
        {values.uploadUrl && downloadUrl !== '' && (
          <Box>
            <Box sx={{ width: '100%', pt: 2 }}>
              <audio
                src={downloadUrl}
                preload="auto"
                controls
                style={{
                  display: 'block',
                  width: '100%',
                }}

              >
                <track kind="captions" />
              </audio>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" sx={{ pb: 1, pt: 1, pr: 1 }}>
              <Box
                component="a"
                target="_blank"
                href={downloadUrl}
                download
                display="flex"
                flexDirection="row"
                columnGap={0.5}
                alignItems="flex-end"
                sx={{
                  color: theme.palette.primary.main,
                  textDecoration: 'underline',
                  '&:hover': {
                    cursor: 'pointer',
                    fontWeight: 500,
                    color: theme.palette.primary.darker,
                  },
                  '&:focus': {
                    fontWeight: 800,
                    color: theme.palette.primary.darker,
                    textDecoration: 'underline',
                  },

                }}
              >
                <Iconify icon="mdi:tray-arrow-down" width="0.75rem" />
                <Typography
                  variant="caption"
                  sx={{
                  }}

                >
                  Download
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {!values.uploadUrl && (
          <RHFUploadAudio
            name="uploadUrl"
            onUploadAsync={onUploadAsync}
            loading={loading}
            maxSize={800000000}
          />
        )}
      </Box>

      {/* <DialogAnswerQuestionTypeControl
        answerType={answerType}
        question={question}
        activeChange={activeChange}
        setActiveChangeDialogOpen={setActiveChangeDialogOpen}
        setAnswerType={setAnswerType}
        jobProfile={jobProfile}
      /> */}

      <AnswerDialogActions
        loading={loading}
        currentAnswer={currentAnswer}
        onClose={onClose}
        onClick={onSubmit}
        saveButtonDisabled={values.uploadUrl === ''}
        isActive={false}
      />
    </FormProvider>

  );
}
