/* eslint-disable max-len */
import { Box, BoxProps, useTheme } from '@mui/material';

import { memo } from 'react';

function CheckmarkIllustration({ ...other }: BoxProps) {
  const theme = useTheme();

  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      viewBox="0 0 480 360"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <circle cx="235" cy="200" r="160" fill={PRIMARY_MAIN} opacity="0.2" />

      <defs>
        <linearGradient id="linearGradient-2" x1="30.113%" x2="30.113%" y1="0%" y2="100%">
          <stop offset="0%" stopOpacity="0" />
          <stop offset="100%" />
        </linearGradient>
      </defs>

      <g transform="translate(100 100) scale(20)">
        <path
          fill={PRIMARY_MAIN}
          fillRule="nonzero"
          d="M6.027 9.997a.625.625 0 0 1-.448-.186L1.62 5.028a.625.625 0 0 1 .894-.894l3.34 3.34 8.28-8.28a.625.625 0 0 1 .885.884L6.475 9.81a.625.625 0 0 1-.448.187z"
        />
      </g>

    </Box>
  );
}

export default memo(CheckmarkIllustration);
