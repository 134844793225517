import { Box } from '@mui/material';

import FormButton from 'src/views/candidate/application/form-controls/form-button';

export default function BoolBox(props: {
  value: boolean;
  onChange: (next: boolean) => void;
}) {
  const {
    value,
    onChange,
  } = props;

  return (
    <Box display="flex" flexDirection="row" columnGap={1} sx={{ pt: 1 }}>
      <FormButton
        onClick={() => onChange(false)}
        selected={!value}
      >
        No
      </FormButton>
      <FormButton
        onClick={() => onChange(true)}
        selected={value}
      >
        Yes
      </FormButton>
    </Box>
  );
}
