import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

export default function DialogConfirmAnswerChange(props: {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}) {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogContent sx={{ p: 3 }}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Typography variant="h6">Unsaved changes</Typography>
          <Typography variant="body1">
            If you leave this page without saving, your answer will be lost.
          </Typography>

          <Box display="flex" flexDirection="row" justifyContent="flex-end" columnGap={1}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              onClick={onConfirm}
            >
              Continue
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
