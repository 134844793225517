import {
  ApplicationAssessmentResponse,
  ApplicationAssessmentResponseData,
  AssessmentQuestion,
  AssessmentResponseType,
  PrivateDocumentRequest,
  SupportedAudioEncoding,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography } from '@mui/material';

import { useEffect, useRef, useState } from 'react';

import { useSessionContext } from 'src/contexts/session/useSessionContext';
import ctrl from 'src/ctrl';
import { TrackingEvent, UseBoolType, useEventTracker } from 'src/hooks';
import { generateId } from 'src/utils';
import textUtil from 'src/utils/text-util';

import AnswerControlBox from '../answer-control-box';
import AudioControls from '../audio-recording/audio-controls';
import AudioTrack from '../audio-recording/audio-track';
import ButtonRecord from '../audio-recording/button-record';
import UnsupportedErr from '../audio-recording/unsupported-error';
import DialogConfirmDelete from '../dialog-confirm-delete';
import DialogRecordAudioConfirm from '../dialog-record-audio-confirm';
import QuestionLayout from '../question-layout';

export default function AnswerRecordAudio(props: {
  onSave: (data: ApplicationAssessmentResponseData) => void;
  loading: boolean;
  loadingDelete: boolean;
  onDelete: (id: string) => Promise<void>;
  onClose: VoidFunction;
  currentAnswer?: ApplicationAssessmentResponse;
  minLengthSeconds: number;
  onActive: VoidFunction;
  isActive: boolean;

  answerType: string;
  question: AssessmentQuestion & {
    main: string;
    lighter: string;
  } & { currentAnswer?: ApplicationAssessmentResponse | undefined };
  activeChange: UseBoolType;
  setActiveChangeDialogOpen: (next: string) => void;
  setAnswerType: (next: string) => void;
  jobProfile: UniformProfileDefinition;

}) {
  const session = useSessionContext();
  const eventTracker = useEventTracker();
  const {
    onSave,
    currentAnswer,
    loading,
    loadingDelete,
    onClose,
    minLengthSeconds,
    onActive,
    isActive,
    question,
    jobProfile,
  } = props;

  const [permission, setPermission] = useState<boolean | undefined>();
  const [unsupportedErr, setUnsupportedErr] = useState(false);
  const mediaRecorder = useRef<MediaRecorder | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stream, setStream] = useState<MediaStream>();
  const [recordingStatus, setRecordingStatus] = useState('inactive');
  const [audioChunks, setAudioChunks] = useState<Blob[]>([]);
  const [audio, setAudio] = useState<string>('');
  const [audioBlob, setAudioBlob] = useState<Blob | undefined>();
  const [mimeType, setMimeType] = useState('audio/webm');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [counter, setCounter] = useState(0);
  const [backgroundCounter, setBackgroundCounter] = useState(0);

  const containerBox = useRef<HTMLDivElement>(null);

  const [paused, setPaused] = useState(false);

  const [confirmDoneDialog, setConfirmDoneDialog] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    // reset when the question id changes
    setStream(undefined);
    setAudioChunks([]);
    setAudio('');
    setAudioBlob(undefined);
    setCounter(0);
  }, [question.id]);

  useEffect(() => {
    if (recordingStatus === 'active' && !paused) {
      setTimeout(() => {
        if (!paused) {
          setCounter(counter + 1);
        }
      }, 1000);
    }
  }, [counter, recordingStatus, paused]);

  useEffect(() => {
    if (paused) {
      setTimeout(() => {
        setBackgroundCounter(backgroundCounter + 1);
      }, 1000);
    }
  }, [backgroundCounter, recordingStatus, paused]);

  const stopRecording = () => {
    setRecordingStatus('inactive');
    if (mediaRecorder.current) {
      mediaRecorder.current.onstop = () => {
        const blob = new Blob(audioChunks, { type: mimeType });
        setAudioBlob(blob);
        const audioUrl = URL.createObjectURL(blob);
        setAudio(audioUrl);
        setAudioChunks([]);
      };
      stream?.getTracks().forEach((t) => t.stop());
      mediaRecorder.current.stop();
    }
  };

  const onRecord = () => {
    if (unsupportedErr) {
      return;
    }
    if (audio === '') {
      if (recordingStatus === 'active') {
        stopRecording();
      } else {
        getMicrophonePermission();
      }
    }
  };

  const pauseRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.pause();
      setPaused(true);
    }
  };

  const restartRecording = () => {
    setRecordingStatus('inactive');
    setPermission(undefined);
    setAudioChunks([]);
    setStream(undefined);
    setAudio('');
    setAudioBlob(undefined);
  };

  const resumeRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.resume();
      setPaused(false);
    }
  };

  const getMicrophonePermission = () => {
    setPaused(false);
    if ('MediaRecorder' in window) {
      onActive();
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then((newStream) => {
          setStream(newStream);
          setPermission(true);
          setRecordingStatus('active');
          setCounter(0);

          const options = { mimeType };
          if (MediaRecorder.isTypeSupported(options.mimeType)) {
            // noop
          } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
            options.mimeType = 'audio/mp4';
            setMimeType('audio/mp4');
          } else if (MediaRecorder.isTypeSupported('video/mp4')) {
            options.mimeType = 'video/mp4';
            setMimeType('video/mp4');
          } else if (MediaRecorder.isTypeSupported('audio/mp4;codecs=aac')) {
            options.mimeType = 'audio/mp4;codecs=aac';
            setMimeType('audio/mp4;codecs=aac');
          } else {
            console.error('unsupported mimetype');
            setUnsupportedErr(true);
            return;
          }

          const media = new MediaRecorder(newStream, options);
          mediaRecorder.current = media;
          mediaRecorder.current.start();
          const localAudioChunks: Blob[] = [];
          mediaRecorder.current.ondataavailable = (recorderEvent) => {
            if (typeof recorderEvent.data === 'undefined') {
              return;
            }
            if (recorderEvent.data.size === 0) {
              return;
            }
            localAudioChunks.push(recorderEvent.data);
          };
          setAudioChunks(localAudioChunks);
        }).catch((err) => {
          console.error(err);
          setPermission(false);
        });
    }
  };

  const onSaveAudio = () => {
    if (audioBlob) {
      setLoadingLocal(true);
      const request: PrivateDocumentRequest = {
        tags: ['teq-audio-browser-capture'],
        filename: `audio-capture-${generateId()}.${mimeType.split('/')[1]}`,
        contentType: mimeType,
        directory: '',
        provider: '',
      };
      const file = new File([audioBlob], request.filename, { type: mimeType });
      ctrl.fileManager.uploadSignedPrivateDocument(session, file, request)
        .then((v) => {
          eventTracker.trackEvent(TrackingEvent.UPLOAD_AUDIO_FILE);
          const newData: ApplicationAssessmentResponseData = {
            jobProfileQuestionId: '',
            type: AssessmentResponseType.AUDIO,
            recordedInBrowser: false,
            processed: false,
            rawText: '',
            encoding: SupportedAudioEncoding.MP3,
            language: 'en-US',
            documentId: v.id,
            documentPath: v.rawUrl,
            sampleRateHertz: 16000,
          };
          onSave(newData);
          setLoadingLocal(false);
          return v.id;
        }).catch((err) => {
          console.error(err);
          setLoadingLocal(false);
        });
    }
  };

  return (
    <>
      <QuestionLayout
        currentQuestion={question}
        controlBox={(
          <AnswerControlBox
            loading={loading || loadingLocal}
            currentAnswer={currentAnswer}
            onClose={onClose}
            onClick={() => {
              onSaveAudio();
            }}
            saveButtonDisabled={audio === '' || counter < minLengthSeconds}
            isActive={isActive}
          />
        )}
      >
        <Box display="flex" flexDirection="column" rowGap={2} sx={{ pt: 2 }}>
          {(unsupportedErr || permission === false) && (
            <UnsupportedErr
              unsupportedErr={unsupportedErr}
              permissionErr={permission === false}
            />
          )}
          <Box>
            <Typography>
              Answer with a recording about two minutes long. Stop is enabled after you have recorded for
              {' '}
              {textUtil.numToStr((jobProfile.teqAssessment.defaultMinAudioLength || 120) / 60)}
              {' '}
              minute(s).
            </Typography>
          </Box>
          {audio !== '' && (
            <AudioTrack
              audio={audio}
              counter={counter}
              minLengthSeconds={minLengthSeconds}
              onDelete={() => {
                setDeleteDialogOpen(true);
              }}
            />
          )}
          {recordingStatus === 'active' && (
            <Box ref={containerBox}>
              <AudioControls
                currentCounter={counter}
                backgroundCounter={backgroundCounter}
                minLengthSeconds={minLengthSeconds}
                isPaused={paused}
                mediaRecorder={mediaRecorder}
                visualizerWidth={containerBox.current ? (containerBox.current.offsetWidth * 0.5) : '100%'}
                onPause={() => pauseRecording()}
                onResume={() => resumeRecording()}
                onStop={() => stopRecording()}
                onRestart={() => {
                  restartRecording();
                }}
                isStoppable={counter < minLengthSeconds}
              />
            </Box>
          )}

          {audio === '' && recordingStatus !== 'active' && (
            <Box sx={{ pb: 3 }}>
              <ButtonRecord onRecord={onRecord} />
            </Box>
          )}

        </Box>

      </QuestionLayout>
      <DialogRecordAudioConfirm
        open={confirmDoneDialog}
        onClose={() => {
          setConfirmDoneDialog(false);
        }}
        onConfirm={() => {
          stopRecording();
          setConfirmDoneDialog(false);
        }}
      />

      <DialogConfirmDelete
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        onConfirmDelete={() => {
          setAudio('');
          setAudioChunks([]);
          setCounter(0);
          setDeleteDialogOpen(false);
        }}
        loadingDelete={loadingDelete}
      />
    </>
  );
}
