/* eslint-disable react-hooks/rules-of-hooks */
import services from '@piccadilly-cloud/connect-platform-services';

import {
  ReactNode,
  Reducer,
  createContext,
  useEffect,
  useReducer,
} from 'react';

import useLogger from 'src/utils/useReducerLogger';

import { ContextMutations } from '../model';
import { useSessionContext } from '../session';

import { PurchaseFlowAction, PurchaseFlowDContextDispatch, PurchaseFlowDispatch, PurchaseFlowState } from './model';

type S = PurchaseFlowState;

const initState = (): S => ({
  address: undefined,
  dispatch: purchaseFlowDispatch(() => { }),
  isOpen: false,
  listing: undefined,
  numBundles: 1,
  paymentInfo: undefined,
  plans: [],
  selectedPlan: undefined,
  transaction: undefined,
});

const purchaseFlowMutations: ContextMutations<S, PurchaseFlowAction> = {
  'purchaseFlow/RESET_STATE': (state) => ({
    ...initState(),
    dispatch: state.dispatch,
  }),

  'purchaseFlow/SET_ADDRESS': (state, { address }) => ({
    ...state,
    address,
  }),

  'purchaseFlow/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),

  'purchaseFlow/SET_LISTING': (state, { listing }) => ({
    ...state,
    listing,
  }),

  'purchaseFlow/SET_IS_OPEN': (state, { isOpen }) => ({
    ...state,
    isOpen,
  }),

  'purchaseFlow/SET_NUM_BUNDLES': (state, { numBundles }) => ({
    ...state,
    numBundles,
  }),

  'purchaseFlow/SET_PAYMENT_INFO': (state, { paymentInfo }) => ({
    ...state,
    paymentInfo,
  }),

  'purchaseFlow/SET_PLANS': (state, { plans }) => ({
    ...state,
    plans,
  }),

  'purchaseFlow/SET_SELECTED_PLAN': (state, { selectedPlan }) => ({
    ...state,
    selectedPlan,
  }),

  'purchaseFlow/SET_TRANSACTION': (state, { transaction }) => ({
    ...state,
    transaction,
  }),
};

const reducer = (
  state: S,
  action: PurchaseFlowAction,
): S => purchaseFlowMutations[action.type](state, action.payload as any) ?? state;

const purchaseFlowDispatch: PurchaseFlowDContextDispatch = (dispatch): PurchaseFlowDispatch => ({
  'purchaseFlow/createTransaction': async (payload) => {
    try {
      const { session, numBundles, plan } = payload;
      const { transaction } = await services.edge.billing.transaction.create({
        numBundles,
        planId: plan.id,
      })({ token: session.token });
      dispatch({
        type: 'purchaseFlow/SET_TRANSACTION',
        payload: {
          transaction,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/fetchPlans': async (payload) => {
    try {
      const { session } = payload;
      const plans = await services.edge.billing.plan.getAll()({
        token: session.token,
      });
      dispatch({
        type: 'purchaseFlow/SET_PLANS',
        payload: {
          plans,
        },
      });
      dispatch({
        type: 'purchaseFlow/SET_SELECTED_PLAN',
        payload: {
          selectedPlan: plans[0],
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/fetchPublicListing': async (payload) => {
    try {
      const { session, listingVanityPath } = payload;
      if (!listingVanityPath) {
        return;
      }

      const listing = await services.edge.job.listing.getPublicListing(listingVanityPath)({
        token: session.token,
      });
      dispatch({
        type: 'purchaseFlow/SET_LISTING',
        payload: {
          listing,
        },
      });
    } catch (error) {
      console.error(error);
    }
  },

  'purchaseFlow/resetState': async () => {
    dispatch({ type: 'purchaseFlow/RESET_STATE', payload: {} });
  },

  'purchaseFlow/setAddress': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_ADDRESS', payload });
  },

  'purchaseFlow/setIsOpen': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_IS_OPEN', payload });
  },

  'purchaseFlow/setNumBundles': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_NUM_BUNDLES', payload });
  },

  'purchaseFlow/setPaymentInfo': async (payload) => {
    dispatch({ type: 'purchaseFlow/SET_PAYMENT_INFO', payload });
  },

  'purchaseFlow/updateTransactionPrice': async () => {
    // TODO: Implement
  },
});

export interface PurchaseFlowProviderProps {
  children: ReactNode;
}

export const PurchaseFlowContext = createContext<S | null>(null);
export function PurchaseFlowProvider({ children }: PurchaseFlowProviderProps) {
  const session = useSessionContext();
  const [state, dispatch] = useReducer<Reducer<S, PurchaseFlowAction>>(
    process.env.REACT_APP_DEBUG_REDUX === 'true'
      ? useLogger(reducer)
      : reducer,
    initState(),
  );

  useEffect(() => {
    dispatch({
      type: 'purchaseFlow/SET_DISPATCH',
      payload: { dispatch: purchaseFlowDispatch(dispatch) },
    });
  }, [dispatch]);

  useEffect(() => {
    state.dispatch['purchaseFlow/fetchPlans']({
      session,
    });
  }, [
    session,
    session.token,
    state.dispatch,
  ]);

  return (
    <PurchaseFlowContext.Provider value={state}>
      {children}
    </PurchaseFlowContext.Provider>
  );
}
