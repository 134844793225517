import { Box, Typography, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';
import { piccIcon } from 'src/utils';

import HeaderBanner from '../v2/_common/header-banner';

export default function Banner() {
  const theme = useTheme();

  return (
    <HeaderBanner height="76px" color={theme.palette.info.lighter}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap={1}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          columnGap={1}
          sx={{ color: theme.palette.background.paper }}
        >
          <Iconify icon={piccIcon.alertBanner.info} color={theme.palette.info.main} />
          <Typography
            variant="h6"
            color={theme.palette.info.darker}
          >
            This is a preview of the assessment. No answers will be saved.
          </Typography>
        </Box>
      </Box>
    </HeaderBanner>
  );
}
