import { Box } from '@mui/material';

import { MutableRefObject } from 'react';

import ButtonPause from './button-pause';
import ButtonRestart from './button-restart';
import ButtonResume from './button-resume';
import ButtonStop from './button-stop';
import Counter from './counter';
import Visualizer from './visualizer';

export default function AudioControls(props: {
  currentCounter: number;
  backgroundCounter: number;
  minLengthSeconds: number;
  isPaused: boolean;
  mediaRecorder: MutableRefObject<MediaRecorder | null>;
  visualizerWidth: string | number | undefined;

  onPause: VoidFunction;
  onResume: VoidFunction;
  onRestart: VoidFunction;
  onStop: VoidFunction;

  isStoppable: boolean;
}) {
  const {
    currentCounter,
    backgroundCounter,
    minLengthSeconds,
    isPaused,
    mediaRecorder,
    visualizerWidth,
    onPause,
    onResume,
    onRestart,
    onStop,
    isStoppable,
  } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box>
        <Counter
          currentCounter={currentCounter}
          backgroundCounter={backgroundCounter}
          minLengthSeconds={minLengthSeconds}
          isPaused={isPaused}
        />
      </Box>

      <Box>
        <Visualizer
          backgroundCounter={backgroundCounter}
          isPaused={isPaused}
          mediaRecorder={mediaRecorder}
          visualizerWidth={visualizerWidth}
        />
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-end" columnGap={3}>
        <ButtonRestart
          onRestart={onRestart}
        />

        <ButtonStop
          onStop={onStop}
          disabled={isStoppable}
        />

        {isPaused && (
          <ButtonResume
            onResume={onResume}
          />
        )}
        {!isPaused && (
          <ButtonPause
            onPause={onPause}
          />
        )}
      </Box>
    </Box>
  );
}
