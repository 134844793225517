import { useCallback, useState } from 'react';

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>();

  const start = useCallback((newMessage?: string) => {
    setIsLoading(true);
    setMessage(newMessage);
  }, []);

  const stop = useCallback(() => {
    setIsLoading(false);
    setMessage(undefined);
  }, []);

  return {
    isLoading,
    message,
    setMessage,
    start,
    stop,
  };
};
