import { FroalaUploadRequest } from '@piccadilly-cloud/connect-platform-services';

/* eslint-disable import/extensions */
import { Box, Typography } from '@mui/material';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/js/third_party/embedly.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/third_party/embedly.min.css';
import 'froala-editor/css/plugins/video.min.css';
import 'froala-editor/css/plugins/image.min.css';

import { FieldError } from 'react-hook-form';

import FroalaEditor from './Froala';

export interface WYSIWYGProps {
  dataKey: string;
  initialValue: string;
  onChange: (nextValue: string) => void;
  assetManagerImageOpts: () => FroalaUploadRequest;
  defaultText?: string;
  canvasEditor?: boolean;
  richTextOnly?: boolean;
  error?: FieldError;
  helperMessage?: string;
}

export default function WYSIWYG(props: WYSIWYGProps) {
  const {
    dataKey,
    initialValue,
    onChange,
    assetManagerImageOpts,
    defaultText,
    canvasEditor,
    richTextOnly,
    error,
    helperMessage,
  } = props;
  return (
    <Box sx={{
      zIndex: 1000,
    }}
    >
      <Box
        sx={{
          ...(!!error && {
            border: '1px solid red',
            borderRadius: 1.5,
          }),
        }}
      >
        <FroalaEditor
          editorKey={dataKey}
          defaultHtmlValue={initialValue}
          onChange={onChange}
          assetManagerImageOpts={assetManagerImageOpts}
          defaultText={defaultText}
          canvasEditor={canvasEditor}
          richTextOnly={richTextOnly}
        />
      </Box>
      <Box sx={{ pl: 2 }}>
        <Typography variant="caption" color={error ? 'error' : 'unset'}>
          {helperMessage}
        </Typography>
      </Box>
    </Box>
  );
}
