import { FroalaUploadRequest } from '@piccadilly-cloud/connect-platform-services';

import React from 'react';
import Froala from 'react-froala-wysiwyg';

export default function FroalaEditor(props: {
  editorKey: string;
  defaultHtmlValue: string;
  onChange: (nextHtmlValue: string) => void;
  assetManagerImageOpts: () => FroalaUploadRequest;
  defaultText?: string;
  canvasEditor?: boolean;
  richTextOnly?: boolean;
}) {
  const {
    editorKey,
    defaultHtmlValue,
    onChange,
    assetManagerImageOpts,
    defaultText,
    canvasEditor,
    richTextOnly,
  } = props;

  const [isFroalaInitialized, setIsFroalaInitialized] = React.useState(false);
  const [model, setModel] = React.useState<string>('');
  const [currentEditorKey, setCurrentEditorKey] = React.useState('');

  const defaultValueCB = React.useCallback(() => {
    if (!isFroalaInitialized && editorKey !== '') {
      setIsFroalaInitialized(true);
      setCurrentEditorKey(editorKey);
      setModel(defaultHtmlValue);
    }
    if (editorKey !== currentEditorKey) {
      setCurrentEditorKey(editorKey);
      setModel(defaultHtmlValue);
    }
  }, [currentEditorKey, isFroalaInitialized, defaultHtmlValue, editorKey]);
  React.useEffect(defaultValueCB, [defaultValueCB, editorKey]);

  const handleModelChange = (model1: any, _: any) => {
    if (editorKey === currentEditorKey) {
      onChange(model1);
      setModel(model1);
    }
  };

  return (
    <Froala
      model={model}
      onModelChange={handleModelChange}
      config={{
        key: process.env.REACT_APP_F_LICENSE,
        attribution: false,
        placeholder: 'Start typing...',
        heightMin: canvasEditor ? '20rem' : '12rem',
        zIndex: 99999,
        placeholderText: defaultText || 'Start typing...',
        videoUploadParam: 'file',
        videoUploadURL: `${process.env.REACT_APP_EDGE_URL}/upload/froala`,
        videoUploadParams: { request: JSON.stringify(assetManagerImageOpts()) },
        videoUploadMethod: 'POST',
        videoMaxSize: 50 * 1024 * 1024,
        videoAllowedTypes: ['webm', 'jpg', 'ogg', 'mov', 'MOV', 'mp4', 'quicktime'],
        videoAllowedProviders: ['youtube', 'vimeo'],

        imageUploadURL: `${process.env.REACT_APP_EDGE_URL}/upload/froala`,
        imageUploadParam: 'file',
        imageUploadMethod: 'POST',
        imageUploadParams: { request: JSON.stringify(assetManagerImageOpts()) },
        htmlExecuteScripts: false,
        events: {
          'image.beforeUpload': (_: any) => {},
          'image.uploaded': (_: any) => {},
          'image.error': (_: any, _2: any) => {},
        },
        fontFamily: {
          'Open Sans,Helvetica,sans-serif': 'Open Sans',
        },
        fontFamilySelection: false,
        fontFamilyDefaultSelection: 'Open Sans',
        toolbarButtons: {
          moreText: {
            buttons: [
              'bold',
              'italic',
              'underline',
              'fontSize',
              'strikeThrough',
              'subscript',
              'superscript',
              'textColor',
              'backgroundColor',
              'inlineClass',
              'inlineStyle',
              'clearFormatting',
            ],
          },
          moreParagraph: {
            buttons: [
              'alignLeft',
              'alignCenter',
              'formatOLSimple',
              'alignRight',
              'alignJustify',
              'formatOL',
              'formatUL',
              'paragraphFormat',
              'paragraphStyle',
              'lineHeight',
              'outdent',
              'indent',
              'quote',
            ],
          },
          moreRich: {
            buttons:
              richTextOnly ? []
                : [
                  'insertLink',
                  'insertImage',
                  'insertVideo',
                  'insertTable',
                  'emoticons',
                  'fontAwesome',
                  'specialCharacters',
                  'embedly',
                  // "insertFile",
                  'insertHR',
                ],
          },
          moreMisc: {
            buttons: [
              'undo',
              'redo',
              'fullscreen',
              'print',
              'spellChecker',
              'selectAll',
              'html',
              'help',
            ],
            align: 'right',
            buttonsVisible: 2,
          },
        },
        pluginsEnabled: [
          'table',
          'spell',
          'quote',
          'save',
          'paragraphFormat',
          'paragraphStyle',
          'help',
          'draggable',
          'fontSize',
          'align',
          'link',
          'lists',
          'file',
          'image',
          'emoticons',
          'url',
          'video',
          'embedly',
          'colors',
          'entities',
          'inlineClass',
          'inlineStyle',
          'spellChecker',
          'imageTUI',
        ],
      }}
    />
  );
}
