import { UserAccount } from '@piccadilly-cloud/connect-platform-services';

const SEGMENT_TOKEN = 'gDxGe0ri2p7DD1988C7NdiDDKyAwqsdT';
export const initSegment = () => {
  if (!window.analytics?.load) {
    console.info('Analytics not initialized.');
    return;
  }

  window.analytics.load(SEGMENT_TOKEN);
};

// export const trackPageViewSegment = (nextLocation: Route, prevLocation: Route) => {
//   // Delay tracking page views for performance.
//   setTimeout(() => {
//     window.analytics.page('Navigate', {
//       path: nextLocation.path,
//       referrer: prevLocation.path,
//     });
//   }, 500);
// };

export const identifyAnonymousUserSegment = () => {
  if (!window.analytics?.identify) {
    console.info('Analytics not initialized. Unable to identify user.');
    return;
  }

  window.analytics.identify();
};

export const getSegmentIdentityFromState = (user: UserAccount) => {
  const {
    accountRole,
    personalInfo,
    email,
  } = user;
  const { firstName, lastName } = personalInfo;
  const name = `${firstName} ${lastName}`;
  return {
    email,
    name,
    role: accountRole,
  };
};

export const identifyAuthenticatedUserSegment = (user: UserAccount) => {
  const segmentIdentity = getSegmentIdentityFromState(user);
  if (!window.analytics?.identify) {
    console.info('Analytics not initialized. Unable to identify user.');
    return;
  }

  console.info('Identifying user', user.id);
  window.analytics.identify(user.id, segmentIdentity);
};
