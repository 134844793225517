import { EducationProgress, EducationTypeExt } from '@piccadilly-cloud/connect-platform-services';

import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';

import React, { useEffect } from 'react';

import Iconify from 'src/components/iconify';
import { piccIcon } from 'src/utils';

import listingConfig from '../../config';

type AdditionalEducationSelectProps = {
  index: number;
  onUpdate: (type: EducationTypeExt, prog: EducationProgress) => void;
  type: EducationTypeExt | null;
  progress: EducationProgress | null;
  onRemove: VoidFunction;
  selected?: { type: string; progress: number | null }[];
  fieldError?: string;
};

export default function AdditionalEducationSelect(props: AdditionalEducationSelectProps) {
  const { index, onUpdate, onRemove, type, progress, selected, fieldError } = props;
  const [key, setKey] = React.useState(type ?? '');
  const [value, setValue] = React.useState<number | ''>(progress ?? '');
  const theme = useTheme();

  const isRemoveDisabled = index === 0 && (key === '' || value === '');

  const handleKeyChange = (event: SelectChangeEvent) => {
    const newKey = event.target.value;
    setKey(newKey);

    if (newKey && value === '') {
      setValue(1);
    }
  };

  const handleValueChange = (event: SelectChangeEvent) => {
    const numberValue = Number(event.target.value);
    setValue(numberValue);
  };

  useEffect(() => {
    if (key && value) {
      onUpdate(key as EducationTypeExt, value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key, value]);

  return (
    <Box>
      <Box display="flex" flexDirection="row" columnGap={1} sx={{ justifyContent: 'space-between', py: 1 }}>
        <FormControl fullWidth>
          <InputLabel id="key-label">Type</InputLabel>
          <Select
            labelId="key-label"
            label="Type"
            value={key}
            onChange={handleKeyChange}
            displayEmpty
            defaultValue=""
          >
            <MenuItem value="default" disabled>
              Choose additional education or training
            </MenuItem>
            {listingConfig.additionalEducationOpts.map((option) => {
              const isDisabled = selected?.some((item) => item.type === option.value);
              return (
                <MenuItem key={option.label} value={option.value} disabled={isDisabled}>
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel id="value-label">Progress</InputLabel>
          <Select
            labelId="value-label"
            label="Progress"
            value={value !== '' ? String(value) : ''}
            onChange={handleValueChange}
            displayEmpty
            defaultValue=""
          >
            <MenuItem value={0} disabled>
              Choose progress
            </MenuItem>
            {listingConfig.educationProgressOpts.map((option) => (
              <MenuItem key={option.label} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton
          onClick={() => {
            onRemove();
            setKey('');
            setValue('');
          }}
          disableRipple
          disabled={isRemoveDisabled}
          sx={{ cursor: 'pointer' }}
        >
          <Iconify
            icon={piccIcon.actions.close}
            color={isRemoveDisabled ? theme.palette.grey[500] : theme.palette.error.main}
            width={24}
          />
        </IconButton>
      </Box>
      {fieldError && key === '' && (
        <Typography variant="body2" color={theme.palette.error.main} sx={{ pt: 1 }}>
          {fieldError}
        </Typography>
      )}
    </Box>
  );
}
