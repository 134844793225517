import { UniformListingDefinition, UniformProfileDefinition } from '@piccadilly-cloud/connect-platform-services';

import { ListingEditorFlowStep, ListingEditorFlowStepId } from './model';

export const initListing = (): UniformListingDefinition => ({
  id: '',
  profileId: '',
  profileName: '',
  profileJobTitle: '',
  isAssessmentOnly: false,
  isReviewRequired: false,
  updatedAt: new Date(),
  status: 'DRAFT',
  statusChangedAt: new Date(),
  overview: {
    title: '',
    url: '',
    description: '',
    referenceId: '',
    externalListingUrl: '',
    location: '',
    locationType: '',
    positionStatus: '',
    positionDuration: '',
    isComplete: false,
  },
  availability: {
    willingToTravelFrequency: 1,
    willingToRelocate: 1,
    profileFields: {},
    isComplete: false,
  },
  eligibility: {
    experienceLevel: 1,
    experienceImportance: 1,
    educationLevel: 1,
    educationImportance: 1,
    ageRequirement: 5,
    authorizedToWork: 5,
    certificates: [],
    certificatesImportance: 1,
    isCertificationsEnabled: true,
    additionalEducation: [],
    additionalEducationImportance: 1,
    isAdditionalEducationEnabled: false,
    profileFields: {},
    isComplete: false,
  },
  teqTraitsInOrder: [],
  teqTraits: {
    adaptingAndCoping: 1,
    analyzingAndInterpreting: 1,
    creatingAndConceptualizing: 1,
    enterprisingAndPerforming: 1,
    interactingAndPresenting: 1,
    leadingAndDeciding: 1,
    organizingAndExecuting: 1,
    supportingAndCooperating: 1,
    conscientiousness: 1,
    empathy: 1,
    enthusiasm: 1,
    grit: 1,
    opennessToExperience: 1,
    proactiveness: 1,
    agreeableness: 1,
    resilience: 1,
    extraversion: 1,
    selfDetermination: 1,
    sustainedDedication: 1,
    delayedGratification: 1,
    negativeExpressionOfEmotion: 1,
  },
  teqQuestions: {
    selectedQuestions: [],
  },
  isReadyToPublish: false,
});

export const initProfile = (): UniformProfileDefinition => ({
  profileName: '',
  profileId: '',
  family: '',
  teqAssessment: {
    questions: {
      allQuestions: [],
      sections: [],
    },
    traits: {
      adaptingAndCoping: 1,
      analyzingAndInterpreting: 1,
      creatingAndConceptualizing: 1,
      enterprisingAndPerforming: 1,
      interactingAndPresenting: 1,
      leadingAndDeciding: 1,
      organizingAndExecuting: 1,
      supportingAndCooperating: 1,
      conscientiousness: 1,
      empathy: 1,
      enthusiasm: 1,
      grit: 1,
      opennessToExperience: 1,
      proactiveness: 1,
      agreeableness: 1,
      resilience: 1,
      extraversion: 1,
      selfDetermination: 1,
      sustainedDedication: 1,
      delayedGratification: 1,
      negativeExpressionOfEmotion: 1,
    },
    numberRequired: 0,
    defaultMinWords: 0,
    defaultMaxWords: 0,
    defaultMinAudioLength: 0,
    defaultMaxAudioLength: 0,
    enableFileUpload: false,
  },
  eligibility: {
    fields: [],
  },
  location: {
    fields: [],
  },
  availability: {
    fields: [],
  },
  education: {
    fields: [],
    isCertificationsEnabled: false,
    isAdditionalEducationEnabled: false,
  },
  experience: {
    fields: [],
  },
});

export const getEditorStepMap = () => {
  const stepMap = new Map<ListingEditorFlowStepId, ListingEditorFlowStep>();
  stepMap.set(ListingEditorFlowStepId.JOB, {
    title: 'Select a job',
    stepName: ListingEditorFlowStepId.JOB,
    navStepTitle: 'Job',
  });
  stepMap.set(ListingEditorFlowStepId.OVERVIEW, {
    title: 'Overview',
    stepName: ListingEditorFlowStepId.OVERVIEW,
    stepSuccessMessage: 'Successfully saved Job overview',
  });
  stepMap.set(ListingEditorFlowStepId.AVAILABILITY, {
    title: 'Availability',
    stepName: ListingEditorFlowStepId.AVAILABILITY,
    stepSuccessMessage: 'Successfully saved Availability',
  });
  stepMap.set(ListingEditorFlowStepId.ELIGIBILITY, {
    title: 'Eligibility',
    stepName: ListingEditorFlowStepId.ELIGIBILITY,
    stepSuccessMessage: 'Successfully saved Eligibility',
  });
  stepMap.set(ListingEditorFlowStepId.TEQ_INTERVIEW, {
    title: 'Behavioral and motivational traits',
    stepName: ListingEditorFlowStepId.TEQ_INTERVIEW,
    navStepTitle: 'Traits',
    stepSuccessMessage: 'Successfully saved Job traits',
  });
  stepMap.set(ListingEditorFlowStepId.PUBLISH, {
    title: 'Publish',
    stepName: ListingEditorFlowStepId.PUBLISH,
    stepSuccessMessage: 'Successfully published Job',
  });
  stepMap.set(ListingEditorFlowStepId.SHARE, {
    title: 'Invite candidates',
    stepName: ListingEditorFlowStepId.SHARE,
    navStepTitle: 'Share',
  });
  return stepMap;
};

export const getCleanedStepName = (step: string): ListingEditorFlowStepId => {
  if (step.includes('teq-interview')) {
    return ListingEditorFlowStepId.TEQ_INTERVIEW;
  }
  return step as ListingEditorFlowStepId;
};

export const getPreviousStepInOrder = (step: ListingEditorFlowStepId): ListingEditorFlowStep => {
  const stepMap = getEditorStepMap();
  const stepName = getCleanedStepName(step);
  const stepIndex = Array.from(stepMap.keys()).indexOf(stepName);
  const prevStep = stepMap.get(Array.from(stepMap.keys())[stepIndex - 1]);
  if (!prevStep) {
    throw new Error('no back step found');
  }

  return prevStep;
};

export const getNextStepInOrder = (step: ListingEditorFlowStepId): ListingEditorFlowStep => {
  const stepMap = getEditorStepMap();
  const stepIndex = Array.from(stepMap.keys()).indexOf(step);
  const nextStep = stepMap.get(Array.from(stepMap.keys())[stepIndex + 1]);
  if (!nextStep) {
    throw new Error('no next step found');
  }

  return nextStep;
};

export const currentStep_draft: ListingEditorFlowStep = getEditorStepMap().get(ListingEditorFlowStepId.OVERVIEW)!;

export const currentStep_active: ListingEditorFlowStep = {
  title: 'Publish',
  stepName: ListingEditorFlowStepId.PUBLISH,
};
