import { Box, alpha } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import Logo from 'src/components/logo';
import { NavSectionHorizontal } from 'src/components/nav-section';
import { useBoolean, useResponsive } from 'src/hooks';
import { useNavConfig } from 'src/routes/navigation/useNavConfig';
import { bgBlur } from 'src/utils/cssStyles';

import { HEADER } from '../config-layout';

import { AccountPopover, HeaderShadow, SettingsButton } from './_common';
import SupportButton from './_common/support-button';
import NavVertical from './nav-vertical';

export default function Header() {
  const theme = useTheme();

  const isNavHorizontal = true;

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavConfig();

  const nav = useBoolean();

  const renderContent = (
    <>
      {/* {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && <Logo sx={{ ml: 1 }} />}

      {lgUp && isNavHorizontal && (
        <Box>
          <NavSectionHorizontal
            data={navData.data}
            config={navData.config}
          />
        </Box>
      )}
      {!lgUp && isNavHorizontal && (
        <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />
      )} */}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >

        {process.env.REACT_APP_ENV === 'dev' && (
          <SettingsButton />
        )}

        <SupportButton />

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: alpha('#ffffff', 0.8),
            height: HEADER.H_DESKTOP_OFFSET,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
      {isNavHorizontal && (
        <HeaderShadow />
      )}
    </AppBar>
  );
}
