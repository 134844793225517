import { ApplicationAssessmentResponse } from '@piccadilly-cloud/connect-platform-services';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Tooltip } from '@mui/material';

import { useApplicationFlowContext } from 'src/contexts';
import { TrackingEvent, useBoolean, useEventTracker } from 'src/hooks';

import DialogConfirmAnswerChange from './dialog-confirm-answer-change';

export default function AnswerControlBox(props: {
  loading: boolean;
  currentAnswer?: ApplicationAssessmentResponse;
  onClose: VoidFunction;
  onClick: VoidFunction;
  saveButtonDisabled: boolean;
  isActive: boolean;
  isMinWordCountMet?: boolean;
}) {
  const eventTracker = useEventTracker();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { loading, currentAnswer, onClose, onClick, saveButtonDisabled, isActive, isMinWordCountMet } = props;

  const changeDialogConfirm = useBoolean();
  const { isPreview } = useApplicationFlowContext();
  const handleBack = () => isPreview ? onClose() : changeDialogConfirm.onTrue();

  const backButton = (
    <Button
      variant="outlined"
      color="primary"
      onClick={handleBack}
    >
      Back
    </Button>
  );

  const saveButton = (
    <Tooltip title={isMinWordCountMet ? '' : 'A minimum of 8 words are required'}>
      <div>
        <LoadingButton
          color="primary"
          variant="contained"
          disabled={saveButtonDisabled || !isMinWordCountMet}
          loading={loading}
          onClick={() => {
            onClick();
          }}
        >
          Next
        </LoadingButton>
      </div>
    </Tooltip>
  );

  const control = () => (
    <Box display="flex" flexDirection="row" justifyContent="space-between" columnGap={1}>
      {backButton}
      {saveButton}
    </Box>
  );

  return (
    <>
      <Box sx={{ pt: 2, pb: 2, px: 0 }}>
        <Box display="flex" justifyContent="flex-end" flexDirection="row" columnGap={1}>
          {control()}
        </Box>
      </Box>
      <DialogConfirmAnswerChange
        open={changeDialogConfirm.value && !isPreview}
        onClose={() => {
          changeDialogConfirm.onFalse();
        }}
        onConfirm={() => {
          eventTracker.trackEvent(TrackingEvent.ASSESSMENT_QUESTION_CANCEL_BTN, {
            applicationId: currentAnswer?.applicationId,
            answerId: currentAnswer?.id,
          });
          changeDialogConfirm.onFalse();
          onClose();
        }}
      />
    </>
  );
}
