import { Box, Button, Typography } from '@mui/material';

import Iconify from 'src/components/iconify';
import textUtil from 'src/utils/text-util';

export default function AudioTrack(props: {
  audio: string;
  counter: number;
  minLengthSeconds: number;
  onDelete: VoidFunction;
}) {
  const { audio, counter, minLengthSeconds, onDelete } = props;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%', py: 1 }}>
        <Box sx={{ width: '100%' }}>
          <audio
            src={audio}
            controls
            style={{
              display: 'block',
              width: '100%',
              borderRadius: 3,
            }}
          >
            <track kind="captions" />
          </audio>
        </Box>
        <Box sx={{ pl: 1 }}>
          <Button
            color="primary"
            variant="text"
            onClick={onDelete}
            startIcon={<Iconify icon="fa6-regular:trash-can" />}
          >
            Delete
          </Button>
        </Box>
      </Box>
      {counter < minLengthSeconds && (
        <Box sx={{ width: '100%' }}>
          <Typography variant="caption" color="error">
            Recording must be a minimum of
            {' '}
            {textUtil.numToStr(minLengthSeconds / 60)}
            {' '}
            minutes in length
          </Typography>
        </Box>
      )}
    </Box>
  );
}
