import { Box, Typography } from '@mui/material';

import { useApplicationFlowContext } from 'src/contexts/applicationFlowContext';

import ControlRow from '../control-row';

export interface TabGettingStartedProps {
  loading: boolean;
  onNext: () => void;
}

export default function TabGettingStarted(props: TabGettingStartedProps) {
  const { loading, onNext } = props;
  const applicationFlowContext = useApplicationFlowContext();

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        rowGap={1}
        sx={{
          pt: applicationFlowContext.jobProfile?.availability.fields.length !== 0 ? 4 : 3,
        }}
      >
        <Typography variant="h4">
          Begin your assessment
        </Typography>
        <Typography sx={{ pt: 1 }}>
          To ensure employers get to know you better, we‘ll first ask you some
          job-related questions, then pose a few situations to see how you’d handle them.
        </Typography>
        <Typography sx={{ pt: 1 }}>
          Every question is required unless marked optional.
        </Typography>
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">
            Guidelines
          </Typography>
          <Box sx={{ pl: 3 }}>
            <ul>
              <li>
                <Typography>
                  Ensure you have a stable internet connection and use a common browser like Chrome or Safari
                </Typography>
              </li>
              <li>
                <Typography>
                  Be in a quiet room with minimal distractions
                </Typography>
              </li>
              <li>
                <Typography>
                  Complete the assessment in a timely manner to be considered for the position
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
      <ControlRow
        onNext={onNext}
        onNextLabel="Start"
        loading={loading}
        isEditing={applicationFlowContext.isEditing}
      />
    </>
  );
}
