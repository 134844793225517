import services, { OnboardingDataResponse } from '@piccadilly-cloud/connect-platform-services';

import { OnboardingFlowContextDispatch, OnboardingFlowDispatch } from './model';

export const onboardingFlowDispatch: OnboardingFlowContextDispatch = (dispatch): OnboardingFlowDispatch => ({
  'onboardingFlow/initState': async ({
    data,
    steps,
    isAvailable,
    allStepsComplete,
    completeCtaEnabled,
    welcomeDialog,
  }) => {
    dispatch({
      type: 'onboardingFlow/INIT_ONBOARDING',
      payload: {
        data,
        steps,
        isAvailable,
        allStepsComplete,
        completeCtaEnabled,
        welcomeDialog,
      },
    });
  },
  'onboardingFlow/setStepComplete': async ({
    token,
    event,
    data,
    steps,
  }) => {
    if (data) {
      const { completedActions } = data.postRegistration;
      if (
        completedActions
        && completedActions.includes(event)
      ) {
        return Promise.resolve();
      }

      const nextCompletedActions = [...data.postRegistration.completedActions, event];
      const nextSteps = steps
        .map((s) => ({
          ...s,
          isComplete: s.event === event ? true : s.isComplete,
        }))
        .map((step, index, updatedSteps) => ({
          ...step,
          disabled: (index !== 0 && !updatedSteps[index - 1].isComplete) || step.isComplete,
        }));

      const allStepsComplete = nextSteps.every((s) => s.isComplete);
      const nextData: OnboardingDataResponse = {
        ...data,
        postRegistration: {
          ...data.postRegistration,
          completedActions: nextCompletedActions,
        },
      };
      return services.edge.app.onboarding.update(nextData)({ token })
        .then(() => {
          dispatch({
            type: 'onboardingFlow/STEP_COMPLETED',
            payload: {
              data: nextData,
              steps: nextSteps,
              allStepsComplete,
            },
          });
        }).catch((err) => {
          console.error('failed to record onboarding event', err);
        });
    }
    return Promise.resolve();
  },
  'onboardingFlow/toggleOpen': async ({ isOpen }) => {
    dispatch({
      type: 'onboardingFlow/TOGGLE_OPEN',
      payload: {
        isOpen,
      },
    });
  },
  'onboardingFlow/onboardingComplete': async ({ token, data }) => {
    const nextData: OnboardingDataResponse = {
      ...data,
      postRegistration: {
        ...data.postRegistration,
        isComplete: true,
      },
    };
    return services.edge.app.onboarding.update(nextData)({ token })
      .then(() => {
        dispatch({
          type: 'onboardingFlow/ONBOARDING_COMPLETED',
          payload: { isAvailable: false },
        });
      });
  },
});
