import services, { DynamicVendor } from '@piccadilly-cloud/connect-platform-services';

import { Typography } from '@mui/material';

import { useSessionContext } from 'src/contexts';
import { useLoading, useToast } from 'src/hooks';

import CustomDialog from './custom-dialog';

export interface ChildVendorPasswordResetDialogEmits {
  close?: () => void;
}

export interface ChildVendorPasswordResetDialogProps {
  childVendor: DynamicVendor;
  open: boolean;
  emit?: ChildVendorPasswordResetDialogEmits;
}

export default function ChildVendorPasswordResetDialog(
  props: ChildVendorPasswordResetDialogProps,
) {
  const { childVendor, open, emit } = props;
  const session = useSessionContext();
  const toast = useToast();
  const loading = useLoading();

  const title = `Reset password for admin at ${childVendor.companyName}`;
  const contactName = `${childVendor.primaryContact.firstName} ${childVendor.primaryContact.lastName}`;

  const onSendPasswordResetLink = async () => {
    try {
      loading.start();
      await services.edge.app.userAccount.requestPasswordReset(
        childVendor.primaryContact.email,
        true,
      )({ token: session.token });
      toast.success('Successfully sent password reset instructions.');
    } catch (error) {
      toast.error('Something went wrong, please try again.');
    } finally {
      loading.stop();
      emit?.close?.();
    }
  };

  return (
    <CustomDialog
      title={title}
      open={open}
      confirmText="Send"
      hideCancel
      loading={loading.isLoading}
      emit={{
        close: emit?.close,
        confirm: onSendPasswordResetLink,
      }}
    >
      <Typography variant="body2">
        {`A link will be sent to ${contactName} to reset their password.`}
      </Typography>
    </CustomDialog>
  );
}
