import {
  ApplicationAssessmentResponse,
  ApplicationAssessmentResponseData,
  AssessmentQuestion,
  AssessmentResponseType,
} from '@piccadilly-cloud/connect-platform-services';

import { generateId } from 'src/utils';

export interface CurrentQuestion extends AssessmentQuestion {
  main: string;
  lighter: string;
  currentAnswer?: ApplicationAssessmentResponse | undefined;
}

const getInitialAnswerType = (currentQuestion: CurrentQuestion, responseType: AssessmentResponseType): string => {
  if (!currentQuestion.currentAnswer) {
    if (responseType === AssessmentResponseType.AUDIO) {
      return 'record';
    }
    return 'text';
  }
  if (
    currentQuestion.currentAnswer.type === AssessmentResponseType.TEXT
    || currentQuestion.currentAnswer.type === AssessmentResponseType.TRANSCRIBED_TEXT
  ) {
    return 'text';
  }
  if (currentQuestion.currentAnswer) {
    return 'uploaded';
  }
  return 'upload';
};

const onSaveTextInput = (
  currentQuestion: CurrentQuestion,
  textInput: string,
  responseType: AssessmentResponseType,
): ApplicationAssessmentResponse => currentQuestion.currentAnswer
    ? {
      ...currentQuestion.currentAnswer,
      rawText: textInput,
      type: responseType,
    } : {
      id: generateId(),
      jobProfileQuestionId: currentQuestion.id,
      type: responseType,
      processed: true,
      rawText: textInput,
      vendorId: '',
      accountId: '',
      applicationId: '',
      createdAt: new Date(),
      updatedAt: new Date(),
      deleted: false,
      recordedInBrowser: false,
      documentId: '',
      documentPath: '',
      sampleRateHertz: 0,
    };

const onSaveUploadFile = (
  currentQuestion: CurrentQuestion,
  data: ApplicationAssessmentResponseData,
): ApplicationAssessmentResponse => ({
  id: generateId(),
  jobProfileQuestionId: currentQuestion.id,
  type: AssessmentResponseType.AUDIO,
  processed: false,
  rawText: '',
  vendorId: '',
  accountId: '',
  applicationId: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  deleted: false,
  recordedInBrowser: data.recordedInBrowser,
  documentId: data.documentId,
  documentPath: data.documentPath,
  encoding: data.encoding,
  language: data.language,
  sampleRateHertz: data.sampleRateHertz,
});

const assessmentUtil = {
  getInitialAnswerType,
  onSaveTextInput,
  onSaveUploadFile,
};

export default assessmentUtil;
