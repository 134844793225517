import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';

import Iconify from 'src/components/iconify';
import Image from 'src/components/image/image';
import { useApplicationFlowContext } from 'src/contexts';

const renderThingsYouNeedToKnow = () => {
  const thingsYouNeedToKnow = [
    'There are no right or wrong answers! What matters is understanding your approach to the job.',
    'All questions must be answered for your assessment to be submitted.',
    'For best results, fill the suggested answer length for each question.',
  ];
  return thingsYouNeedToKnow.map((thingToKnow, index) => (
    <Box
      key={index}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <Iconify icon="radix-icons:dot-filled" />
      <Typography variant="body1">
        {thingToKnow}
      </Typography>
    </Box>
  ));
};

export interface TabAssessmentProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabAssessment(props: TabAssessmentProps) {
  const {
    loading,
    onBack,
    onNext,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();
  const theme = useTheme();

  /**
   * TODO: Temporary solution to progress the application flow
   * until we transition to step based application flow using useApplicationFlow step map
   */
  const onSubmit = () => {
    applicationFlowContext.dispatch['applicationFlow/setCandidateProfileAssessmentComplete']({
      isComplete: true,
    });
    onNext();
  };

  const controls = () => (
    <Box
      display="flex"
      columnGap={2}
    >
      {(applicationFlowContext.isEditing
        || applicationFlowContext.jobListing?.isAssessmentOnly
      ) && <Box />}
      {!applicationFlowContext.isEditing
        && !applicationFlowContext.jobListing?.isAssessmentOnly
        && (
          <Button
            color="primary"
            variant="outlined"
            onClick={onBack}
          >
            Back
          </Button>
        )}

      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={onSubmit}
      >
        Next
      </LoadingButton>

    </Box>
  );

  return (
    <Grid container rowGap={3} sx={{ py: 3 }}>
      <Grid container rowGap={2}>
        <Grid item>
          <Image
            src="/logo/teq-interview-logo.svg"
            disabledEffect
            width="185px"
          />
        </Grid>

        <Grid container rowGap={{ xs: 2 }}>
          <Grid item md={8} sm={12}>
            <Grid container rowGap={2}>
              <Typography variant="body1">
                These questions are designed for your specific position
                and help us identify your unique strengths at work.
              </Typography>

              <Box>
                <Typography variant="h4">
                  What you need to know
                </Typography>

                <Grid container rowGap={1}>
                  {renderThingsYouNeedToKnow()}
                </Grid>
              </Box>

              <Typography variant="body1">
                You should expect to spend about 15-20 minutes completing this assessment.
              </Typography>
            </Grid>
          </Grid>

          <Grid item md={4} sm={6}>
            <Image src="/assets/illustrations/question-answer-save.svg" width="100%" />
          </Grid>
        </Grid>

        <Grid
          container
          display="flex"
          flexDirection="column"
          rowGap={1}
          sx={{
            boxShadow: `0px 12px 24px -4px ${alpha('#919eab', 0.12)}, 0px 0px 2px 0px ${alpha('#919eab', 0.2)}`,
            borderRadius: 3,
            p: 3,
          }}
        >
          <Box display="flex" alignItems="center" columnGap={1}>
            <Iconify
              icon="mdi:shield-lock"
              color={theme.palette.primary.darker}
            />
            <Typography
              variant="h4"
              color={theme.palette.primary.darker}
            >
              Integrity agreement
            </Typography>
          </Box>

          <Typography variant="body2">
            By submitting your answers, you commit to honesty and integrity,
            in alignment with our culture of trust and accountability at
            {' '}{applicationFlowContext.jobListing?.companyName}.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        {controls()}
      </Grid>
    </Grid>
  );
}
