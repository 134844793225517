import { Box } from '@mui/material';

import { useMemo, useState } from 'react';

import { useApplicationFlowContext } from 'src/contexts';
import { useLoading, useResponsive, useToast } from 'src/hooks';

import ViewBox from './partials/view-box';
import { ApplicationFlowTabId, getCleanedTabName, getQuestionIndex, useApplicationFlow } from './pcdl-wiz/model';
import SurveyFlow from './survey/survey-flow';
import TabAssessment from './tabs/tab-assessment';
import TabAssessmentQuestion from './tabs/tab-assessment-question';
import TabAvailability from './tabs/tab-availability';
import TabCredentials from './tabs/tab-credentials';
import TabEducation from './tabs/tab-education';
import TabEligibility from './tabs/tab-eligibility';
import TabExperience from './tabs/tab-experience';
import TabGettingStarted from './tabs/tab-getting-started';
import TabLocation from './tabs/tab-location';
import TabReview from './tabs/tab-review';
import ApplicationSubmitted from './tabs/tab-submitted';

interface ControllerTabsProps {
  emit?: {
    close?: () => void;
  };
}

export default function ControllerTabs(props: ControllerTabsProps) {
  const { emit } = props;
  const {
    application,
    jobListing,
    jobProfile,
    isSurveyComplete,
    isEditing,
    isPreview,
    dispatch: applicationFlowDispatch,
  } = useApplicationFlowContext();

  const applicationFlow = useApplicationFlow();
  const isDesktop = useResponsive('up', 'md');
  const toast = useToast();
  const loading = useLoading();

  const [active, setActive] = useState<string>(ApplicationFlowTabId.GETTING_STARTED);

  const credentialsEnabled = (jobListing?.certificates?.length ?? 0) > 0;

  const onNext = async (
    currentTab: string,
    nextTab: string,
  ) => {
    try {
      loading.start();
      const tabName = getCleanedTabName(currentTab);
      await applicationFlow.completionActions[tabName]?.();

      if (isEditing) {
        applicationFlowDispatch['applicationFlow/setIsEditing']({
          isEditing: false,
        });
        setActive(ApplicationFlowTabId.REVIEW);
      } else if (currentTab.includes(`${ApplicationFlowTabId.ASSESSMENT_QUESTION}-`)) {
        const currentIndex = getQuestionIndex(currentTab);
        let nextIndex = 0;
        const allQuestions = jobListing?.assessmentQuestionIds ?? [];
        if (currentIndex >= (allQuestions.length - 1)) {
          setActive(ApplicationFlowTabId.REVIEW);
        } else {
          nextIndex = currentIndex + 1;
          setActive(`${ApplicationFlowTabId.ASSESSMENT_QUESTION}-${nextIndex}`);
        }
      } else {
        setActive(nextTab);
      }

      window.scrollTo(0, 0);
    } catch (error) {
      console.error(error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      loading.stop();
    }
  };

  const onSubmit = async () => {
    try {
      loading.start();
      await applicationFlow.completionActions[ApplicationFlowTabId.REVIEW]?.();
      applicationFlowDispatch['applicationFlow/setApplicationSubmitted']({
        isSubmitted: true,
      });
    } catch (error) {
      console.error(error);
    } finally {
      loading.stop();
    }
  };

  const onBack = (nextTab: string) => {
    window.scrollTo(0, 0);
    if (nextTab === ApplicationFlowTabId.REVIEW) {
      setActive(`${ApplicationFlowTabId.ASSESSMENT_QUESTION}-${(jobListing?.assessmentQuestionIds.length ?? 0) - 1}`);
    } else if (nextTab.includes(ApplicationFlowTabId.ASSESSMENT_QUESTION)) {
      const currentIndex = getQuestionIndex(nextTab);
      let nextIndex = 0;
      if (currentIndex > 0) {
        nextIndex = currentIndex - 1;
        setActive(`${ApplicationFlowTabId.ASSESSMENT_QUESTION}-${nextIndex}`);
      } else {
        setActive(ApplicationFlowTabId.ASSESSMENT);
      }
    } else {
      setActive(nextTab);
    }
  };

  const tabList = useMemo(() => {
    const numQuestions = jobListing?.assessmentQuestionIds.length || 1;

    const numQuestionsAnswered = application?.profile.assessment.responseAnswers.length ?? 0;
    const assessmentPartialComplete = ((numQuestionsAnswered || 0) / numQuestions) * 100;

    if (jobListing && jobListing.isAssessmentOnly) {
      return [
        {
          id: ApplicationFlowTabId.ASSESSMENT,
          name: 'Assessment',
          isComplete: !!application?.profile.assessment.isComplete,
          partialCompletePercent: assessmentPartialComplete,
        },
        {
          id: ApplicationFlowTabId.REVIEW,
          name: 'Review and submit',
          isComplete: false,
        },
      ];
    }

    const base: {
      id: ApplicationFlowTabId | string;
      name: string;
      isComplete: boolean;
    }[] = [
        {
          id: ApplicationFlowTabId.GETTING_STARTED,
          name: 'Getting Started',
          isComplete: !!application?.profile.location.isComplete,
        },
        {
          id: ApplicationFlowTabId.LOCATION,
          name: 'Location',
          isComplete: !!application?.profile.location.isComplete,
        },
        {
          id: ApplicationFlowTabId.EXPERIENCE,
          name: 'Experience',
          isComplete: !!application?.profile.experience.isComplete,
        },
        {
          id: ApplicationFlowTabId.AVAILABILITY,
          name: 'Availability',
          isComplete: !!application?.profile.availability.isComplete,
        },
        {
          id: ApplicationFlowTabId.ELIGIBILITY,
          name: 'Eligibility',
          isComplete: !!application?.profile.eligibility.isComplete,
        },
        {
          id: ApplicationFlowTabId.EDUCATION,
          name: 'Education',
          isComplete: !!application?.profile.education.isComplete,
        },
        ...(credentialsEnabled ? [
          {
            id: ApplicationFlowTabId.CREDENTIALS,
            name: 'Credentials',
            isComplete: !!application?.profile.credentials.isComplete,
          },
        ] : []),
        {
          id: ApplicationFlowTabId.ASSESSMENT,
          name: 'Assessment',
          isComplete: !!application?.profile.assessment.isComplete,
        },
      ];

    (jobListing?.assessmentQuestionIds ?? []).forEach((i, index) => {
      const answered = !!application?.profile.assessment.responseAnswers.find(
        (j) => j.jobProfileQuestionId === i,
      );
      base.push(
        { id: `assessment-question-${index}`, name: `Assessment Question ${index}`, isComplete: answered },
      );
    });

    base.push(
      { id: ApplicationFlowTabId.REVIEW, name: 'Review and submit', isComplete: false },
    );

    return base;
  }, [
    application?.profile.assessment.isComplete,
    application?.profile.assessment.responseAnswers,
    application?.profile.availability.isComplete,
    application?.profile.credentials.isComplete,
    application?.profile.education.isComplete,
    application?.profile.eligibility.isComplete,
    application?.profile.experience.isComplete,
    application?.profile.location.isComplete,
    credentialsEnabled,
    jobListing,
  ]);

  const progress = useMemo(() => {
    const completedSections = [
      application?.profile.experience.isComplete,
      application?.profile.location.isComplete,
      application?.profile.availability.isComplete,
      application?.profile.eligibility.isComplete,
      application?.profile.education.isComplete,
      application?.profile.credentials.isComplete,
      application?.profile.assessment.isComplete,
      application?.profile.assessment.isComplete,
    ].filter(Boolean).length;

    if (isDesktop) {
      const totalAssessmentSections = tabList.length - 1;
      const answersInterviewQuestions = application?.profile.assessment.responseAnswers.length || 0;
      const totalCompleted = completedSections + answersInterviewQuestions;

      return totalCompleted / totalAssessmentSections;
    }

    const totalLength = tabList.length + (jobListing?.assessmentQuestionIds.length ?? 0);
    const completedTabs = tabList.filter((i) => i.isComplete).length;

    return (completedTabs + completedSections) / totalLength;
  }, [
    isDesktop,
    tabList,
    application,
    jobListing?.assessmentQuestionIds.length,
  ]);

  if (!application) {
    return (<>Something went wrong! Please refresh and try again.</>);
  }

  if (application.submitted) {
    return (
      <ApplicationSubmitted
        application={application}
        jobListing={jobListing}
      />
    );
  }

  const body = (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        pb={isDesktop ? 4 : 12}
        style={{
          minWidth: '350px',
          width: '100%',
        }}
      >
        <ViewBox
          application={application}
          jobProfile={jobProfile}
          jobListing={jobListing}
          progress={progress}
          active={active}
          setActive={setActive}
          tabList={tabList}
          hideDevMode={!isDesktop}
          hideCompanyName={active !== ApplicationFlowTabId.GETTING_STARTED && !isDesktop}
          hideDescription={active !== ApplicationFlowTabId.GETTING_STARTED}
          hideProgress={active === ApplicationFlowTabId.GETTING_STARTED}
        >
          <Box display="flex" flexDirection="column">
            <Box>
              {active === ApplicationFlowTabId.GETTING_STARTED && (
                <TabGettingStarted
                  loading={loading.isLoading}
                  onNext={() => onNext(ApplicationFlowTabId.GETTING_STARTED, ApplicationFlowTabId.LOCATION)}
                />
              )}
              {active === ApplicationFlowTabId.LOCATION && (
                <TabLocation
                  loading={loading.isLoading}
                  onNext={() => onNext(ApplicationFlowTabId.LOCATION, ApplicationFlowTabId.EXPERIENCE)}
                  onBack={() => onBack(ApplicationFlowTabId.GETTING_STARTED)}
                />
              )}
              {active === ApplicationFlowTabId.EXPERIENCE && (
                <TabExperience
                  loading={loading.isLoading}
                  onNext={() => onNext(ApplicationFlowTabId.EXPERIENCE, ApplicationFlowTabId.AVAILABILITY)}
                  onBack={() => onBack(ApplicationFlowTabId.LOCATION)}
                />
              )}
              {active === ApplicationFlowTabId.AVAILABILITY && (
                <TabAvailability
                  loading={loading.isLoading}
                  onNext={() => onNext(ApplicationFlowTabId.AVAILABILITY, ApplicationFlowTabId.ELIGIBILITY)}
                  onBack={() => onBack(ApplicationFlowTabId.EXPERIENCE)}
                />
              )}
              {active === ApplicationFlowTabId.ELIGIBILITY && (
                <TabEligibility
                  loading={loading.isLoading}
                  onNext={() => onNext(ApplicationFlowTabId.ELIGIBILITY, ApplicationFlowTabId.EDUCATION)}
                  onBack={() => onBack(ApplicationFlowTabId.AVAILABILITY)}
                />
              )}
              {active === ApplicationFlowTabId.EDUCATION && (
                <TabEducation
                  loading={loading.isLoading}
                  onNext={() => {
                    const nextTab = credentialsEnabled
                      ? ApplicationFlowTabId.CREDENTIALS
                      : ApplicationFlowTabId.ASSESSMENT;

                    onNext(ApplicationFlowTabId.EDUCATION, nextTab);
                  }}
                  onBack={() => onBack(ApplicationFlowTabId.ELIGIBILITY)}
                />
              )}
              {active === ApplicationFlowTabId.CREDENTIALS && (
                <TabCredentials
                  loading={loading.isLoading}
                  onNext={() => onNext(ApplicationFlowTabId.CREDENTIALS, ApplicationFlowTabId.ASSESSMENT)}
                  onBack={() => onBack(ApplicationFlowTabId.EDUCATION)}
                />
              )}
              {active === ApplicationFlowTabId.ASSESSMENT && (
                <TabAssessment
                  loading={loading.isLoading}
                  onNext={() => onNext(
                    ApplicationFlowTabId.ASSESSMENT,
                    `${ApplicationFlowTabId.ASSESSMENT_QUESTION}-0`,
                  )}
                  onBack={() => {
                    const prevTab = credentialsEnabled
                      ? ApplicationFlowTabId.CREDENTIALS
                      : ApplicationFlowTabId.EDUCATION;

                    onBack(prevTab);
                  }}
                />
              )}
              {active.includes(`${ApplicationFlowTabId.ASSESSMENT_QUESTION}-`) && (
                <TabAssessmentQuestion
                  index={getQuestionIndex(active)}
                  loading={loading.isLoading}
                  onNext={() => onNext(active, ApplicationFlowTabId.REVIEW)}
                  onBack={() => onBack(active)}
                />
              )}
              {active === ApplicationFlowTabId.REVIEW && (
                <TabReview
                  loading={loading.isLoading}
                  onBack={() => onBack(ApplicationFlowTabId.REVIEW)}
                  onNext={isPreview ? emit?.close || (() => { }) : onSubmit}
                />
              )}
            </Box>
          </Box>
        </ViewBox>
      </Box>
    </Box>
  );

  if (!isDesktop) {
    if (!isSurveyComplete && !isPreview) {
      return <SurveyFlow />;
    }

    return body;
  }

  return (
    <>
      {body}
      {!isSurveyComplete && !isPreview && <SurveyFlow />}
    </>
  );
}
