/* eslint-disable max-len */
import { TraitMap, UniformProfileAssessmentTraits } from '@piccadilly-cloud/connect-platform-services';

import { ListingTeqTraitsForm } from './form/form-types';

// JOB LISTING COPY
const experienceOpts = [
  { label: 'Little to no experience', description: 'may have education but no working experience', value: 1 },
  { label: 'Basic experience', description: 'up to two years of experience on the job', value: 5 },
  { label: 'Intermediate experience', description: 'between two and four years', value: 7 },
  { label: 'Advanced experience', description: 'between four and eight years', value: 9 },
  { label: 'Expert', description: 'greater than eight years', value: 10 },
];

const educationOpts = [
  { label: 'Less than a high school diploma', description: '', value: 1 },
  { label: 'High school diploma', description: 'or the equivalent (for example, GED)', value: 2 },
  { label: 'Some college courses', description: '', value: 4 },
  { label: 'Associate\'s degree (or other 2-year degree)', description: '', value: 5 },
  { label: 'Bachelor\'s degree', description: '', value: 6 },
  { label: 'Master\'s degree', description: '', value: 8 },
  { label: 'Doctoral degree', description: '', value: 11 },
];

const locationTypeOpts = [
  { label: 'On site or hybrid', value: 'ONSITE' },
  { label: 'Remote', value: 'REMOTE' },
];

const positionStatusOpts = [
  { label: 'Full time', value: 'FULL_TIME' },
  { label: 'Part time', value: 'PART_TIME' },
];

const positionDurationOpts = [
  { label: 'Permanent', value: 'PERMANENT' },
  { label: 'Contract', value: 'TEMPORARY' },
];

const criticalScaleOpts = [
  { label: '1 - Not important', value: 1 },
  { label: '2 - Minimally important', value: 2 },
  { label: '3 - Moderately important', value: 3 },
  { label: '4 - Very important', value: 4 },
  { label: '5 - Critical', value: 5 },
];

const additionalEducationOpts = [
  { label: 'Technical college', value: 'technicalCollege' },
  { label: 'Trade school', value: 'tradeSchool' },
  { label: 'Apprenticeship', value: 'apprenticeship' },
  { label: 'Online vocational program', value: 'onlineVocational' },
  { label: 'Other on-the-job formal training', value: 'other' },
];

const educationProgressOpts = [
  { label: 'Started', value: 1 },
  { label: 'Completed', value: 2 },
];

const getByValue = (v: { value: any; label: string }[], key: any, defaultValue?: string) => {
  // eslint-disable-next-line eqeqeq
  const x = v.find((i) => i.value == key);
  if (x) {
    return x.label;
  }
  if (defaultValue) {
    return defaultValue;
  }
  return 'Incomplete';
};

const traitMapToListingTeqTraitsForm = (existing: ListingTeqTraitsForm, tm: TraitMap[]): ListingTeqTraitsForm => {
  const updatedFormData = {
    ...existing,
  };
  tm.forEach((trait) => {
    switch (trait.id) {
      case 'adaptingAndCoping':
        updatedFormData.adaptingAndCoping = trait.value;
        break;
      case 'analyzingAndInterpreting':
        updatedFormData.analyzingAndInterpreting = trait.value;
        break;
      case 'creatingAndConceptualizing':
        updatedFormData.creatingAndConceptualizing = trait.value;
        break;
      case 'enterprisingAndPerforming':
        updatedFormData.enterprisingAndPerforming = trait.value;
        break;
      case 'interactingAndPresenting':
        updatedFormData.interactingAndPresenting = trait.value;
        break;
      case 'leadingAndDeciding':
        updatedFormData.leadingAndDeciding = trait.value;
        break;
      case 'organizingAndExecuting':
        updatedFormData.organizingAndExecuting = trait.value;
        break;
      case 'supportingAndCooperating':
        updatedFormData.supportingAndCooperating = trait.value;
        break;
      case 'conscientiousness':
        updatedFormData.conscientiousness = trait.value;
        break;
      case 'agreeableness':
        updatedFormData.agreeableness = trait.value;
        break;
      case 'opennessToExperience':
        updatedFormData.opennessToExperience = trait.value;
        break;
      case 'enthusiasm':
        updatedFormData.enthusiasm = trait.value;
        break;
      case 'grit':
        updatedFormData.grit = trait.value;
        break;
      case 'proactiveness':
        updatedFormData.proactiveness = trait.value;
        break;
      default:
    }
  });
  updatedFormData.counter = tm.filter((t) => t.value === 5).length;
  return updatedFormData;
};

const traitMap = (f: ListingTeqTraitsForm, p: UniformProfileAssessmentTraits, reset?: boolean): TraitMap[] => {
  let v: ListingTeqTraitsForm | UniformProfileAssessmentTraits = f;
  if (reset) {
    v = p;
  }
  const traits: TraitMap[] = [
    {
      id: 'adaptingAndCoping',
      value: v.adaptingAndCoping || 1,
      title: 'Adapting and Coping',
      description: 'Responds effectively to change and skillfully manages pressure and stressors. Realizes and accepts the need for change. Willing to try new approaches and adapt to new ways of working. Stays positive and views changing circumstances as an opportunity for self-development and growth.',
      shortDescription: 'The skill to respond effectively to change and to manage pressure and stressors.',
      icon: 'material-symbols:change-circle',
    },
    {
      id: 'analyzingAndInterpreting',
      value: v.analyzingAndInterpreting || 1,
      title: 'Analyzing and Interpreting',
      description: 'Identifies problems, analyzes them effectively, and generates strategic solutions. Observes, questions, and assesses situations analytically. Seeks knowledge and conducts research in order to understand the circumstances behind, and core elements of the situation, and to provide appropriate solutions.',
      shortDescription: 'The skill to identify problems, analyze them effectively, and generate strategic solutions.',
      icon: 'mdi:magnify-scan',
    },
    {
      id: 'creatingAndConceptualizing',
      value: v.creatingAndConceptualizing || 1,
      title: 'Creating and Conceptualizing',
      description: 'Seeks new ideas and experiences, and approaches problems and opportunities with creativity and innovation. Explores and develops new concepts by challenging current situations or processes. Seeks opportunities and formulates approaches for improvement. High scorers tend to be especially open to new ideas and experiences and particularly proficient at approaching and solving problems creatively.',
      shortDescription: 'The skill to seek new ideas and experiences and approach problems and opportunities with creativity and innovation.',
      icon: 'mdi:head-idea',
    },
    {
      id: 'enterprisingAndPerforming',
      value: v.enterprisingAndPerforming || 1,
      title: 'Enterprising and Performing',
      description: "Focuses energy toward achieving work objectives and creating business success. Motivated to win and advance the business. Completes responsibilities with confidence and a can-do attitude. High performers tend to be especially proficient at focusing one's energy toward achieving work goals and creating business success.",
      shortDescription: 'The skill to focus one’s energy toward achieving work objectives and creating business success.',
      icon: 'mdi:report-finance',
    },
    {
      id: 'interactingAndPresenting',
      value: v.interactingAndPresenting || 1,
      title: 'Interacting and Presenting',
      description: 'Socially interacts, communicates, and persuades others effectively across all levels of an organization. Socially skilled and comfortable in public settings. Presents skillfully and clearly. Influences their audience, while not shying away from challenging others. Networks readily and exudes a professional image. High  performers tend to be especially proficient at interacting and communicating socially, and effective in persuading others across organizational levels.',
      shortDescription: 'The skill to socially interact, communicate, and persuade others effectively across all levels of an organization.',
      icon: 'mdi:presentation',
    },
    {
      id: 'leadingAndDeciding',
      value: v.leadingAndDeciding || 1,
      title: 'Leading and Deciding',
      description: 'Influences others, facilitates and drives team and individual efforts toward goals and objectives. Takes responsibility and coordinates progress toward work completion.Takes control and makes decisions on behalf of a team. Delegates tasks, and gains trust from others by fostering an environment of efficient collaboration. High performers tend to be especially proficient at influencing others, as well as facilitating and driving team and individual efforts toward goals and objectives.',
      shortDescription: 'The skill to influence others, facilitate and drive team and individual efforts toward goals and objectives.',
      icon: 'mdi:person-tie',
    },
    {
      id: 'organizingAndExecuting',
      value: v.organizingAndExecuting || 1,
      title: 'Organizing and Executing',
      description: 'Plans and organizes work, and then starts, monitors, and delivers the work to meet performance standards. Systematically plans and organizes work, monitors progress towards set goals and follows through on commitments. Focuses on the future and on delivering results and meeting performance standards. High performers tend to be especially proficient at planning, organizing, starting and monitoring work, as well as working to achieve performance standards.',
      shortDescription: 'The skill to plan and organize work, and then to start, monitor, and deliver the work to meet performance standards.',
      icon: 'mdi:strategy',
    },
    {
      id: 'supportingAndCooperating',
      value: v.supportingAndCooperating || 1,
      title: 'Supporting and Cooperating',
      description: 'Shows respect, supports others, and works effectively with teams, clients, and staff. Cares for and helps others by building respectful, supportive relationships. Is people and team-oriented, keeping others informed while seeking to fully understand. High performers tend to be especially respectful and supportive of others and particularly proficient at working effectively with teams, clients, and staff.',
      shortDescription: 'The skill to show respect, support others, and work effectively with teams, clients, and staff.',
      icon: 'mdi:hand-extended',
    },
    {
      id: 'conscientiousness',
      value: v.conscientiousness || 1,
      title: 'Conscientiousness',
      description: 'The tendency to be achievement oriented, as well as dependable, and organized. May demonstrate a proclivity for detail-orientation and being planful, as well as displaying a relentless pursuit of goals. High scorers tend to be especially achievement oriented, dependable, and organized.',
      shortDescription: 'The tendency to be achievement oriented, dependable, and organized.',
      icon: 'material-symbols:list-alt',
    },
    {
      id: 'agreeableness',
      value: v.agreeableness || 1,
      title: 'Agreeableness',
      description: 'The tendency to be friendly, sensitive and trusting. May come across as cooperative, tolerant and considerate of others. Likely to have a proclivity towards compliance and conforming behaviors. High scorers tend to be especially friendly, sensitive and trusting.',
      shortDescription: 'The tendency to be friendly, sensitive and trusting.',
      icon: 'material-symbols:thumb-up',
    },
    {
      id: 'opennessToExperience',
      value: v.opennessToExperience || 1,
      title: 'Openness to Experience',
      description: 'The tendency to be curious, creative, original, and open to new ideas. May come across as being broad-minded and flexible in thought, as well as adventurous, intellectual, and artistic. High scorers tend to be especially curious, creative, original, and open to new ideas. High scorers tend to be especially curious, creative, original, and open to new ideas.',
      shortDescription: 'The tendency to be curious, creative, original, and open to new ideas.',
      icon: 'mdi:head-question',
    },
    {
      id: 'enthusiasm',
      value: v.enthusiasm || 1,
      title: 'Enthusiasm',
      description: 'The expression of interest, joy and excitement toward the job opportunity. Also likely to display high levels of motivation, energy, involvement, and interest in the work itself. High scorers tend to be especially expressive of excitement and interest, usually in reference to the job opportunity.',
      shortDescription: 'Expresses excitement with high levels of energy and motivation',
      icon: 'bi:person-arms-up',
    },
    {
      id: 'grit',
      value: v.grit || 1,
      title: 'Grit',
      description: 'The motivation to persevere long-term in the face of challenging circumstances, to sustain interest and apply persistent effort over time, to recover from stress or setbacks, to make autonomous choices, and to control short-term impulses by patiently awaiting future rewards. High scorers tend to be especially persevering in both effort and interest long- term, in the face of challenging circumstances.',
      shortDescription: 'The motivation to persevere long-term in the face of challenging circumstances.',
      icon: 'fa6-solid:hand-fist',
    },
    {
      id: 'proactiveness',
      value: v.proactiveness || 1,
      title: 'Proactiveness',
      description: 'The tendency to anticipate future situations and to take initiative, including the motivation to pursue, plan, prepare, and make things happen, even without being told. Also likely to be the first to assist others in need, to challenge others, and to drive action. High scorers tend to be especially attuned to the impact of future situations and motivated to take preemptive action.',
      shortDescription: 'The tendency to anticipate future situations and the motivation to take initiative.',
      icon: 'mdi:lightbulb-on',
    },
  ].sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    } if (b.value < a.value) {
      return -1;
    }
    return 0;
  });
  return traits;
};

const traitMapNegativeExpressionOfEmotion = {
  value: 0,
  title: 'Negative Expression of Emotion',
  description: 'Tendency to overuse language reflecting negative emotions when communicating at work. May be interpreted by others as expressing negative attitudes or intense passion. Candidates who are flagged on this scale are likely to express negative emotions more readily than others, which may impact other’s reactions and reduce effectiveness in the work environment. They may struggle to use neutral or disarming language when tensions run high. Depending on the job requirements, a flagged candidate may in rare instances be an asset, such as where extreme language is the norm or some aggressiveness is necessary to perform the job.',
  shortDescription: 'Tendency to express negative attitudes at work',
  icon: 'mdi:comment-minus',
};

const listingConfig = {
  experienceOpts,
  educationOpts,
  locationTypeOpts,
  positionStatusOpts,
  positionDurationOpts,
  additionalEducationOpts,
  educationProgressOpts,

  criticalScaleOpts,

  getByValue,

  traitMapToListingTeqTraitsForm,
  traitMap,
  traitMapNegativeExpressionOfEmotion,
};

export default listingConfig;
