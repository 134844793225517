import {
  EducationLevel,
  ExperienceType,
  LocationType,
  PositionType,
} from '@piccadilly-cloud/connect-platform-services';

// CANDIDATE COPY
const experienceLevel = [
  { label: 'Little to no experience', description: 'May have education but no working experience', value: 1 },
  { label: 'Basic experience', description: 'Up to two years of experience on the job', value: 5 },
  { label: 'Intermediate experience', description: 'Between two and four years', value: 7 },
  { label: 'Advanced experience', description: 'Between four and eight years', value: 9 },
  { label: 'Expert', description: 'Greater than eight years', value: 10 },
];

const assessmentResponseType = [
  { label: 'Audio', description: 'Use your device microphone to record', value: 'AUDIO' },
  { label: 'Text', description: 'Write your answers in the space provided', value: 'TEXT' },
];

const educationLevel = [
  { label: 'Less than a high school diploma', value: 1 },
  { label: 'High school diploma or the equivalent (for example, GED)', value: 2 },
  { label: 'Some college courses', value: 4 },
  { label: 'Associate\'s degree (or other 2-year degree)', value: 5 },
  { label: 'Bachelor\'s degree', value: 6 },
  { label: 'Master\'s degree', value: 8 },
  { label: 'Doctoral degree', value: 11 },
];

const oneToFive = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
];

const oneOrFive = [
  { label: '1', value: 1 },
  { label: '5', value: 5 },
];

const getExperienceType = (experienceType: ExperienceType): string => {
  switch (experienceType) {
    case ExperienceType.NoExperience:
      return 'Little to no experience - May have education but no working experience';
    case ExperienceType.SomeExperience:
      return 'Basic experience - Up to two years of experience on the job';
    case ExperienceType.Skilled:
      return 'Intermediate experience - Between two and four years';
    case ExperienceType.VeryExperienced:
      return 'Advanced experience - Between four and eight years';
    case ExperienceType.Expert:
      return 'Expert - Greater than eight years';
    default:
      return '';
  }
};

const getEducationLevel = (eduLevel: EducationLevel): string => {
  switch (eduLevel) {
    case EducationLevel.LessThanHighschoolDiploma:
      return 'Less than a high school diploma';
    case EducationLevel.HighSchoolDiploma:
      return 'High school diploma or the equivalent (for example, GED)';
    case EducationLevel.SomeCollege:
      return 'Some college courses';
    case EducationLevel.AssociatesDegree:
      return 'Associate\'s degree (or other 2-year degree)';
    case EducationLevel.BachelorsDegree:
      return 'Bachelor\'s degree';
    case EducationLevel.MastersDegree:
      return 'Master\'s degree';
    case EducationLevel.DoctoralDegree:
      return 'Doctoral degree';
    default:
      return '';
  }
};

const getEmploymentType = (employmentType: string[]): string => {
  if (!employmentType) {
    return '';
  }
  const opts: string[] = [];
  employmentType.forEach((i) => {
    const asStr = i.split('_')
      .map((str, index) => {
        if (index === 0) {
          return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
        return str.toLowerCase();
      })
      .join(' ');
    opts.push(asStr);
  });
  return opts.join(', ');
};

const getPositionType = (positionType: PositionType[]): string => {
  if (!positionType) {
    return '';
  }
  const opts: string[] = [];
  positionType.forEach((i) => {
    const asStr = i.split('_')
      .map((str, index) => {
        if (index === 0) {
          return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }
        return str.toLowerCase();
      })
      .join(' ');
    opts.push(asStr);
  });
  return opts.join(', ');
};

const getLocationType = (locationTypes: LocationType[]): string => {
  if (!locationTypes) {
    return '';
  }
  const opts: string[] = [];
  locationTypes.forEach((i) => {
    const asStr = i.split('_')
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
      .join(' ');
    opts.push(asStr);
  });
  return opts.join(', ');
};

const getBinaryResponse = (value: number): string => {
  if (value === 5) {
    return 'Yes';
  }
  return 'No';
};

const getGradientPercentResponse = (value: number): string => {
  switch (value) {
    case 5:
      return '100%';
    case 4:
      return '75%';
    case 3:
      return '50%';
    case 2:
      return '25%';
    case 1:
      return '0%';
    default:
      return '0%';
  }
};
const getGradientResponse = (value: number): string => {
  switch (value) {
    case 5:
      return 'Always';
    case 4:
      return 'Almost Always';
    case 3:
      return 'Sometimes';
    case 2:
      return 'Occasionally';
    case 1:
      return 'Never';
    default:
      return 'Never';
  }
};

const formOptions = {
  experienceLevel,
  assessmentResponseType,
  educationLevel,
  oneToFive,
  oneOrFive,
  getExperienceType,
  getEducationLevel,
  getEmploymentType,
  getPositionType,
  getLocationType,
  getBinaryResponse,
  getGradientPercentResponse,
  getGradientResponse,
};

export default formOptions;
