import { useContext } from 'react';

import { PurchaseFlowContext } from './PurchaseFlowContext';

export const usePurchaseFlowContext = () => {
  const context = useContext(PurchaseFlowContext);
  if (!context) {
    throw new Error('usePurchaseFlowContext context must be use inside PurchaseFlowProvider');
  }

  return context;
};
