import { useSessionContext } from 'src/contexts';

import { useWorkspace } from './use-workspace';

export const usePayments = () => {
  const {
    account,
    appConfig,
  } = useSessionContext();
  const { workspace } = useWorkspace();

  const isBillingAdmin = workspace.billingAdmins?.includes(account.email);
  const isPurchaseEnabled = appConfig.behavior.recruiterBehavior.assessmentPurchasesEnabled;

  return {
    isBillingAdmin,
    isPurchaseEnabled,
  };
};
