import { Paper } from '@mui/material';

import teqScoreConfigV2 from 'src/components-piccadilly/teq-score/v2/config';

interface HeaderBannerProps {
  children: React.ReactNode;
  color?: string;
  height?: number | string;
  disableElevation?: boolean;
}

export default function HeaderBanner(props: HeaderBannerProps) {
  const { children, color, height, disableElevation } = props;
  return (
    <Paper
      elevation={disableElevation ? 0 : 3}
      sx={{
        backgroundColor: color || teqScoreConfigV2.palette[2].light,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: height || '200px',
      }}
    >
      {children}
    </Paper>
  );
}
