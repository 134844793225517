import {
  AccountRole,
  UserAccount,
  UserAccountAnalyticsInfo,
  UserAccountBehavior,
  UserAccountInvitationData,
  UserAccountNotificationOpts,
  UserAccountPersonalInfo,
  UserAccountType,
} from '@piccadilly-cloud/connect-platform-services';

import { generateId } from 'src/utils/general';

export const initUserAccountAnalyticsInfo = (): UserAccountAnalyticsInfo => ({
  source: '',
  gender: '',
  age: '',
  ethnicity: '',
  extendedQuestions: [],
});

export const initUserAccountBehavior = (): UserAccountBehavior => ({
  useGettingStarted: false,
  isGettingStartedComplete: false,
  isUserZero: false,
});

export const initUserAccountNotificationOpts = (): UserAccountNotificationOpts => ({
  emailPreferred: false,
  emailVerified: false,
  smsPreferred: false,
  smsVerified: false,
  mfaEnabled: false,
});

export const initUserAccountPersonalInfo = (): UserAccountPersonalInfo => ({
  firstName: '',
  lastName: '',
  avatarUrl: '',
  companyName: '',
  jobTitle: '',
  joinDate: new Date(),
  phoneNumber: '',
});

export const initUserAccount = (): UserAccount => ({
  id: generateId(),
  vendor: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  lastSessionAt: new Date(),
  deleted: false,
  inactive: false,
  accountRole: 'GUEST',
  email: '',
  phoneNumber: '',
  personalInfo: initUserAccountPersonalInfo(),
  analyticsInfo: initUserAccountAnalyticsInfo(),
  notificationOpts: initUserAccountNotificationOpts(),
  workspaceSettings: {
    workspaces: [],
    defaultWorkspace: '',
  },
  behaviorSettings: initUserAccountBehavior(),
  customerSettings: {
    StripeCustomer: '',
    hasActiveSubsubscription: false,
  },
});

export interface UserAccountInvitationForm {
  firstName: string;
  lastName: string;
  email: string;
}

export const initUserAccountInvitationData = (
  formInfo: UserAccountInvitationForm,
  accountRole: AccountRole,
  invitationType: UserAccountType,
  jobListingId?: string,
  jobListingName?: string,
  jobListingVanityPath?: string,
): UserAccountInvitationData => ({
  sendMethod: 'EMAIL',
  email: formInfo.email,
  phoneNumber: '',
  firstName: formInfo.firstName,
  lastName: formInfo.lastName,
  accountRole,
  sendData: {
    invitationType,
    jobListingId: jobListingId ?? '',
    jobListingName: jobListingName ?? '',
    jobListingVanityPath: jobListingVanityPath ?? '',
  },
  behaviorSettings: initUserAccountBehavior(),
});
