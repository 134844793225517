export const companyName = 'Piccadilly Software Group, LLC';
export const companyAddressLine1 = '4233 Roanoke Rd STE 200';
export const companyAddressLine2 = 'Kansas City, MO 64111';
export const companySupportEmail = 'support@teqconnect.com';

const companyInfo = {
  companyName,
  companyAddressLine1,
  companyAddressLine2,
  companySupportEmail,
};

export default companyInfo;
