import services, { ApplicantSurvey, Application, PublicListing } from '@piccadilly-cloud/connect-platform-services';

import {
  Alert,
  Box,
  CircularProgress,
  Container,
  IconButton,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';

import Iconify from 'src/components/iconify';
import { useApplicationFlowContext } from 'src/contexts';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useResponsive, useToast } from 'src/hooks';

import QuestionCard from '../partials/question-card';

interface ApplicationSubmittedProps {
  application: Application;
  jobListing?: PublicListing;
}

export default function ApplicationSubmitted(props: ApplicationSubmittedProps) {
  const { application, jobListing } = props;
  const { isPreview } = useApplicationFlowContext();
  const isDesktop = useResponsive('up', 'md');

  const session = useSessionContext();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [receivedFeedback, setReceivedFeedback] = useState(false);

  useEffect(() => {
    if (isPreview) {
      return;
    }
    (async () => {
      try {
        setLoading(true);
        const response
          = await services.edge.record.applicantSurvey.getSurvey(application.accountId)({ token: session.token });
        if (!response.accountId) {
          setReceivedFeedback(false);
        } else {
          setReceivedFeedback(true);
        }
      } catch (error) {
        console.error(error);
        setReceivedFeedback(false);
      } finally {
        setLoading(false);
      }
    })();
  }, [application.accountId, session.token, isPreview]);

  const onSubmitFeedback = (index: number) => {
    if (isPreview) {
      setReceivedFeedback(true);
      toast.success('Your feedback has been received');
      return;
    }
    setLoading(true);
    const applicantSurvey: ApplicantSurvey = {
      id: '',
      vendorId: session.appConfig.vendor,
      accountId: session.account.email,
      createdAt: new Date(),
      feedbackRating: index,
    };
    services.edge.record.applicantSurvey.create(applicantSurvey)({ token: session.token })
      .then(() => {
        setReceivedFeedback(true);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        setLoading(false);
        setReceivedFeedback(true);
        toast.success('Your feedback has been received');
      });
  };

  return (
    <Container maxWidth="md">
      <Box display="flex" flexDirection="column" rowGap={2} sx={{ pt: 3 }}>
        <Typography variant="h2">
          Thank you
        </Typography>
        <Typography variant="body2">
          Your assessment for the
          {' '}
          <b>{jobListing?.name || ''}</b>
          {' '}
          job was submitted successfully.
        </Typography>
        <QuestionCard
          title="Feedback"
        >
          <Box display="flex" flexDirection="column" rowGap={1}>
            {!receivedFeedback && (
              <Typography variant="body1">
                How satisfied are you with the TEQ Connect assessment process?
              </Typography>
            )}

            {!loading && (
              <>
                {receivedFeedback && (
                  <Box>
                    <Alert severity="success">
                      Your feedback has been received
                    </Alert>
                  </Box>
                )}
                {!receivedFeedback && (
                  <>
                    <Box display="flex" justifyContent="space-between" flexDirection="row" columnGap={1}>
                      <IconButton onClick={() => {
                        onSubmitFeedback(0);
                      }}
                      >
                        <Iconify icon="mdi:emoticon-frown-outline" width={isDesktop ? '4rem' : '2rem'} />
                      </IconButton>
                      <IconButton onClick={() => {
                        onSubmitFeedback(1);
                      }}
                      >
                        <Iconify icon="mdi:emoticon-confused-outline" width={isDesktop ? '4rem' : '2rem'} />
                      </IconButton>
                      <IconButton onClick={() => {
                        onSubmitFeedback(2);
                      }}
                      >
                        <Iconify icon="mdi:emoticon-neutral-outline" width={isDesktop ? '4rem' : '2rem'} />
                      </IconButton>
                      <IconButton onClick={() => {
                        onSubmitFeedback(3);
                      }}
                      >
                        <Iconify icon="mdi:emoticon-happy-outline" width={isDesktop ? '4rem' : '2rem'} />
                      </IconButton>
                      <IconButton onClick={() => {
                        onSubmitFeedback(4);
                      }}
                      >
                        <Iconify icon="mdi:emoticon-excited-outline" width={isDesktop ? '4rem' : '2rem'} />
                      </IconButton>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography variant="body2">
                        Very unsatisfied
                      </Typography>
                      <Typography variant="body2">
                        Very satisfied
                      </Typography>
                    </Box>
                  </>
                )}
              </>
            )}
            {loading && (
              <Box display="flex" sx={{ pt: 2 }} justifyContent="center">
                <CircularProgress />
              </Box>
            )}

          </Box>
        </QuestionCard>
      </Box>
    </Container>
  );
}
