import { Box, IconButton, Typography, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';

export default function ButtonRestart(props: {
  onRestart: VoidFunction;
}) {
  const { onRestart } = props;

  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="row" justifyContent="center">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <IconButton
          onClick={onRestart}
        >
          <Iconify icon="mdi:restore" width="32px" color={theme.palette.grey[500]} />
        </IconButton>
        <Typography variant="body2" color={theme.palette.grey[700]}>
          Restart
        </Typography>
      </Box>
    </Box>
  );
}
