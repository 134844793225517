import { Grid, useTheme } from '@mui/material';
import Box from '@mui/material/Box';

import Image from 'src/components/image';
import { useResponsive } from 'src/hooks';

import Footer from '../nonworkspace/footer';

export interface AuthClassicLayoutProps {
  title?: string;
  image?: string;
  children: React.ReactNode;
}

export default function AuthClassicLayout({ children }: AuthClassicLayoutProps) {
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <Box
        sx={{
          position: 'absolute',
          height: '100vh',
          width: '100vw',
          backgroundColor: theme.palette.primary.lighter,
          zIndex: -1,
        }}
      >
        <Box sx={{ position: 'absolute', bottom: 0, left: 0 }}>
          <Image src="/assets/background/tulips-bl-1.svg" />
        </Box>
        <Box sx={{ position: 'absolute', bottom: 0, left: 0 }}>
          <Image src="/assets/background/tulips-bl-2.svg" />
        </Box>
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Image src="/assets/background/tulips-tr-1.svg" />
        </Box>
        <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
          <Image src="/assets/background/tulips-tr-2.svg" />
        </Box>
      </Box>
      <Grid container sx={{ pt: isDesktop ? '15vh' : '5vh' }}>
        <Grid item md={2} xs={12} />
        <Grid item md={8} xs={12}>
          <Box
            display="flex"
            textAlign="center"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            width="100%"
            padding={isDesktop ? 4 : 2}
          >
            {children}
          </Box>
        </Grid>
        <Grid item md={2} xs={12} />
      </Grid>

      <Footer />
    </>
  );
}
