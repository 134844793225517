import { Box, useTheme } from '@mui/material';

import { MutableRefObject } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';

export default function Visualizer(props: {
  backgroundCounter: number;
  isPaused: boolean;
  mediaRecorder: MutableRefObject<MediaRecorder | null>;
  visualizerWidth: string | number | undefined;
}) {
  const { backgroundCounter, isPaused, mediaRecorder, visualizerWidth } = props;

  const theme = useTheme();

  return (
    <Box>
      {mediaRecorder && (
        <div>
          {mediaRecorder.current && (
            <Box sx={{
              py: 1,
              ...(isPaused && backgroundCounter % 2 === 0 ? {
                opacity: 0.5,
              } : {
                opacity: 1,
              }),
            }}
            >
              <LiveAudioVisualizer
                mediaRecorder={mediaRecorder.current}
                barColor={theme.palette.primary.darker}
                barWidth={3}
                width={visualizerWidth}
                gap={4}
                smoothingTimeConstant={0.9}
                height={30}
              />
            </Box>
          )}
        </div>
      )}
    </Box>
  );
}
