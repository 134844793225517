import { Box, Typography, useTheme } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';

import { Controller, useFormContext } from 'react-hook-form';

type Props = RadioGroupProps & {
  name: string;
  options: { label: string; description: string; value: any }[];
  label?: string;
  spacing?: number;
  helperText?: React.ReactNode;
};

export default function RHFRadioGroupStyled({
  row,
  name,
  label,
  options,
  spacing,
  helperText,
  ...other
}: Props) {
  const theme = useTheme();
  const { control } = useFormContext();

  const labelledby = label ? `${name}-${label}` : '';

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl component="fieldset">
          {label && (
            <FormLabel component="legend" id={labelledby} sx={{ typography: 'body2' }}>
              {label}
            </FormLabel>
          )}

          <RadioGroup
            {...field}
            aria-labelledby={labelledby}
            row={row}
            {...other}
            sx={{
              px: 2,
            }}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={(
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">
                      {option.label}
                    </Typography>
                    <Typography variant="body2">
                      {option.description}
                    </Typography>
                  </Box>
                )}
                sx={{
                  border: `1px solid ${theme.palette.info.lighter}`,
                  borderRadius: 3,
                  px: 3,
                  py: 1,
                  my: 1,
                  ...(Number(field.value) === option.value ? {
                    border: `2px solid ${theme.palette.primary.main}`,
                  } : {}),
                  '&:hover': {
                    cursor: 'pointer',
                    background: '#F7F8F9',
                    border: `1px solid ${theme.palette.info.lighter}`,
                    ...(Number(field.value) === option.value ? {
                      border: `2px solid ${theme.palette.primary.main}`,
                    } : {}),
                  },
                  '&:not(:last-of-type)': {
                    mb: spacing || 0,
                  },
                  ...(row && {
                    mr: 0,
                    '&:not(:last-of-type)': {
                      mr: spacing || 2,
                    },
                  }),
                }}
              />
            ))}
          </RadioGroup>

          {(!!error || helperText) && (
            <FormHelperText error={!!error} sx={{ mx: 0 }}>
              {error ? error?.message : helperText}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}
