import { Box, Typography, useTheme } from '@mui/material';

export default function Counter(props: {
  currentCounter: number;
  backgroundCounter: number;
  minLengthSeconds: number;
  isPaused: boolean;
}) {
  const { currentCounter, backgroundCounter, minLengthSeconds, isPaused } = props;

  const theme = useTheme();

  return (
    <Box>
      <Typography
        variant="h2"
        color={
          currentCounter > minLengthSeconds ? 'success' : theme.palette.primary.darker
        }
        sx={{
          ...(isPaused && backgroundCounter % 2 === 0 ? {
            opacity: 0.5,
          } : {
            opacity: 1,
          }),
        }}
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          {Math.floor(currentCounter / 60)}
          :
          {currentCounter % 60 < 10 ? '0' : ''}
          {currentCounter % 60}
        </Box>
      </Typography>
    </Box>
  );
}
