import services from '@piccadilly-cloud/connect-platform-services';

import { useCallback, useMemo, useState } from 'react';

import { useApplicationFlowContext } from 'src/contexts/applicationFlowContext/useApplicationFlowContext';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { TrackingEvent, useEventTracker } from 'src/hooks';

export const getQuestionIndex = (tab: string): number => parseInt(tab.split('-')[2], 10);

export const getCleanedTabName = (tab: string): ApplicationFlowTabId => {
  if (tab.includes(ApplicationFlowTabId.ASSESSMENT_QUESTION)) {
    return ApplicationFlowTabId.ASSESSMENT_QUESTION;
  }
  return tab as ApplicationFlowTabId;
};

export enum ApplicationFlowTabId {
  GETTING_STARTED = 'getting-started',
  LOCATION = 'location',
  EXPERIENCE = 'experience',
  AVAILABILITY = 'availability',
  ELIGIBILITY = 'eligibility',
  EDUCATION = 'education',
  CREDENTIALS = 'credentials',
  ASSESSMENT = 'assessment',
  ASSESSMENT_QUESTION = 'assessment-question',
  REVIEW = 'review',
}

export type FlowCompletionActionResponse = Promise<void | string>;
export type FlowCompletionAction = (tab: string) => FlowCompletionActionResponse;

type ApplicationFlowTabCompletionAction = () => Promise<void>;

export interface ApplicationFlowTab {
  id: ApplicationFlowTabId;
  title: string;
  completionAction?: ApplicationFlowTabCompletionAction;
  isComplete?: boolean;
}

export const useApplicationFlow = () => {
  const session = useSessionContext();
  const {
    application,
    isEditing,
    isPreview,
    jobListing,
  } = useApplicationFlowContext();
  const [applicationFlowTab, setApplicationFlowTab] = useState<ApplicationFlowTabId>(
    ApplicationFlowTabId.GETTING_STARTED,
  );
  const eventTracker = useEventTracker();

  const credentialsEnabled = (jobListing?.certificates?.length ?? 0) > 0;

  const gettingStartedCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update assessment');
    }

    await services.edge.candidate.application.updateAssessment(
      session.account.email,
      application.id,
      application.profile.assessment,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const locationCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update location');
    }

    await services.edge.candidate.application.updateLocation(
      session.account.email,
      application.id,
      application.profile.location,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const experienceCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update experience');
    }

    await services.edge.candidate.application.updateExperience(
      session.account.email,
      application.id,
      application.profile.experience,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const availabilityCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update availability');
    }

    await services.edge.candidate.application.updateAvailability(
      session.account.email,
      application.id,
      application.profile.availability,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const eligibilityCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update eligibility');
    }

    await services.edge.candidate.application.updateEligibility(
      session.account.email,
      application.id,
      application.profile.eligibility,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const educationCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update education');
    }

    await services.edge.candidate.application.updateEducation(
      session.account.email,
      application.id,
      application.profile.education,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const credentialsCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }
    if (!application?.profile) {
      throw new Error('Failed to update credentials');
    }
    await services.edge.candidate.application.updateCredentials(
      session.account.email,
      application.id,
      application.profile.credentials,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const assessmentCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update assessment');
    }

    await services.edge.candidate.application.updateAssessment(
      session.account.email,
      application.id,
      application.profile.assessment,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const assessmentQuestionCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application?.profile) {
      throw new Error('Failed to update assessment');
    }

    await services.edge.candidate.application.updateAssessment(
      session.account.email,
      application.id,
      application.profile.assessment,
    )({ token: session.token });
  }, [
    application?.id,
    application?.profile,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const reviewCompletionAction = useCallback(async () => {
    if (isPreview) {
      return;
    }

    if (!application) {
      throw new Error('Failed to submit application');
    }

    await services.edge.candidate.application.submit(
      session.account.email,
      application.id,
    )({ token: session.token });
    eventTracker.trackEvent(TrackingEvent.CANDIDATE_APPLICATION_SUBMISSION, {
      jobListingId: application.id,
    });
  }, [
    application,
    eventTracker,
    isPreview,
    session.account.email,
    session.token,
  ]);

  const completionActions: Record<ApplicationFlowTabId, ApplicationFlowTabCompletionAction> = {
    [ApplicationFlowTabId.GETTING_STARTED]: gettingStartedCompletionAction,
    [ApplicationFlowTabId.LOCATION]: locationCompletionAction,
    [ApplicationFlowTabId.EXPERIENCE]: experienceCompletionAction,
    [ApplicationFlowTabId.AVAILABILITY]: availabilityCompletionAction,
    [ApplicationFlowTabId.ELIGIBILITY]: eligibilityCompletionAction,
    [ApplicationFlowTabId.EDUCATION]: educationCompletionAction,
    [ApplicationFlowTabId.CREDENTIALS]: credentialsCompletionAction,
    [ApplicationFlowTabId.ASSESSMENT]: assessmentCompletionAction,
    [ApplicationFlowTabId.ASSESSMENT_QUESTION]: assessmentQuestionCompletionAction,
    [ApplicationFlowTabId.REVIEW]: reviewCompletionAction,
  };

  const applicationFlowTabMap = useMemo(() => {
    const tabMap = new Map<ApplicationFlowTabId, ApplicationFlowTab>();
    if (!jobListing?.isAssessmentOnly) {
      tabMap.set(ApplicationFlowTabId.GETTING_STARTED, {
        id: ApplicationFlowTabId.GETTING_STARTED,
        title: 'Getting Started',
        completionAction: gettingStartedCompletionAction,
      });
      tabMap.set(ApplicationFlowTabId.LOCATION, {
        id: ApplicationFlowTabId.LOCATION,
        title: 'Location',
        completionAction: locationCompletionAction,
        isComplete: application?.profile.location.isComplete,
      });
      tabMap.set(ApplicationFlowTabId.EXPERIENCE, {
        id: ApplicationFlowTabId.EXPERIENCE,
        title: 'Experience',
        completionAction: experienceCompletionAction,
        isComplete: application?.profile.experience.isComplete,
      });
      tabMap.set(ApplicationFlowTabId.AVAILABILITY, {
        id: ApplicationFlowTabId.AVAILABILITY,
        title: 'Availability',
        completionAction: availabilityCompletionAction,
        isComplete: application?.profile.availability.isComplete,
      });
      tabMap.set(ApplicationFlowTabId.ELIGIBILITY, {
        id: ApplicationFlowTabId.ELIGIBILITY,
        title: 'Eligibility',
        completionAction: eligibilityCompletionAction,
        isComplete: application?.profile.eligibility.isComplete,
      });
      tabMap.set(ApplicationFlowTabId.EDUCATION, {
        id: ApplicationFlowTabId.EDUCATION,
        title: 'Education',
        completionAction: educationCompletionAction,
        isComplete: application?.profile.education.isComplete,
      });

      if (credentialsEnabled) {
        tabMap.set(ApplicationFlowTabId.CREDENTIALS, {
          id: ApplicationFlowTabId.CREDENTIALS,
          title: 'Credentials',
          completionAction: credentialsCompletionAction,
          isComplete: application?.profile.credentials.isComplete,
        });
      }
    }

    tabMap.set(ApplicationFlowTabId.ASSESSMENT, {
      id: ApplicationFlowTabId.ASSESSMENT,
      title: 'Assessment',
      completionAction: assessmentCompletionAction,
      isComplete: application?.profile.assessment.isComplete,
    });
    tabMap.set(ApplicationFlowTabId.ASSESSMENT_QUESTION, {
      id: ApplicationFlowTabId.ASSESSMENT_QUESTION,
      title: 'Assessment Question',
      completionAction: assessmentQuestionCompletionAction,
      isComplete: application?.profile.assessment.isComplete,
    });
    tabMap.set(ApplicationFlowTabId.REVIEW, {
      id: ApplicationFlowTabId.REVIEW,
      title: 'Review and Submit',
      completionAction: reviewCompletionAction,
    });
    return tabMap;
  }, [
    application?.profile.assessment.isComplete,
    application?.profile.availability.isComplete,
    application?.profile.credentials.isComplete,
    application?.profile.education.isComplete,
    application?.profile.eligibility.isComplete,
    application?.profile.experience.isComplete,
    application?.profile.location.isComplete,
    assessmentCompletionAction,
    assessmentQuestionCompletionAction,
    availabilityCompletionAction,
    credentialsCompletionAction,
    credentialsEnabled,
    educationCompletionAction,
    eligibilityCompletionAction,
    experienceCompletionAction,
    gettingStartedCompletionAction,
    jobListing?.isAssessmentOnly,
    locationCompletionAction,
    reviewCompletionAction,
  ]);

  const getInitialApplicationFlowStep = (): ApplicationFlowTab | undefined => {
    if (jobListing?.isAssessmentOnly) {
      return applicationFlowTabMap.get(ApplicationFlowTabId.ASSESSMENT);
    }

    const steps = Array.from(applicationFlowTabMap.values());
    const lastCompletedStep = steps.findLast((step) => step.isComplete);
    if (!lastCompletedStep) {
      return applicationFlowTabMap.get(ApplicationFlowTabId.GETTING_STARTED);
    }

    if (lastCompletedStep.id === ApplicationFlowTabId.ASSESSMENT_QUESTION) {
      return applicationFlowTabMap.get(ApplicationFlowTabId.REVIEW);
    }

    return lastCompletedStep;
  };

  const getBackTabInOrder = (tab: ApplicationFlowTabId): ApplicationFlowTab => {
    const tabName = getCleanedTabName(tab);
    const tabIndex = Array.from(applicationFlowTabMap.keys()).indexOf(tabName);
    let previousTabIndex = tabIndex - 1;
    if (previousTabIndex < 0) {
      previousTabIndex = 0;
    }
    const backTab = applicationFlowTabMap.get(Array.from(applicationFlowTabMap.keys())[previousTabIndex]);
    if (!backTab) {
      throw new Error('no back step found');
    }

    return backTab;
  };

  const getNextTabInOrder = (tab: ApplicationFlowTabId): ApplicationFlowTab => {
    if (isEditing) {
      const reviewTab = applicationFlowTabMap.get(ApplicationFlowTabId.REVIEW);
      if (!reviewTab) {
        throw new Error('no review step found');
      }

      return reviewTab;
    }

    const tabIndex = Array.from(applicationFlowTabMap.keys()).indexOf(tab);
    const nextTab = applicationFlowTabMap.get(Array.from(applicationFlowTabMap.keys())[tabIndex + 1]);
    if (!nextTab) {
      throw new Error('no next step found');
    }

    return nextTab;
  };

  return {
    applicationFlowTab,
    completionActions,
    getInitialApplicationFlowStep,
    getBackTabInOrder,
    getNextTabInOrder,
    setApplicationFlowTab,
  };
};
