// @mui
import FormHelperText from '@mui/material/FormHelperText';

//
import { FileRejection } from 'react-dropzone';
import { Controller, useFormContext } from 'react-hook-form';

import { Upload, UploadAvatar, UploadBox, UploadProps } from '../upload';
import UploadAudio from '../upload/upload-audio';

// ----------------------------------------------------------------------

interface Props extends Omit<UploadProps, 'file'> {
  name: string;
  multiple?: boolean;
}

interface AudioUploadProps extends Props {
  loading: boolean;
  onUploadAsync: (acceptedFiles: File[], rejectedFiles?: FileRejection[]) => Promise<string>;
}

// ----------------------------------------------------------------------

export function RHFUploadAvatar({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAvatar error={!!error} file={field.value} {...other} />

          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUploadAudio({ name, ...other }: AudioUploadProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div>
          <UploadAudio error={!!error} file={field.value} {...other} />
          {!!error && (
            <FormHelperText error sx={{ px: 2, textAlign: 'flex-start' }}>
              {error.message}
            </FormHelperText>
          )}
        </div>
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUploadBox({ name, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <UploadBox files={field.value} error={!!error} {...other} />
      )}
    />
  );
}

// ----------------------------------------------------------------------

export function RHFUpload({ name, multiple, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => multiple ? (
        <Upload
          multiple
          accept={{ 'image/*': [] }}
          files={field.value}
          error={!!error}
          helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
          {...other}
        />
      ) : (
        <Upload
          accept={{ 'image/*': [] }}
          file={field.value}
          error={!!error}
          helperText={
              (!!error || helperText) && (
                <FormHelperText error={!!error} sx={{ px: 2 }}>
                  {error ? error?.message : helperText}
                </FormHelperText>
              )
            }
          {...other}
        />
      )}
    />
  );
}
