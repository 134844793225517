import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';

import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/contexts/session/guards/auth-guard';
import RecruiterGuard from 'src/contexts/session/guards/recruiter-guard';
import ApplicationPreviewLayout from 'src/layouts/application-preview/layout';
import PrintClassicLayout from 'src/layouts/print/classic';
// import DashboardLayoutUnjustified from 'src/layouts/workspace-unjustified';
import DashboardLayout from 'src/layouts/v2/dashboard/layout';

// ----------------------------------------------------------------------

const Dashboard = lazy(() => import('src/pages/dashboard'));
const Profile = lazy(() => import('src/pages/user-account/profile'));
const AppSettings = lazy(() => import('src/pages/app/app-settings'));

const CandidateAllCandidatesPage = lazy(() => import('src/pages/recruiter/candidates/candidates'));
const CandidateScorecardPage = lazy(() => import('src/pages/recruiter/candidates/candidate-scorecard'));
const CandidateScorecardPrintPage = lazy(() => import('src/pages/recruiter/candidates/candidate-scorecard-print'));

const ListingsPage = lazy(() => import('src/pages/recruiter/listings/listings-table'));
const ListingEditorPage = lazy(() => import('src/pages/recruiter/listings/listing-editor'));
const ProfileSelectionPage = lazy(() => import('src/pages/recruiter/listings/profile-selection'));

const ListingDashboardPage = lazy(() => import('src/pages/recruiter/listing-dashboard/listing-dashboard'));
const ListingDashboardCandidateScorecardPage = lazy(() =>
  import('src/pages/recruiter/listing-dashboard/listing-dashboard-candidate'),
);
const ListingDashboardCandidateComparePage = lazy(() =>
  import('src/pages/recruiter/listing-dashboard/listing-dashboard-compare'),
);

const WorkspaceMembersPage = lazy(() => import('src/pages/recruiter/workspace/workspace-member-page'));

const CandidateApplicationPreviewPage = lazy(() => import('src/pages/recruiter/application/application-preview'));

// ----------------------------------------------------------------------

export const recruiterRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <RecruiterGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RecruiterGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <Dashboard />, index: true },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <RecruiterGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </RecruiterGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'profile', element: <Profile />, index: true },
      { path: 'app-settings', element: <AppSettings />, index: true },

      { path: 'recruiter/w/:workspaceIndex/dashboard', element: <Dashboard />, index: true },

      { path: 'recruiter/w/:workspaceIndex/members', element: <WorkspaceMembersPage /> },

      { path: 'recruiter/w/:workspaceIndex/jobs', element: <ListingsPage /> },
      { path: 'recruiter/w/:workspaceIndex/jobs/:listingId', element: <ListingEditorPage /> },
      { path: 'recruiter/w/:workspaceIndex/job/create', element: <ProfileSelectionPage /> },

      { path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId', element: <ListingDashboardPage /> },
      {
        path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId/candidate/:teqScoreId',
        element: <ListingDashboardCandidateScorecardPage />,
      },
      {
        path: 'recruiter/w/:workspaceIndex/dashboard/job/:listingId/compare',
        element: <ListingDashboardCandidateComparePage />,
      },

      { path: 'recruiter/w/:workspaceIndex/candidates', element: <CandidateAllCandidatesPage /> },
      { path: 'recruiter/w/:workspaceIndex/candidates/results/:teqScoreId', element: <CandidateScorecardPage /> },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <RecruiterGuard>
          <PrintClassicLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </PrintClassicLayout>
        </RecruiterGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'recruiter/w/:workspaceIndex/candidates/results/:teqScoreId/print',
        element: <CandidateScorecardPrintPage />,
      },
    ],
  },

  {
    path: '',
    element: (
      <AuthGuard>
        <RecruiterGuard>
          <ApplicationPreviewLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </ApplicationPreviewLayout>
        </RecruiterGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'recruiter/w/:workspaceIndex/listing/:listingId/assessment/preview',
        element: <CandidateApplicationPreviewPage />,
      },
    ],
  },
];
