import { Session } from '@piccadilly-cloud/connect-platform-services';

import { defaultWelcomeDialogConfig, getOnboardingFlowConfig } from './config';
import { OnboardingFlowType, OnboardingStep } from './model';

export const shouldUseOnboarding = (session: Session): boolean => {
  const isUserEligibleForOnboarding = session.account.accountRole === 'WORKSPACE_ADMIN';
  const hasValidUserAccountId = session.account.id !== '';

  return isUserEligibleForOnboarding && hasValidUserAccountId;
};

export const getStepsConfig = (session: Session, workspaceIndex: number): OnboardingStep[] => {
  let steps: OnboardingStep[] = [];
  const flowType = getFlowType(session);
  const configs = getOnboardingFlowConfig(workspaceIndex, flowType);
  if (session.appConfig.behavior.useDemoMode) {
    steps = configs[OnboardingFlowType.PURCHASE];
  }
  if (session.account.behaviorSettings.useGettingStarted) {
    steps = configs[OnboardingFlowType.PREPURCHASE];
  }

  return steps;
};

export const getWelcomeDialog = defaultWelcomeDialogConfig;

export const getFlowType = (session: Session): OnboardingFlowType | undefined => {
  if (session.appConfig.behavior.useDemoMode) {
    return OnboardingFlowType.PURCHASE;
  }
  if (session.account.behaviorSettings.useGettingStarted) {
    return OnboardingFlowType.PREPURCHASE;
  }

  return undefined;
};
