/* eslint-disable react-hooks/rules-of-hooks */
import services from '@piccadilly-cloud/connect-platform-services';

import {
  Reducer,
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';

import useLogger from 'src/utils/useReducerLogger';

import { ContextMutations } from '../model';
import { useSessionContext } from '../session/useSessionContext';

import { initApplication, initListing, initProfile } from './helpers';
import {
  ApplicationFlowAction,
  ApplicationFlowDContextDispatch,
  ApplicationFlowDispatch,
  ApplicationFlowState,
} from './model';

type S = ApplicationFlowState;

const initState = (): S => ({
  application: initApplication(),
  dispatch: applicationFlowDispatch(() => { }),
  isEditing: false,
  isLoading: false,
  isPreview: false,
  isSurveyComplete: false,
  jobListing: initListing(),
  jobProfile: initProfile(),
});

const applicationFlowMutations: ContextMutations<S, ApplicationFlowAction> = {
  'applicationFlow/RESET_STATE': (state) => ({
    dispatch: state.dispatch,
    application: initApplication(),
    isEditing: false,
    isLoading: false,
    isPreview: false,
    isSurveyComplete: false,
    jobListing: initListing(),
    jobProfile: initProfile(),
  }),

  'applicationFlow/SET_APPLICATION': (state, { application }) => ({
    ...state,
    application,
  }),

  'applicationFlow/SET_APPLICATION_SUBMITTED': (state, { isSubmitted }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        submitted: isSubmitted,
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_ASSESSMENT_COMPLETE': (state, { isComplete }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          assessment: {
            ...state.application.profile.assessment,
            isComplete,
          },
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_ASSESSMENT_QUESTION_RESPONSES': (state, { responses }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          assessment: {
            ...state.application.profile.assessment,
            responseAnswers: responses,
          },
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_AVAILABILITY': (state, { availability }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          availability,
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_CREDENTIALS': (state, { credentials }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          credentials,
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_EDUCATION': (state, { education }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          education,
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_ELIGIBILITY': (state, { eligibility }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          eligibility,
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_EXPERIENCE': (state, { experience }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          experience,
        },
      },
    };
  },

  'applicationFlow/SET_CANDIDATE_PROFILE_LOCATION': (state, { location }) => {
    if (!state.application) {
      return state;
    }

    return {
      ...state,
      application: {
        ...state.application,
        profile: {
          ...state.application.profile,
          location,
        },
      },
    };
  },

  'applicationFlow/SET_DISPATCH': (state, { dispatch }) => ({
    ...state,
    dispatch,
  }),

  'applicationFlow/SET_IS_EDITING': (state, { isEditing }) => ({
    ...state,
    isEditing,
  }),

  'applicationFlow/SET_IS_LOADING': (state, { isLoading }) => ({
    ...state,
    isLoading,
  }),

  'applicationFlow/SET_IS_PREVIEW': (state, { isPreview }) => ({
    ...state,
    isPreview,
  }),

  'applicationFlow/SET_IS_SURVEY_COMPLETE': (state, { isSurveyComplete }) => ({
    ...state,
    isSurveyComplete,
  }),

  'applicationFlow/SET_JOB_LISTING': (state, { jobListing }) => ({
    ...state,
    jobListing,
  }),

  'applicationFlow/SET_JOB_PROFILE': (state, { jobProfile }) => ({
    ...state,
    jobProfile,
  }),

  'applicationFlow/SET_LISTING_COMPANY_NAME': (state, { companyName }) => {
    if (!state.jobListing) {
      return state;
    }

    return {
      ...state,
      jobListing: {
        ...state.jobListing,
        companyName,
      },
    };
  },

  'applicationFlow/SET_LISTING_NAME': (state, { listingName }) => {
    if (!state.jobListing) {
      return state;
    }

    return {
      ...state,
      jobListing: {
        ...state.jobListing,
        name: listingName,
      },
    };
  },
};

const reducer = (
  state: S,
  action: ApplicationFlowAction,
): S => applicationFlowMutations[action.type](state, action.payload as any) ?? state;

const applicationFlowDispatch: ApplicationFlowDContextDispatch = (dispatch): ApplicationFlowDispatch => ({
  'applicationFlow/resetState': async (payload) => {
    dispatch({ type: 'applicationFlow/RESET_STATE', payload });
  },

  'applicationFlow/setApplication': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_APPLICATION', payload });
  },

  'applicationFlow/setApplicationSubmitted': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_APPLICATION_SUBMITTED', payload });
  },

  'applicationFlow/setCandidateProfileAssessmentComplete': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_ASSESSMENT_COMPLETE', payload });
  },

  'applicationFlow/setCandidateProfileAssessmentQuestionResponses': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_ASSESSMENT_QUESTION_RESPONSES', payload });
  },

  'applicationFlow/setCandidateProfileAvailability': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_AVAILABILITY', payload });
  },

  'applicationFlow/setCandidateProfileCredentials': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_CREDENTIALS', payload });
  },

  'applicationFlow/setCandidateProfileEducation': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_EDUCATION', payload });
  },

  'applicationFlow/setCandidateProfileEligibility': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_ELIGIBILITY', payload });
  },

  'applicationFlow/setCandidateProfileExperience': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_EXPERIENCE', payload });
  },

  'applicationFlow/setCandidateProfileLocation': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_CANDIDATE_PROFILE_LOCATION', payload });
  },

  'applicationFlow/setIsEditing': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_IS_EDITING', payload });
  },

  'applicationFlow/setIsLoading': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_IS_LOADING', payload });
  },

  'applicationFlow/setIsPreview': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_IS_PREVIEW', payload });
  },

  'applicationFlow/setIsSurveyComplete': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_IS_SURVEY_COMPLETE', payload });
  },

  'applicationFlow/setJobListing': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_JOB_LISTING', payload });
  },

  'applicationFlow/setJobProfile': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_JOB_PROFILE', payload });
  },

  'applicationFlow/setListingCompanyName': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_LISTING_COMPANY_NAME', payload });
  },

  'applicationFlow/setListingName': async (payload) => {
    dispatch({ type: 'applicationFlow/SET_LISTING_NAME', payload });
  },
});

export interface ApplicationFlowProviderProps {
  children: React.ReactNode;
}

export const ApplicationFlowContext = createContext<S | null>(null);
export function ApplicationFlowProvider({ children }: ApplicationFlowProviderProps) {
  const session = useSessionContext();
  const params = useParams();
  const [applicationId] = useState(params.applicationId || '');
  const [state, dispatch] = useReducer<Reducer<S, ApplicationFlowAction>>(
    process.env.REACT_APP_DEBUG_REDUX === 'true'
      ? useLogger(reducer)
      : reducer,
    initState(),
  );

  const isValidDemographics = useMemo(() => {
    const {
      useDemographicCollectionExtended,
    } = session.appConfig.behavior.candidateBehavior;

    const isValidDemographicsExtended = !useDemographicCollectionExtended
      || (session.account.analyticsInfo.extendedQuestions
        && session.account.analyticsInfo.extendedQuestions.length !== 0);

    return session.account.analyticsInfo.age !== ''
      && session.account.analyticsInfo.ethnicity !== ''
      && session.account.analyticsInfo.gender !== ''
      && isValidDemographicsExtended;
  }, [
    session.account.analyticsInfo.age,
    session.account.analyticsInfo.ethnicity,
    session.account.analyticsInfo.gender,
    session.account.analyticsInfo.extendedQuestions,
    session.appConfig.behavior.candidateBehavior,
  ]);

  const applicationDataQuery = useQuery(
    [`application-${session.account.email}`, {}],
    async () => {
      dispatch({ type: 'applicationFlow/SET_IS_LOADING', payload: { isLoading: true } });
      const data = await services.edge.candidate.application.edit(
        session.account.email,
        applicationId,
      )({ token: session.token });
      dispatch({ type: 'applicationFlow/SET_IS_LOADING', payload: { isLoading: false } });
      return data;
    },
    {
      keepPreviousData: false,
      cacheTime: 1,
      staleTime: Infinity,
      refetchOnMount: 'always',
      enabled: applicationId !== '',
    },
  );

  useEffect(() => {
    dispatch({
      type: 'applicationFlow/SET_DISPATCH',
      payload: { dispatch: applicationFlowDispatch(dispatch) },
    });
  }, [dispatch]);

  useEffect(() => {
    if (!applicationDataQuery.data) {
      return;
    }

    const {
      application,
      jobListing,
      jobProfile,
    } = applicationDataQuery.data;
    dispatch({
      type: 'applicationFlow/SET_APPLICATION',
      payload: { application },
    });
    dispatch({
      type: 'applicationFlow/SET_JOB_LISTING',
      payload: { jobListing },
    });
    dispatch({
      type: 'applicationFlow/SET_JOB_PROFILE',
      payload: { jobProfile },
    });

    dispatch({
      type: 'applicationFlow/SET_IS_SURVEY_COMPLETE',
      payload: { isSurveyComplete: isValidDemographics },
    });
  }, [applicationDataQuery.data, isValidDemographics]);

  return (
    <ApplicationFlowContext.Provider value={state}>
      {children}
    </ApplicationFlowContext.Provider>
  );
}
