import { Alert, Box } from '@mui/material';

export default function UnsupportedErr(props: {
  unsupportedErr: boolean;
  permissionErr: boolean;
}) {
  const { unsupportedErr, permissionErr } = props;

  if (!unsupportedErr && permissionErr === true) {
    return (
      <Box sx={{ pb: 3 }}>
        <Alert severity="error">
          Please allow access to microphone in order to proceed.
        </Alert>
      </Box>
    );
  }

  if (unsupportedErr) {
    return (
      <Box sx={{ pb: 3 }}>
        <Alert severity="error">
          Your device does not support microphone recording in the browser. Please use an alternative
          input type.
        </Alert>
      </Box>
    );
  }

  return (<Box />);
}
