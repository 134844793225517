/* eslint-disable react-hooks/rules-of-hooks */
import services, {
  AppConfig,
  AppConfigWorkspace,
  CandidateUserAccountRegistrationRequest,
  Session,
  UserAccount,
  UserAccountInvitation,
  UserAccountRegistrationRequest,
} from '@piccadilly-cloud/connect-platform-services';

import { createContext, useCallback, useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';

import { identifyAuthenticatedUserSegment } from 'src/ctrl/segment';
import { initAppConfig, initDynamicVendorPublic, initSession } from 'src/utils';
import { initUserAccount } from 'src/utils/initializers/user';
import useLogger from 'src/utils/useReducerLogger';

import authActions from './actions/auth';
import auth from './reducers/auth';
import dynamicVendor from './reducers/dynamicVendor';
import { ActionsType, AuthStateType, SessionContextType, SessionTypes } from './types';
import { isValidToken, setSession } from './utils';

const initState = (): AuthStateType => ({
  account: initUserAccount(),
  activeWorkspace: -1,
  appConfig: initAppConfig(),
  dynamicVendor: initDynamicVendorPublic(),
  isAuthenticated: false,
  isFailedToFetch: false,
  isInitialized: false,
  sessionType: 'UNAUTHENTICATED',
  token: '',
  workspaces: [],
});

const reducer = (state: AuthStateType, action: ActionsType) => {
  const reducers = {
    ...auth.reducers,
    ...dynamicVendor.reducers,
  };
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

export const SessionContext = createContext<SessionContextType | null>(null);
export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(
    process.env.REACT_APP_DEBUG_REDUX === 'true'
      ? useLogger(reducer)
      : reducer,
    initState(),
  );

  const initialize = useCallback(async () => {
    let currentHostname = window.location.hostname;
    currentHostname = currentHostname.replace('www.', '');
    if (currentHostname.endsWith('.ai')) {
      currentHostname = currentHostname.replace('.ai', '');
    } else if (currentHostname.endsWith('.com')) {
      currentHostname = currentHostname.replace('.com', '');
    }

    try {
      const accessToken = typeof window !== 'undefined' ? localStorage.getItem('piccadillyAccessToken') : '';
      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const session: Session = await services.edge.session.create({
          appHost: currentHostname,
          token: accessToken,
          vendor: '',
          email: '',
          phoneNumber: '',
          password: '',
          rememberMe: false,
        })({});

        dispatch({
          type: SessionTypes.INITIAL,
          payload: {
            isAuthenticated: true,
            session,
          },
        });
        identifyAuthenticatedUserSegment(session.account);
      } else {
        const session: Session = await services.edge.session.create({
          appHost: currentHostname,
          token: '',
          vendor: '',
          email: '',
          phoneNumber: '',
          password: '',
          rememberMe: false,
        })({});

        dispatch({
          type: SessionTypes.INITIAL,
          payload: {
            isAuthenticated: false,
            session,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: SessionTypes.INITIAL,
        payload: {
          isAuthenticated: false,
          isFailedToFetch: true,
          session: initSession(state.account, state.appConfig),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const { refetch: refetchDynamicVendor, ...dynamicVendorQuery } = useQuery(
    [`dynamic-vendor-${state.appConfig.vendor}`],
    () => {
      if (!state.isAuthenticated || state.account.accountRole === 'CANDIDATE') {
        return undefined;
      }

      return services.edge.manage.getForVendor()({
        token: state.token,
        vendor: state.appConfig.vendor,
      });
    },
    {
      keepPreviousData: false,
      cacheTime: 1,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    },
  );

  useEffect(() => {
    if (!state.isAuthenticated) {
      return;
    }

    if (dynamicVendorQuery.data) {
      dispatch({
        type: SessionTypes.UPDATE_DYNAMIC_VENDOR,
        payload: {
          dynamicVendor: dynamicVendorQuery.data,
        },
      });
    } else {
      refetchDynamicVendor();
    }
  }, [
    refetchDynamicVendor,
    dynamicVendorQuery.data,
    state.isAuthenticated,
  ]);

  return (
    <SessionContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        ...state,
        session: {
          account: state.account,
          appConfig: state.appConfig,
          sessionType: state.sessionType,
          workspaces: state.workspaces,
          token: state.token,
        },
        method: 'jwt',
        getDynamicVendor: refetchDynamicVendor,
        loginUniversal: (
          token: string,
          email: string,
          password: string,
          rememberMe: boolean,
          vendor: string,
        ) => authActions.loginUniversal(token, email, password, rememberMe, vendor)(dispatch),
        login: (
          token: string,
          email: string,
          password: string,
          rememberMe: boolean,
          vendor: string,
        ) => authActions.login(token, email, password, rememberMe, vendor)(dispatch),
        loginPasscode: (
          token: string,
          passCode: string,
          vendor: string,
        ) => authActions.loginPasscode(token, passCode, vendor)(dispatch),
        logout: () => authActions.logout()(dispatch),
        register: (
          token: string,
          request: UserAccountRegistrationRequest,
          invitation: UserAccountInvitation,
        ) => authActions.register(token, request, invitation)(dispatch),
        registerCandidate: (
          token: string,
          request: CandidateUserAccountRegistrationRequest,
        ) => authActions.registerCandidate(token, request)(dispatch),
        updateCurrentAccount: (
          updatedAccount: UserAccount,
        ) => authActions.updateCurrentAccount(updatedAccount)(dispatch),
        updateCurrentAppConfig: (
          updatedAppConfig: AppConfig,
        ) => authActions.updateCurrentAppConfig(updatedAppConfig)(dispatch),
        updateCurrentAppConfigWorkspaces: (
          updatedWorkspaces: AppConfigWorkspace[],
        ) => authActions.updateCurrentAppConfigWorkspaces(updatedWorkspaces)(dispatch),

        setCurrentWorkspace: (workspaceIndex: number) => authActions.setCurrentWorkspace(workspaceIndex)(dispatch),
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}
