import { Box, CircularProgress, Typography } from '@mui/material';

interface LoadingInlineProps {
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'inherit';
  message?: string;
  size?: number;
}

export default function LoadingInline(props: LoadingInlineProps) {
  const { color, message, size } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={1}
    >
      <CircularProgress
        color={color || 'primary'}
        size={size || '40px'}
      />
      {message && (
        <Typography variant="body2" color="text.secondary">
          {message}
        </Typography>
      )}
    </Box>
  );
}
