import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

export default function DialogRecordAudioConfirm(props: {
  open: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}) {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent sx={{ py: 3 }}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Typography variant="h5">
            Confirm completion
          </Typography>
          <Typography variant="body1">
            Are you sure you are done answering this question?
          </Typography>

          <Box display="flex" flexDirection="row" columnGap={1}>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              onClick={onClose}
            >
              No, keep recording
            </Button>
            <LoadingButton
              color="primary"
              variant="contained"
              fullWidth
              onClick={onConfirm}
            >
              Yes, end recording
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
