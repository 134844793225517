import { DynamicVendorAssessments } from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';

import Iconify from 'src/components/iconify';
import { useSessionContext } from 'src/contexts';
import { Invitee } from 'src/utils/initializers/appConfig';
import VendorInvitationForm from 'src/views/vendor-admin/invite/vendor-invitation-form';

import { LoadingScreen } from '../loading-screen';

import CustomDialog, { CustomDialogProps } from './custom-dialog';

const calculateRemainingAssessments = (data: DynamicVendorAssessments, assigned: number = 0) => {
  if (!data) {
    return 0;
  }
  return data.remaining - assigned;
};

export interface VendorInvitationDialogProps extends CustomDialogProps {
  invitee?: Invitee;
  isFirstInvite?: boolean;
}

export default function VendorInvitationDialog(props: VendorInvitationDialogProps) {
  const { invitee, open, isFirstInvite, emit } = props;
  const session = useSessionContext();
  const theme = useTheme();
  const [remainingAssessments, setRemainingAssessments] = useState(0);

  useEffect(() => {
    if (session.dynamicVendor) {
      setRemainingAssessments(calculateRemainingAssessments(session.dynamicVendor.assessments));
    }
  }, [session.dynamicVendor]);

  const handleUpdateRemaining = (assigned: number) => {
    if (session.dynamicVendor) {
      setRemainingAssessments(calculateRemainingAssessments(session.dynamicVendor.assessments, assigned));
    }
  };

  if (!session.dynamicVendor) {
    return <LoadingScreen />;
  }

  const renderTitle = () => {
    if (invitee) {
      return (
        <Box display="flex" alignItems="center" columnGap={1}>
          <Typography variant="h4">Vendor configuration</Typography>
        </Box>
      );
    }
    return (
      isFirstInvite ? (
        <Box display="flex" alignItems="center" columnGap={1}>
          <Typography variant="h3" fontWeight={600}>Invite your first vendor</Typography>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" columnGap={1} color={theme.palette.primary.main}>
          <Iconify icon="fluent-mdl2:join-online-meeting" />
          <Typography variant="h4">Invite vendors</Typography>
        </Box>
      )
    );
  };

  return (
    <CustomDialog
      open={open}
      title={renderTitle()}
      hideConfirm
      hideCancel
      maxWidth="md"
      emit={{
        close: emit?.close,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        rowGap={3}
      >
        {!invitee && (
          <Typography>
            Provide the information for the vendor(s) you want to invite.
          </Typography>
        )}

        <VendorInvitationForm
          initialInvitee={invitee}
          data={session.dynamicVendor}
          isFirstInvite={isFirstInvite}
          remainingAssessments={remainingAssessments}
          onUpdateRemaining={handleUpdateRemaining}
          onRefresh={session.getDynamicVendor}
        />
      </Box>
    </CustomDialog>
  );
}
