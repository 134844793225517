// i18n
import 'src/locales/i18n';

// scrollbar
import 'simplebar-react/dist/simplebar.min.css';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

// carousel
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

// ----------------------------------------------------------------------

// routes
import { useEffect } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';

import { MotionLazy } from 'src/components/animate/motion-lazy';
import ProgressBar from 'src/components/progress-bar';
import { SettingsDrawer, SettingsProvider } from 'src/components/settings';
import { SnackbarProvider } from 'src/components/snackbar';
import { useDevMode, useScrollToTop } from 'src/hooks';
import { LocalizationProvider } from 'src/locales';
import Router from 'src/routes/sections';
import ThemeProvider from 'src/theme/mui';

import Debug from './components/debug/debug';
import SessionDebug from './components/debug/session-debug';
import { OnboardingFlowProvider } from './contexts/onboardingContext';
import SessionGuard from './contexts/session/guards/session-guard';
import { useSessionContext } from './contexts/session/useSessionContext';
import { initSegment } from './ctrl/segment';

export default function App() {
  const session = useSessionContext();
  const devModeAvailable = useDevMode();

  useScrollToTop();

  useEffect(() => {
    initSegment();
  }, []);

  return (
    <LocalizationProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'light', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'bold', // 'default' | 'bold'
          themeLayout: 'mini', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: true,
        }}
      >
        <ThemeProvider>
          <SnackbarProvider>
            <MotionLazy>
              <SessionGuard>
                <SettingsDrawer />
                <OnboardingFlowProvider>
                  <ProgressBar />
                  <Router session={session} />
                  {process.env.REACT_APP_DEBUG_QUERY === 'true' && (
                    <ReactQueryDevtools position="bottom-right" />
                  )}
                  {devModeAvailable && (
                    <Debug>
                      <SessionDebug
                        appConfig={session.appConfig}
                        dynamicVendor={session.dynamicVendor}
                        userAccount={session.account}
                      />
                    </Debug>
                  )}
                </OnboardingFlowProvider>
              </SessionGuard>
            </MotionLazy>
          </SnackbarProvider>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
