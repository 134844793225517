import { Box, Typography, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';

import QuestionCard from '../partials/question-card';

import { CurrentQuestion } from './util';

export default function QuestionLayout(props: {
  children: JSX.Element;
  controlBox: JSX.Element;

  currentQuestion: CurrentQuestion;
}) {
  const { children, controlBox, currentQuestion } = props;

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      rowGap={1}
    >

      <QuestionCard
        title={currentQuestion.question}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            columnGap={1}
            sx={{
              backgroundColor: theme.palette.info.lighter,
              borderRadius: 3,
              p: 1,
              px: 2,
            }}
          >
            <Iconify icon="mdi:lightbulb-on" color={theme.palette.grey[700]} />
            <Typography variant="body2" color={theme.palette.grey[700]}>
              {currentQuestion.tip}
            </Typography>
          </Box>
          {children}
        </Box>
      </QuestionCard>
      <Box>
        {controlBox}
      </Box>
    </Box>
  );
}
