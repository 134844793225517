import TagManager from 'react-gtm-module';

import { useSessionContext } from 'src/contexts/session';

import { useWorkspace } from './use-workspace';

export const useEventTracker = () => {
  const session = useSessionContext();
  const { workspace } = useWorkspace();

  const trackEvent = (event: TrackingEvent, data?: any) => {
    TagManager.dataLayer({
      dataLayer: {
        page: document.title,
        event,
        userId: session.account.id,
        vendorId: session.appConfig.vendor,
        workspaceId: workspace.id,
        eventProps: data,
      },
    });
  };

  return {
    trackEvent,
  };
};

export const useSegmentTrackEvent = () => {
  const session = useSessionContext();
  const userId = session.account?.id ?? '';
  const email = session.account?.email ?? '';
  const timestamp = Date.now();
  const trackEvent = async (event: TrackingEvent, data?: any) => {
    if (!window.analytics) {
      console.info('Analytics not initialized. Event not tracked.');
      return;
    }

    window.analytics.track(event, {
      email,
      timestamp,
      userId,
      ...data,
    });
  };

  return {
    trackEvent,
  };
};

export enum TrackingEvent {
  ADD_MEMBERS_BTN = 'add_members_btn',
  ALL_JOB_LISTINGS = 'all_job_listings',
  ALL_PENDING_INVITES_BTN = 'all_pending_invites_btn',
  ASSESSMENT_QUESTION_CANCEL_BTN = 'assessment_question_cancel_btn',
  ASSESSMENT_QUESTION_DELETE_ANSWER_BTN = 'assessment_question_delete_answer_btn',
  ASSESSMENT_QUESTION_EDIT_ANSWER_BTN = 'assessment_question_edit_btn',
  ASSESSMENT_QUESTION_SAVE_ANSWER_BTN = 'assessment_question_save_btn',
  CANDIDATE_APPLICATION_SUBMISSION = 'candidate_application_submission',
  CANDIDATE_SCORECARD_SHARE_BTN = 'candidate_scorecard_share_btn',
  CONTACT_CANDIDATE_BTN = 'contact_candidate_btn',
  CONTINUE_EDITING_JOB_LISTING = 'continue_editing_job_listing',
  CREATE_NEW_JOB_BTN = 'create_new_job_btn',
  DELETE_WORKSPACE_INVITE = 'delete_workspace_invite',
  DISCARD_JOB_LISTING_CHANGES = 'discard_job_listing_changes',
  EDIT_JOB_LISTING = 'edit_job_listing',
  PUBLISH_JOB_LISTING = 'publish_job_listing',
  REMOVE_WORKSPACE_MEMBER = 'remove_workspace_member',
  SAVE_JOB_LISTING = 'save_job_listing',
  SEND_WORKSPACE_INVITE = 'send_workspace_invite',
  UPLOAD_ASSESSMENT_ANSWER_AS_AUDIO = 'upload_assessment_answer_as_audio',
  UPLOAD_ASSESSMENT_ANSWER_AS_TEXT = 'upload_assessment_answer_as_text',
  UPLOAD_ASSESSMENT_ANSWER_AS_TRANSCRIBED_TEXT = 'upload_assessment_answer_as_transcribed_text',
  UPLOAD_AUDIO_FILE = 'upload_audio_file',
  UPLOAD_AUDIO_SAVE_BTN = 'upload_audio_save_btn',
  WORKSPACE_INVITE_ROLE_CHANGE = 'workspace_invite_role_change',
}
