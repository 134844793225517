import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

export default function DialogConfirmDelete(props: {
  open: boolean;
  onClose: VoidFunction;
  onConfirmDelete: VoidFunction;
  loadingDelete: boolean;
}) {
  const { open, onClose, onConfirmDelete, loadingDelete } = props;

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <DialogContent sx={{ py: 3 }}>
        <Box display="flex" flexDirection="column" rowGap={2}>
          <Typography variant="h3">
            Delete Answer
          </Typography>

          <Typography variant="body1">
            Are you sure you want to delete your answer?
          </Typography>

          <Box display="flex" flexDirection="row" justifyContent="flex-end" columnGap={1}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <LoadingButton
              color="error"
              variant="contained"
              onClick={onConfirmDelete}
              loading={loadingDelete}
            >
              Delete
            </LoadingButton>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
