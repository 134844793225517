import { AppConfig, Session, UserAccount } from '@piccadilly-cloud/connect-platform-services';

export const initSession = (
  account: UserAccount,
  appConfig: AppConfig,
): Session => ({
  account,
  appConfig,
  workspaces: [],
  sessionType: 'UNAUTHENTICATED',
  token: '',
});
