import { useSessionContext } from 'src/contexts';

export const useDemoManager = () => {
  const session = useSessionContext();

  const hasDemoExpired = false;
  const isDemoUser = session.account.accountRole === 'RECRUITER'
    || session.account.accountRole === 'WORKSPACE_ADMIN';

  return {
    hasDemoExpired,
    isDemoUser,
  };
};
