const palette = {
  question1: {
    darker: '#2c4a35',
    dark: '#4e825c',
    main: '#6FB984',
    light: '#b7dcc2',
    lighter: '#e2f1e6',
    lightest: '#f3f9f5',
  },
  question2: {
    darker: '#493205',
    dark: '#92640b',
    main: '#F3A712',
    light: '#f9d389',
    lighter: '#fef6e7',
    lightest: '#fffcf8',
  },
  question3: {
    darker: '#38264e',
    dark: '#614389',
    main: '#8B5FC3',
    light: '#b99fdb',
    lighter: '#f3eff9',
    lightest: '#faf9fd',
  },
  question4: {
    darker: '#144044',
    dark: '#236f78',
    main: '#329FAB',
    light: '#84c5cd',
    lighter: '#ebf5f7',
    lightest: '#f7fbfc',
  },
  question5: {
    darker: '#522e05',
    dark: '#8f5008',
    main: '#CC720C',
    light: '#e6b986',
    lighter: '#faf1e7',
    lightest: '#fdf9f5',
  },
  question6: {
    darker: '#231d16',
    dark: '#473a2c',
    main: '#766149',
    light: '#bbb0a4',
    lighter: '#f1efed',
    lightest: '#f9f9f8',
  },
  question7: {
    darker: '#163140',
    dark: '#337194',
    main: '#49A2D4',
    light: '#92c7e5',
    lighter: '#edf6fb',
    lightest: '#f8fbfd',
  },
  question8: {
    darker: '#491a36',
    dark: '#802e5f',
    main: '#B74188',
    light: '#d48db8',
    lighter: '#f8ecf3',
    lightest: '#fcf7fa',
  },
};

const getColor = (index: number): { main: string; light: string; lighter: string; lightest: string } => {
  switch (index % Object.keys(palette).length) {
    case 0:
      return palette.question1;
    case 1:
      return palette.question2;
    case 2:
      return palette.question3;
    case 3:
      return palette.question4;
    case 4:
      return palette.question5;
    case 5:
      return palette.question6;
    case 6:
      return palette.question7;
    case 7:
      return palette.question8;
    default:
  }
  return palette.question1;
};

const teqScorePalette: { [key: number]: string } = {
  1: '#c31b0c',
  2: '#db610f',
  3: '#f3a712',
  4: '#a6bc2b',
  5: '#177a1b',
};

const teqScoreRot: { [key: number]: string } = {
  1: '-117deg',
  2: '-70deg',
  3: '-23deg',
  4: '24deg',
  5: '70deg',
};

const teqDialRot: { [key: number]: string } = {
  1: '-96deg',
  2: '-48deg',
  3: '0deg',
  4: '48deg',
  5: '96deg',
};

const pcdlPalette = {
  palette,
  getColor,
  teqScorePalette,
  teqDialRot,
  teqScoreRot,
};

export default pcdlPalette;
