import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

export default function ControlRow(props: {
  onBack?: VoidFunction;

  onNext: VoidFunction;
  onNextLabel?: string;

  loading: boolean;

  isEditing?: boolean;
}) {
  const { onBack, onNext, onNextLabel, loading, isEditing } = props;

  const controls = () => (
    <>
      {!isEditing && (
        <>
          {onBack && (
            <Button
              color="primary"
              variant="outlined"
              onClick={onBack}
            >
              Back
            </Button>
          )}
          {!onBack && <Box />}
        </>
      )}
      {isEditing && <Box />}
      <LoadingButton
        variant="contained"
        color="primary"
        loading={loading}
        onClick={onNext}
      >
        {!isEditing && (
          <>
            {onNextLabel || 'Next'}
          </>
        )}
        {isEditing && (
          <>
            Back to review
          </>
        )}
      </LoadingButton>
    </>
  );

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      columnGap={1}
      sx={{ p: 0, pb: 3 }}
    >
      {controls()}
    </Box>
  );
}
