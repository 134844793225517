import {
  ApplicationAssessmentResponse,
  ApplicationAssessmentResponseData,
  AssessmentQuestion,
  AssessmentResponseType,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import { Box } from '@mui/material';

import { useEffect, useState } from 'react';

import { useApplicationFlowContext } from 'src/contexts';
import { useBoolean } from 'src/hooks';

import DialogConfirmAnswerChange from './dialog-confirm-answer-change';
import AnswerRecordAudio from './response-types/record-audio';
import AnswerText from './response-types/text';
import AnswerUploadAudio from './response-types/upload-audio';
import AnswerUploadedAudio from './response-types/uploaded-audio';
import assessmentUtil from './util';

export default function DialogAnswerQuestion(props: {
  onSave: (answeredQuestion: ApplicationAssessmentResponse) => Promise<void>;
  onBack: VoidFunction;
  question: AssessmentQuestion & { main: string; lighter: string } & { currentAnswer?: ApplicationAssessmentResponse };
  loading: boolean;
  loadingDelete: boolean;
  onDelete: (id: string) => Promise<void>;
  jobProfile: UniformProfileDefinition;
  responseType: AssessmentResponseType;
}) {
  const {
    onSave,
    onBack,
    question,
    loading,
    loadingDelete,
    onDelete,
    jobProfile,
    responseType,
  } = props;

  const [currentQuestion, setCurrentQuestion] = useState<AssessmentQuestion & {
    main: string;
    lighter: string;
  } & { currentAnswer?: ApplicationAssessmentResponse }>(question);
  useEffect(() => {
    setCurrentQuestion(question);
  }, [question]);

  const activeChange = useBoolean();
  const { isPreview } = useApplicationFlowContext();
  const onCloseActiveChange = useBoolean();
  const [activeChangeDialogOpen, setActiveChangeDialogOpen] = useState('');
  const [answerType, setAnswerType] = useState(assessmentUtil.getInitialAnswerType(currentQuestion, responseType));

  useEffect(() => {
    activeChange.onFalse();
    setAnswerType(assessmentUtil.getInitialAnswerType(currentQuestion, responseType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion.id, currentQuestion]);

  const onSaveTextInput = async (
    textInput: string,
    newResponseType: AssessmentResponseType,
  ) => onSave(
    assessmentUtil.onSaveTextInput(currentQuestion, textInput, newResponseType),
  );

  const onSaveUploadFile = (data: ApplicationAssessmentResponseData) => {
    onSave(assessmentUtil.onSaveUploadFile(currentQuestion, data));
  };

  return (
    <>
      <Box sx={{ pt: 0.5 }}>
        {answerType === 'text' && (
          <AnswerText
            loading={loading}
            loadingDelete={loadingDelete}
            onSave={onSaveTextInput}
            onClose={onBack}
            currentAnswer={currentQuestion.currentAnswer}
            onActive={() => activeChange.value ? activeChange.onFalse() : activeChange.onTrue()}
            isActive={activeChange.value}
            question={currentQuestion}
          />
        )}
        {answerType === 'record' && (
          <AnswerRecordAudio
            loading={loading}
            loadingDelete={loadingDelete}
            onSave={onSaveUploadFile}
            onDelete={onDelete}
            onClose={onBack}
            currentAnswer={currentQuestion.currentAnswer}
            minLengthSeconds={jobProfile.teqAssessment.defaultMinAudioLength || 120}
            onActive={() => activeChange.onTrue()}
            isActive={activeChange.value}
            answerType={answerType}
            question={currentQuestion}
            activeChange={activeChange}
            setActiveChangeDialogOpen={setActiveChangeDialogOpen}
            setAnswerType={setAnswerType}
            jobProfile={jobProfile}
          />
        )}
        {answerType === 'upload' && (
          <AnswerUploadAudio
            loading={loading}
            loadingDelete={loadingDelete}
            onSave={onSaveUploadFile}
            onDelete={onDelete}
            onClose={onBack}
            currentAnswer={currentQuestion.currentAnswer}
            minLengthSeconds={jobProfile.teqAssessment.defaultMinAudioLength || 120}
            answerType={answerType}
            question={currentQuestion}
            activeChange={activeChange}
            setActiveChangeDialogOpen={setActiveChangeDialogOpen}
            setAnswerType={setAnswerType}
            jobProfile={jobProfile}
          />
        )}
        {answerType === 'uploaded' && (
          <AnswerUploadedAudio
            loading={loading}
            loadingDelete={loadingDelete}
            onSave={onSaveUploadFile}
            onDelete={onDelete}
            onClose={onBack}
            currentAnswer={currentQuestion.currentAnswer}
            minLengthSeconds={jobProfile.teqAssessment.defaultMinAudioLength || 120}
            answerType={answerType}
            question={currentQuestion}
            activeChange={activeChange}
            setActiveChangeDialogOpen={setActiveChangeDialogOpen}
            setAnswerType={setAnswerType}
            jobProfile={jobProfile}
          />
        )}
      </Box>

      <DialogConfirmAnswerChange
        open={activeChangeDialogOpen !== '' && !isPreview}
        onClose={() => {
          setActiveChangeDialogOpen('');
        }}
        onConfirm={() => {
          setAnswerType(activeChangeDialogOpen); setActiveChangeDialogOpen(''); activeChange.onFalse();
        }}
      />
      <DialogConfirmAnswerChange
        open={onCloseActiveChange.value && !isPreview}
        onClose={() => {
          onCloseActiveChange.onFalse();
        }}
        onConfirm={() => { }}
      />
    </>
  );
}
