import { Box, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import FormProvider from 'src/components/hook-form';
import Image from 'src/components/image/image';
import { useApplicationFlowContext } from 'src/contexts';
import BoolBox from 'src/views/listing/listing-editor/steps/partials/bool-box';

import ControlRow from '../control-row';
import QuestionCard from '../partials/question-card';
import formDefaults from '../pcdl-wiz/form-defaults';
import formSchemas from '../pcdl-wiz/form-schemas';
import { TabEligibilityForm } from '../pcdl-wiz/form-types';
import formValues from '../pcdl-wiz/form-values';

export interface TabEligibilityProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabEligibility(props: TabEligibilityProps) {
  const {
    loading,
    onBack,
    onNext,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();

  const resolver = !applicationFlowContext.isPreview
    ? yupResolver(formSchemas.tabEligibility)
    : undefined;

  const methods = useForm<TabEligibilityForm>({
    resolver,
    defaultValues: applicationFlowContext.jobProfile && applicationFlowContext.application?.profile
      ? formDefaults.tabEligibility(
        applicationFlowContext.application.profile,
        applicationFlowContext.jobProfile,
      ) : undefined,
  });

  const {
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const w = watch();

  const onSubmit = handleSubmit(
    (data: TabEligibilityForm) => {
      if (!applicationFlowContext.application?.profile) {
        throw new Error('Failed to update eligibility');
      }

      const updatedCandidateProfile = formValues.onNextEligibility(
        applicationFlowContext.application.profile,
        data,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileEligibility']({
        eligibility: updatedCandidateProfile.eligibility,
      });
      onNext();
    },
    (i) => console.error(i),
  );

  if (!applicationFlowContext.jobProfile) {
    return (<>Something went wrong! Please refresh and try again.</>);
  }

  return (
    <FormProvider methods={methods}>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Box display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 3 }}>
          <Image
            height="120px"
            src="/assets/illustrations/eligibility-illustration.svg"
          />
        </Box>

        <QuestionCard>
          <Box
            gap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
          >

            <Box display="flex" flexDirection="column">
              <Typography variant="h5">
                Authorized to work in the US?
              </Typography>
              <BoolBox
                value={w.authorizedToWork_12}
                onChange={(next: boolean) => {
                  setValue('authorizedToWork_12', next);
                }}
              />
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography variant="h5">
                At least 18 years of age?
              </Typography>
              <BoolBox
                value={w.listingAgeRequirement_13}
                onChange={(next: boolean) => {
                  setValue('listingAgeRequirement_13', next);
                }}
              />
            </Box>

          </Box>
        </QuestionCard>
        {applicationFlowContext.jobProfile.eligibility.fields.length > 0 && (
          <QuestionCard>
            <Box
              gap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >

              {applicationFlowContext.jobProfile.eligibility.fields.map((i) => (
                <Box key={`attribute-${i.id}`} display="flex" flexDirection="column">
                  <Typography variant="h5">
                    {i.label}
                  </Typography>
                  <BoolBox
                    value={w.profileAttributes[i.id]}
                    onChange={(next: boolean) => {
                      setValue(`profileAttributes.${i.id}`, next);
                    }}
                  />
                </Box>
              ))}

            </Box>
          </QuestionCard>
        )}

        <ControlRow
          onBack={onBack}
          onNext={onSubmit}
          loading={loading}
          isEditing={applicationFlowContext.isEditing}
        />
      </Box>

    </FormProvider>
  );
}
