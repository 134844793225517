import {
  Box,
  Card,
  Typography,
  alpha,
  useTheme,
} from '@mui/material';

export default function QuestionCard(props: {
  title?: string;
  title2?: string;
  subTitle?: string;
  children: JSX.Element;
}) {
  const { title, title2, subTitle, children } = props;

  const theme = useTheme();

  return (
    <Card
      sx={{
        boxShadow: `0px 12px 24px -4px ${alpha('#919eab', 0.12)}, 0px 0px 2px 0px ${alpha('#919eab', 0.2)}`,
        borderRadius: 3,
        border: 0,
        p: 3,
      }}
    >
      <Box display="flex" flexDirection="column" rowGap={1}>
        {(title || title2 || subTitle) && (
          <Box display="flex" flexDirection="column">
            {title && (
              <Typography variant="h5">
                {title}
              </Typography>
            )}
            {title2 && (
              <Typography variant="body1">
                {title2}
              </Typography>
            )}
            {subTitle && (
              <Typography variant="body2" color={theme.palette.grey[700]}>
                {subTitle}
              </Typography>
            )}
          </Box>
        )}
        {children}
      </Box>
    </Card>
  );
}
