import { EmploymentType, PositionType } from '@piccadilly-cloud/connect-platform-services';

import { Box, FormHelperText, Grid, Typography } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';

import FormProvider from 'src/components/hook-form';
import Image from 'src/components/image/image';
import { useApplicationFlowContext } from 'src/contexts';
import TwoBox from 'src/views/listing/listing-editor/steps/partials/two-box';

import ControlRow from '../control-row';
import FormButton from '../form-controls/form-button';
import FormSlider from '../form-controls/form-slider';
import QuestionCard from '../partials/question-card';
import formDefaults from '../pcdl-wiz/form-defaults';
import formSchemas from '../pcdl-wiz/form-schemas';
import { TabAvailabilityForm } from '../pcdl-wiz/form-types';
import formValues from '../pcdl-wiz/form-values';

export interface TabAvailabilityProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabAvailability(props: TabAvailabilityProps) {
  const {
    loading,
    onBack,
    onNext,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();

  const methods = useForm<TabAvailabilityForm>({
    resolver: !applicationFlowContext.isPreview && applicationFlowContext.jobProfile
      ? yupResolver(formSchemas.getTabAvailability(applicationFlowContext.jobProfile))
      : undefined,
    defaultValues: applicationFlowContext.jobProfile && applicationFlowContext.application?.profile
      ? formDefaults.tabAvailability(
        applicationFlowContext.application.profile,
        applicationFlowContext.jobProfile,
      ) : undefined,
  });

  const {
    setValue,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(
    (data: TabAvailabilityForm) => {
      if (!applicationFlowContext.application?.profile) {
        throw new Error('Failed to update availability');
      }

      const updatedCandidateProfile = formValues.onNextAvailability(
        applicationFlowContext.application.profile,
        data,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileAvailability']({
        availability: updatedCandidateProfile.availability,
      });
      onNext();
    },
    (i) => console.error(i),
  );

  return (
    <FormProvider methods={methods}>

      <Box display="flex" flexDirection="column" rowGap={2}>

        <Box display="flex" flexDirection="row" justifyContent="center" sx={{ pt: 3 }}>
          <Image
            height="120px"
            src="/assets/illustrations/assesment-permfulltime.svg"
          />
        </Box>

        <QuestionCard
          title="What type of position are you looking for?"
          subTitle="Select all that apply"
        >
          <Box>

            <Box display="flex" flexDirection="column">

              <Typography variant="body1" fontWeight="bold" sx={{ pb: 0.5 }}>
                Status
              </Typography>

              <Box display="flex" flexDirection="row" columnGap={1}>

                <FormButton
                  selected={values.employmentType.includes('FULL_TIME')}
                  onClick={() => {
                    const v = getValues();
                    const nextValues: EmploymentType[] = v.employmentType.includes('FULL_TIME')
                      ? v.employmentType.filter((i) => i !== 'FULL_TIME')
                      : [...v.employmentType, 'FULL_TIME'];
                    setValue('employmentType', nextValues);
                  }}
                >
                  Full Time
                </FormButton>
                <FormButton
                  selected={values.employmentType.includes('PART_TIME')}
                  onClick={() => {
                    const v = getValues();
                    const nextValues: EmploymentType[] = v.employmentType.includes('PART_TIME')
                      ? v.employmentType.filter((i) => i !== 'PART_TIME')
                      : [...v.employmentType, 'PART_TIME'];
                    setValue('employmentType', nextValues);
                  }}
                >
                  Part Time
                </FormButton>

              </Box>

              <FormHelperText error sx={{ mx: 0 }}>
                {errors?.employmentType?.message ? `${errors?.employmentType?.message}` : ''}
              </FormHelperText>

            </Box>
            <Box display="flex" flexDirection="column" sx={{ pt: 1 }}>

              <Typography variant="body1" fontWeight="bold" sx={{ pb: 0.5 }}>
                Duration
              </Typography>

              <Box display="flex" flexDirection="row" columnGap={1}>

                <FormButton
                  selected={values.positionType.includes('PERMANENT')}
                  onClick={() => {
                    const v = getValues();
                    const nextValues: PositionType[] = v.positionType.includes('PERMANENT')
                      ? v.positionType.filter((i) => i !== 'PERMANENT')
                      : [...v.positionType, 'PERMANENT'];
                    setValue('positionType', nextValues);
                  }}
                >
                  Permanent
                </FormButton>
                <FormButton
                  selected={values.positionType.includes('TEMPORARY')}
                  onClick={() => {
                    const v = getValues();
                    const nextValues: PositionType[] = v.positionType.includes('TEMPORARY')
                      ? v.positionType.filter((i) => i !== 'TEMPORARY')
                      : [...v.positionType, 'TEMPORARY'];
                    setValue('positionType', nextValues);
                  }}
                >
                  Contract
                </FormButton>

              </Box>

              <FormHelperText error sx={{ mx: 0 }}>
                {errors?.positionType?.message ? `${errors?.positionType?.message}` : ''}
              </FormHelperText>

            </Box>
          </Box>
        </QuestionCard>

        {applicationFlowContext.jobProfile?.availability.fields.length !== 0 && (
          <QuestionCard title="How willing are you to do the following:">
            <Box display="flex" flexDirection="column" rowGap={2}>
              {applicationFlowContext.jobProfile?.availability.fields.map((field) => {
                if (field.type === 'MATRIX') {
                  return (
                    <Box key={`matrix-availability-${field.id}`} display="flex" flexDirection="column">
                      <Typography variant="body1" fontWeight="bold">
                        {field.label}
                      </Typography>
                      <Box display="flex" flexDirection="row" justifyContent="center">
                        <FormSlider
                          name={`profileAttributes.${field.id}`}
                          step={1}
                          min={1}
                          max={5}
                          marks={[{ value: 1, label: 'Less likely' }, { value: 5, label: 'More likely' }]}
                          getAriaValueText={(value) => `${value}`}
                          valueLabelFormat={(value) => `${value}`}
                          sx={{ alignSelf: 'center', width: 'calc(100% - 25px - 3rem)' }}
                        />
                      </Box>

                    </Box>

                  );
                }
                if (field.type === 'CLAMPED') {
                  return (
                    <React.Fragment key={`attribute-${field.id}`}>
                      <Grid item xs={12}>
                        <TwoBox
                          title={field.candidateLabel || ''}
                          description={field.candidateDescription || ''}
                          value={values.profileAttributes[field.id]}
                          onChange={(next: number) => {
                            setValue(`profileAttributes.${field.id}`, next);
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  );
                }
                return (
                  <>
                    !!Unsupported -
                    {field.id}
                  </>
                );
              })}

            </Box>
          </QuestionCard>
        )}

        <ControlRow
          onBack={onBack}
          onNext={onSubmit}
          loading={loading}
          isEditing={applicationFlowContext.isEditing}
        />

      </Box>
    </FormProvider>
  );
}
