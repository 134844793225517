import {
  Application,
  PublicListingForApplication,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography } from '@mui/material';

import { useState } from 'react';

import { useResponsive } from 'src/hooks';

import ViewBox from './partials/view-box';
import ApplicationSubmitted from './tab-submitted';

export default function NotAvailable(props: {
  application: Application;
  jobProfile: UniformProfileDefinition;
  jobListing: PublicListingForApplication;
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { application, jobProfile, jobListing } = props;

  const isDesktop = useResponsive('up', 'md');
  const [submitted] = useState(application.submitted);

  if (submitted) {
    return (
      <ApplicationSubmitted application={application} jobListing={jobListing} />
    );
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        pb={isDesktop ? 4 : 12}
        sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '95%',
            lg: '90%',
            xl: '65%',
          },
        }}
      >
        <ViewBox
          application={application}
          jobProfile={jobProfile}
          jobListing={jobListing}
          progress={-1}
        >
          <Box display="flex" flexDirection="column" rowGap={2} sx={{ p: 3 }}>
            <Typography>
              This assessment is not available. Please reach out to your recruiter with questions.
            </Typography>
          </Box>
        </ViewBox>
      </Box>
    </Box>
  );
}
