import { alpha } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import Logo from 'src/components/logo';
import { useResponsive } from 'src/hooks';
import { bgBlur } from 'src/utils/cssStyles';

import { HEADER } from '../config-layout';
import { HeaderShadow } from '../internal/_common';

export default function Header() {
  const theme = useTheme();

  const isNavHorizontal = true;

  const lgUp = useResponsive('up', 'lg');

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} disabledLink useFullLogo={lgUp} />}

      {!lgUp && <Logo sx={{ ml: 1 }} disabledLink useFullLogo={lgUp} />}
    </>
  );

  return (
    <AppBar
      sx={{
        position: 'relative',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: alpha('#ffffff', 0.8),
            height: HEADER.H_DESKTOP_OFFSET,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
      {isNavHorizontal && (
        <HeaderShadow />
      )}
    </AppBar>
  );
}
