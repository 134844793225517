import services, {
  ApplicationAssessmentResponse,
  ApplicationAssessmentResponseData,
  AssessmentQuestion,
  AssessmentResponseType,
  SupportedAudioEncoding,
  UniformProfileDefinition,
} from '@piccadilly-cloud/connect-platform-services';

import { Box, Button, Typography, useTheme } from '@mui/material';

import { useEffect, useState } from 'react';

import Iconify from 'src/components/iconify';
import { LoadingInline } from 'src/components/loading-screen';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { UseBoolType, useBoolean } from 'src/hooks';

import AnswerControlBox from '../answer-control-box';
import DialogConfirmDelete from '../dialog-confirm-delete';
import QuestionLayout from '../question-layout';

export default function AnswerUploadAudio(props: {
  onSave: (data: ApplicationAssessmentResponseData) => void;
  loading: boolean;
  loadingDelete: boolean;
  onDelete: (id: string) => Promise<void>;
  onClose: VoidFunction;
  currentAnswer?: ApplicationAssessmentResponse;
  minLengthSeconds: number;

  answerType: string;
  question: AssessmentQuestion & {
    main: string;
    lighter: string;
  } & { currentAnswer?: ApplicationAssessmentResponse | undefined };
  activeChange: UseBoolType;
  setActiveChangeDialogOpen: (next: string) => void;
  setAnswerType: (next: string) => void;
  jobProfile: UniformProfileDefinition;
}) {
  const theme = useTheme();
  const session = useSessionContext();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    onSave,
    currentAnswer,
    loading,
    loadingDelete,
    onDelete,
    onClose,
    question,
  } = props;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingDownloadUrl, setLoadingDownloadUrl] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState('');

  const deleteDialogConfirm = useBoolean();

  const [currentQuestion, setCurrentQuestion] = useState(question);
  useEffect(() => {
    setCurrentQuestion(question);
    setDownloadUrl('');
  }, [question]);

  useEffect(() => {
    if (currentQuestion.currentAnswer?.documentPath !== '') {
      setLoadingDownloadUrl(true);
      services.edge.record.privateDocument.downloadFromUrl({
        rawUrl: currentQuestion.currentAnswer?.documentPath || '',
      })({ token: session.token })
        .then((res) => {
          setDownloadUrl(res.downloadUrl);
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          setLoadingDownloadUrl(false);
        });
    }
  }, [session.token, currentQuestion]);

  const onSubmit = () => {
    const newData: ApplicationAssessmentResponseData = {
      jobProfileQuestionId: '',
      type: AssessmentResponseType.AUDIO,
      recordedInBrowser: false,
      processed: false,
      rawText: '',
      encoding: SupportedAudioEncoding.MP3,
      language: 'en-US',
      documentId: currentQuestion.currentAnswer?.documentId || '',
      documentPath: currentQuestion.currentAnswer?.documentPath || '',
      sampleRateHertz: 16000,
    };
    onSave(newData);
  };

  return (
    <>
      <QuestionLayout
        currentQuestion={currentQuestion}
        controlBox={(
          <AnswerControlBox
            loading={loading}
            onClose={onClose}
            onClick={onSubmit}
            saveButtonDisabled={false}
            isActive={false}
          />
        )}
      >
        <Box sx={{ pt: 2 }}>
          {currentQuestion.currentAnswer?.documentPath && downloadUrl === '' && (
            <Box sx={{ width: '100%', pt: 2, pb: 3 }} display="flex" flexDirection="row" justifyContent="center">
              <LoadingInline />
            </Box>
          )}
          {currentQuestion.currentAnswer?.documentPath && downloadUrl !== '' && (
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box display="flex" flexDirection="row" alignItems="center" sx={{ width: '100%', py: 1 }}>
                <Box sx={{ width: '100%' }}>
                  <audio
                    src={downloadUrl}
                    preload="auto"
                    controls
                    style={{
                      display: 'block',
                      width: '100%',
                      borderRadius: 3,
                    }}
                  >
                    <track kind="captions" />
                  </audio>
                </Box>
                <Box sx={{ pl: 1 }}>
                  <Button
                    color="primary"
                    variant="text"
                    onClick={() => {
                      deleteDialogConfirm.onTrue();
                    }}
                    startIcon={<Iconify icon="fa6-regular:trash-can" />}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" justifyContent="flex-end" sx={{ pb: 0, pt: 0, pr: 1 }}>
                <Box
                  component="a"
                  target="_blank"
                  href={downloadUrl}
                  download
                  display="flex"
                  flexDirection="row"
                  columnGap={0.5}
                  alignItems="flex-end"
                  sx={{
                    color: theme.palette.primary.main,
                    textDecoration: 'underline',
                    '&:hover': {
                      cursor: 'pointer',
                      fontWeight: 500,
                      color: theme.palette.primary.darker,
                    },
                    '&:focus': {
                      fontWeight: 800,
                      color: theme.palette.primary.darker,
                      textDecoration: 'underline',
                    },

                  }}
                >
                  <Iconify icon="mdi:tray-arrow-down" width="0.75rem" />
                  <Typography
                    variant="caption"
                  >
                    Download
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
        </Box>

      </QuestionLayout>
      <DialogConfirmDelete
        open={deleteDialogConfirm.value}
        onClose={() => {
          deleteDialogConfirm.onFalse();
        }}
        onConfirmDelete={() => {
          onDelete(currentAnswer?.id || '').then(() => {
            deleteDialogConfirm.onFalse();
          });
        }}
        loadingDelete={loadingDelete}
      />
    </>
  );
}
