import { Button, useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { m } from 'framer-motion';
import { useNavigate } from 'react-router';

import { varHover } from 'src/components/animate';
import { CustomPopover, usePopover } from 'src/components/custom-popover/v2';
import Iconify from 'src/components/iconify';
import Image from 'src/components/image/image';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { paths } from 'src/routes/paths';

export default function AccountPopover() {
  const navigate = useNavigate();

  const theme = useTheme();

  const session = useSessionContext();

  const popover = usePopover();

  return (
    <>
      <Button
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          borderRadius: 12,
          height: 36,
          background: theme.palette.primary.lighter,
        }}
      >
        <Typography
          variant="button"
          sx={{ px: 1, color: theme.palette.primary.dark }}
        >
          {session.account.personalInfo.firstName[0]}
          {session.account.personalInfo.lastName[0]}
        </Typography>
        <Avatar
          sx={{
            width: 28,
            height: 28,
            padding: 0,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Image
            src="/assets/icons/piccadilly-tulips.svg"
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
            alt={`${session.account.personalInfo.firstName} ${session.account.personalInfo.lastName}`}
          />
        </Avatar>
      </Button>

      <CustomPopover
        open={popover.open}
        anchorEl={popover.anchorEl}
        onClose={popover.onClose}
        slotProps={{
          paper: { sx: { p: 0, width: 250 } },
          arrow: { offset: 20 },
        }}
      >
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {session.account.personalInfo.firstName}{' '}
            {session.account.personalInfo.lastName}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {session.account.email}
          </Typography>
        </Box>

        <Divider />

        <Stack sx={{ p: 1 }}>
          <MenuItem
            onClick={() => {
              navigate(paths.profile);
            }}
          >
            <Iconify icon="mdi:account" color={theme.palette.grey[700]} />
            My profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              session.logout();
            }}
          >
            <Iconify icon="mdi:logout" color={theme.palette.grey[700]} />
            Sign out
          </MenuItem>
        </Stack>
      </CustomPopover>
    </>
  );
}
