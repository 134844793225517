import { OnboardingDataResponse, PostRegistrationOnboardingAction } from '@piccadilly-cloud/connect-platform-services';

import { paths } from 'src/routes/paths';

import { OnboardingFlowConfiguration, OnboardingFlowType, OnboardingStep, WelcomeDialogConfig } from './model';

const createOnboardingStep = (
  event: PostRegistrationOnboardingAction,
  options: Partial<OnboardingStep> = {},
): OnboardingStep => ({
  label: '',
  description: '',
  useExpand: false,
  isComplete: false,
  button: undefined,
  event,
  ...options,
});

const getOnboardingFlowSteps = (workspaceIndex: number, flowType?: OnboardingFlowType):
  Record<PostRegistrationOnboardingAction, OnboardingStep> => {
  const isPurchaseFlow = flowType === OnboardingFlowType.PURCHASE;

  return {
    [PostRegistrationOnboardingAction.CreateWorkspace]: createOnboardingStep(
      PostRegistrationOnboardingAction.CreateWorkspace, {
      label: 'Create your workspace',
      isComplete: true,
    }),
    [PostRegistrationOnboardingAction.CreateJobProfile]: createOnboardingStep(
      PostRegistrationOnboardingAction.CreateJobProfile, {
      label: 'Build a job profile',
      description: 'Next, start with the blueprint that fits your open position and define your ideal candidate.',
      useExpand: true,
      button: {
        label: 'Find my job',
        href: paths.recruiter.workspace.jobs.profiles(workspaceIndex),
      },
    }),
    [PostRegistrationOnboardingAction.SelectPricingPlan]: createOnboardingStep(
      PostRegistrationOnboardingAction.SelectPricingPlan, {
      label: 'Select a plan',
      description: 'A subscription is required to invite candidates.',
      useExpand: true,
    }),
    [PostRegistrationOnboardingAction.ShareAssessment]: createOnboardingStep(
      PostRegistrationOnboardingAction.ShareAssessment, {
      label: isPurchaseFlow ? 'Invite candidate' : 'Share assessment',
      description: isPurchaseFlow
        ? 'Use your free assessment to invite a candidate and view their scorecard.'
        : 'Use your credits to start inviting candidates.',
      useExpand: true,
    }),
  };
};

export const getOnboardingFlowConfig = (index: number, flowType?: OnboardingFlowType): OnboardingFlowConfiguration => {
  const steps = getOnboardingFlowSteps(index, flowType);

  return {
    [OnboardingFlowType.PURCHASE]: [
      steps[PostRegistrationOnboardingAction.CreateWorkspace],
      steps[PostRegistrationOnboardingAction.CreateJobProfile],
      steps[PostRegistrationOnboardingAction.ShareAssessment],
    ],
    [OnboardingFlowType.PREPURCHASE]: [
      steps[PostRegistrationOnboardingAction.CreateWorkspace],
      steps[PostRegistrationOnboardingAction.CreateJobProfile],
      steps[PostRegistrationOnboardingAction.ShareAssessment],
    ],
  };
};

export const defaultWelcomeDialogConfig: WelcomeDialogConfig = {
  header: "You're in! We're excited you are here.",
  body: `Begin by creating a job profile. You'll be able to customize requirements 
  and define critical soft skills, invite candidates, view completed assessment 
  scorecards in your dashboard, and more.`,
  ctaLine: "Ready to find your Best Fit candidates? Let's begin!",
  buttonText: 'Start',
};
export const defaultOnboardingData: OnboardingDataResponse = {
  id: '',
  vendorId: '',
  preRegistration: {
    q1: '',
    q2: '',
    q3: '',
  },
  postRegistration: {
    completedActions: [],
    isComplete: false,
  },
};

export const defaultOnboardingStep = getOnboardingFlowSteps(0)[PostRegistrationOnboardingAction.CreateWorkspace];
