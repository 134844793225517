import services, { ApplicantSurvey, Application, PublicListing } from '@piccadilly-cloud/connect-platform-services';

import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';

import Iconify from 'src/components/iconify';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useResponsive, useToast } from 'src/hooks';

import ViewBox from './partials/view-box';

interface ApplicationSubmittedProps {
  application: Application;
  jobListing?: PublicListing;
}

export default function ApplicationSubmitted(props: ApplicationSubmittedProps) {
  const { application, jobListing } = props;
  const isDesktop = useResponsive('up', 'md');

  const session = useSessionContext();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [receivedFeedback, setReceivedFeedback] = useState(false);

  useEffect(() => {
    setLoading(true);
    services.edge.record.applicantSurvey.getSurvey(application.accountId)({ token: session.token })
      .then((resp) => {
        if (resp.accountId === '') {
          setReceivedFeedback(false);
        } else {
          setReceivedFeedback(true);
        }
      }).catch((err) => {
        console.error(err);
        setReceivedFeedback(false);
      }).finally(() => setLoading(false));
  }, [application.accountId, session.token]);

  const onSubmitFeedback = (index: number) => {
    setLoading(true);
    const applicantSurvey: ApplicantSurvey = {
      id: '',
      vendorId: session.appConfig.vendor,
      accountId: session.account.email,
      createdAt: new Date(),
      feedbackRating: index,
    };
    services.edge.record.applicantSurvey.create(applicantSurvey)({ token: session.token })
      .then(() => {
        setReceivedFeedback(true);
      })
      .catch((err) => {
        console.error(err);
      }).finally(() => {
        setLoading(false);
        setReceivedFeedback(true);
        toast.success('Your feedback has been received');
      });
  };

  return (
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        pb={isDesktop ? 4 : 12}
        sx={{
          width: {
            xs: '100%',
            sm: '100%',
            md: '95%',
            lg: '90%',
            xl: '65%',
          },
        }}
      >
        <ViewBox
          application={application}
          jobListingPublic={jobListing}
          progress={-1}
        >
          <Box display="flex" flexDirection="column" rowGap={2} sx={{ p: isDesktop ? 2 : 3, pb: 8 }}>

            <Grid container>
              <Grid item md={7} sm={12} xs={12}>
                <Box display="flex" flexDirection="column" rowGap={1}>
                  <Typography variant="h2">
                    Thank you
                  </Typography>
                  <Typography variant="body2">
                    Your assessment for the
                    {' '}
                    <b>{jobListing?.name || ''}</b>
                    {' '}
                    job was submitted successfully.
                  </Typography>

                  <Box display="flex" flexDirection="column" rowGap={1} sx={{ pt: 3, maxWidth: 550 }}>
                    <Typography variant="h5">
                      Feedback
                    </Typography>
                    {!receivedFeedback && (
                      <Typography variant="body1">
                        How satisfied are you with the TEQ Connect assessment process?
                      </Typography>
                    )}

                    {!loading && (
                      <>
                        {receivedFeedback && (
                          <Box>
                            <Alert severity="success">
                              Your feedback has been received
                            </Alert>
                          </Box>
                        )}
                        {!receivedFeedback && (
                          <>
                            <Box display="flex" justifyContent="space-between" flexDirection="row" columnGap={1}>
                              <IconButton onClick={() => {
                                onSubmitFeedback(0);
                              }}
                              >
                                <Iconify icon="mdi:emoticon-frown-outline" width={isDesktop ? '4rem' : '2rem'} />
                              </IconButton>
                              <IconButton onClick={() => {
                                onSubmitFeedback(1);
                              }}
                              >
                                <Iconify icon="mdi:emoticon-confused-outline" width={isDesktop ? '4rem' : '2rem'} />
                              </IconButton>
                              <IconButton onClick={() => {
                                onSubmitFeedback(2);
                              }}
                              >
                                <Iconify icon="mdi:emoticon-neutral-outline" width={isDesktop ? '4rem' : '2rem'} />
                              </IconButton>
                              <IconButton onClick={() => {
                                onSubmitFeedback(3);
                              }}
                              >
                                <Iconify icon="mdi:emoticon-happy-outline" width={isDesktop ? '4rem' : '2rem'} />
                              </IconButton>
                              <IconButton onClick={() => {
                                onSubmitFeedback(4);
                              }}
                              >
                                <Iconify icon="mdi:emoticon-excited-outline" width={isDesktop ? '4rem' : '2rem'} />
                              </IconButton>
                            </Box>
                            <Box display="flex" flexDirection="row" justifyContent="space-between">
                              <Typography variant="body2">
                                Very unsatisfied
                              </Typography>
                              <Typography variant="body2">
                                Very satisfied
                              </Typography>
                            </Box>
                          </>
                        )}
                      </>
                    )}
                    {loading && (
                      <Box display="flex" sx={{ pt: 2 }} justifyContent="center">
                        <CircularProgress />
                      </Box>
                    )}

                  </Box>
                </Box>
              </Grid>
              <Grid item md={1} />
              <Grid item md={4}>
                {/* <Box sx={{ py: 3, }}>
              <Image
                src="/assets/placeholder.svg"
                ratio='16/9'
              />

              <Alert severity='info' sx={{ mt: 3, }}>
                TODO updated helper text
              </Alert>
            </Box> */}
              </Grid>

            </Grid>
          </Box>
        </ViewBox>
      </Box>
    </Box>
  );
}
