import { ListingCertificate } from '@piccadilly-cloud/connect-platform-services';

import { Box, Typography, useTheme } from '@mui/material';

import Iconify from 'src/components/iconify';
import { piccIcon } from 'src/utils/config';
import BoolBox from 'src/views/listing/listing-editor/steps/partials/bool-box';

export interface CredentialItemProps {
  certificate: ListingCertificate;
  value: boolean;
  onChange: (next: boolean) => void;
}

export default function CredentialItem(props: CredentialItemProps) {
  const { certificate, value, onChange } = props;
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="flex-start"
      width="100%"
      sx={{
        px: 2,
        py: 1,
      }}
    >
      <Box display="flex" flexDirection="column" rowGap={0.5}>
        <Box display="flex" flexDirection="row" alignItems="center" columnGap={1}>
          <Iconify icon={piccIcon.jobCredentials.certificates} sx={{ color: theme.palette.primary.main }} />
          <Typography
            variant="body1"
            fontWeight={500}
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-word',
            }}
          >
            {certificate.title}
          </Typography>
        </Box>
        <Typography variant="caption" color="textSecondary">
          {certificate.organization}
        </Typography>
        <Box>
          <BoolBox value={value} onChange={onChange} />
        </Box>
      </Box>
    </Box>
  );
}
