import services, {
  ApplicationAssessmentResponse,
  ApplicationAssessmentResponseData,
  AssessmentQuestion,
  AssessmentResponseType,
  PlatformIdResponse,
} from '@piccadilly-cloud/connect-platform-services';
import { Envelope } from '@piccadilly-cloud/connect-platform-services/build/util/platform';

import { Box, Typography, useTheme } from '@mui/material';

import { useMemo, useState } from 'react';

import Image from 'src/components/image';
import { useApplicationFlowContext, useSessionContext } from 'src/contexts';
import { TrackingEvent, useEventTracker, useToast } from 'src/hooks';
import { pxToRem } from 'src/theme/mui/typography';
import { generateId } from 'src/utils';

import DialogAnswerQuestion from '../assessment/dialog-answer-question';
import formValues from '../pcdl-wiz/form-values';
import palette from '../pcdl-wiz/palette';

export interface TabAssessmentQuestionProps {
  index: number;
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabAssessmentQuestion(props: TabAssessmentQuestionProps) {
  const {
    index,
    loading,
    onBack,
    onNext,
  } = props;

  const session = useSessionContext();
  const applicationFlowContext = useApplicationFlowContext();
  const eventTracker = useEventTracker();
  const toast = useToast();
  const theme = useTheme();

  const responseType = applicationFlowContext.application?.profile.assessment.responseType
    ?? AssessmentResponseType.TEXT;

  const currentQuestion: AssessmentQuestion & {
    main: string;
    light: string;
    lighter: string;
    lightest: string;
  } & { currentAnswer?: ApplicationAssessmentResponse } | undefined = useMemo(() => {
    const current = applicationFlowContext.jobListing?.assessmentQuestionIds[index];
    const questionData = applicationFlowContext.jobProfile?.teqAssessment.questions.allQuestions.find(
      (i) => i.id === current,
    );
    if (!questionData || !applicationFlowContext.application) {
      return undefined;
    }

    const currentAnswer = applicationFlowContext.application.profile.assessment.responseAnswers.find(
      (j) => j.jobProfileQuestionId === questionData.id,
    );
    return { ...questionData, ...palette.getColor(index), currentAnswer };
  }, [
    applicationFlowContext.application,
    applicationFlowContext.jobListing?.assessmentQuestionIds,
    applicationFlowContext.jobProfile?.teqAssessment.questions.allQuestions,
    index,
  ]);

  const [loadingSaveAnswer, setLoadingSaveAnswer] = useState(false);
  const [loadingDeleteAnswer, setLoadingDeleteAnswer] = useState(false);
  const onSaveProfile = async (answeredQuestion: ApplicationAssessmentResponseData) => {
    try {
      if (!applicationFlowContext.application) {
        throw new Error('Application not found');
      }

      setLoadingSaveAnswer(true);

      let fn: Envelope<PlatformIdResponse>;
      let updatedAnswer: ApplicationAssessmentResponse;
      if (currentQuestion && currentQuestion.currentAnswer) {
        fn = services.edge.candidate.application.updateAssessment_UpdateResponse(
          applicationFlowContext.application.id,
          session.account.email,
          currentQuestion.currentAnswer.id,
          answeredQuestion,
        );
        updatedAnswer = {
          ...currentQuestion.currentAnswer,
          type: answeredQuestion.type,
          recordedInBrowser: answeredQuestion.recordedInBrowser,
          processed: answeredQuestion.processed,
          rawText: answeredQuestion.rawText,
          documentId: answeredQuestion.documentId,
          documentPath: answeredQuestion.documentPath,
          encoding: answeredQuestion.encoding,
          language: answeredQuestion.language,
          sampleRateHertz: answeredQuestion.sampleRateHertz,
        };
      } else {
        fn = services.edge.candidate.application.updateAssessment_NewResponse(
          session.account.email,
          applicationFlowContext.application.id,
          answeredQuestion,
        );
        updatedAnswer = {
          id: generateId(),
          vendorId: session.appConfig.vendor,
          accountId: session.account.email,
          applicationId: applicationFlowContext.application.id,
          createdAt: new Date(),
          updatedAt: new Date(),
          deleted: false,
          jobProfileQuestionId: answeredQuestion.jobProfileQuestionId,
          type: answeredQuestion.type,
          recordedInBrowser: answeredQuestion.recordedInBrowser,
          processed: answeredQuestion.processed,
          rawText: answeredQuestion.rawText,
          documentId: answeredQuestion.documentId,
          documentPath: answeredQuestion.documentPath,
          sampleRateHertz: answeredQuestion.sampleRateHertz,
        };
      }

      if (!applicationFlowContext.isPreview) {
        const res = await fn({ token: session.token });
        updatedAnswer.id = res.id;
      }
      const nextProfile = formValues.onNextAssessmentQuestionAnswered(
        applicationFlowContext.application.profile,
        updatedAnswer,
      );

      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileAssessmentQuestionResponses']({
        responses: nextProfile.assessment.responseAnswers,
      });

      if (!applicationFlowContext.isPreview) {
        toast.success('Successfully saved response');
      }
      onNext();

      eventTracker.trackEvent(TrackingEvent.ASSESSMENT_QUESTION_SAVE_ANSWER_BTN, {
        applicationId: applicationFlowContext.application.id,
        answerId: updatedAnswer.id,
      });
    } catch (err) {
      console.error('something went wrong!', err);
      toast.error('Something went wrong! Please refresh and try again.');
    } finally {
      setLoadingSaveAnswer(false);
    }
  };

  const deleteResponse = async (questionId: string) => {
    try {
      if (!applicationFlowContext.application) {
        throw new Error('Application not found');
      }

      setLoadingDeleteAnswer(true);
      await services.edge.candidate.application.updateAssessment_DeleteResponse(
        applicationFlowContext.application.id,
        session.account.email,
        questionId,
      )({ token: session.token });

      const nextProfile = formValues.onNextAssessmentQuestionDeleted(
        applicationFlowContext.application.profile,
        questionId,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileAssessmentQuestionResponses']({
        responses: nextProfile.assessment.responseAnswers,
      });

      if (!applicationFlowContext.isPreview) {
        toast.success('Successfully deleted response');
      }
      eventTracker.trackEvent(TrackingEvent.ASSESSMENT_QUESTION_DELETE_ANSWER_BTN, {
        applicationId: applicationFlowContext.application.id,
        answerId: questionId,
      });
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong! Please refresh and try again.');
    } finally {
      setLoadingDeleteAnswer(false);
    }
  };

  if (!currentQuestion || !applicationFlowContext.jobProfile) {
    return (<>Something went wrong! Please refresh and try again.</>);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        px: 2,
        pt: 2,
      }}
      rowGap={2}
    >
      <Box>
        <Image
          src="/logo/teq-interview-logo.svg"
          width="185px"
          disabledEffect
        />
        <Typography
          fontSize={pxToRem(14)}
          color={theme.palette.grey[500]}
          sx={{ pt: 1 }}
        >
          Question {index + 1} of {applicationFlowContext.jobListing?.assessmentQuestionIds.length}
        </Typography>
      </Box>

      <DialogAnswerQuestion
        onSave={onSaveProfile}
        onBack={onBack}
        question={currentQuestion}
        loading={loading || loadingSaveAnswer}
        loadingDelete={loadingDeleteAnswer}
        onDelete={deleteResponse}
        jobProfile={applicationFlowContext.jobProfile}
        responseType={responseType}
      />
    </Box>
  );
}
