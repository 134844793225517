import { Box, Divider, MenuItem } from '@mui/material';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import FormProvider, { RHFSelect } from 'src/components/hook-form';
import Image from 'src/components/image/image';
import { useApplicationFlowContext } from 'src/contexts';

import ControlRow from '../control-row';
import AdditionalEducationForm from '../partials/additional-education-form';
import QuestionCard from '../partials/question-card';
import formDefaults from '../pcdl-wiz/form-defaults';
import formOptions from '../pcdl-wiz/form-options';
import formSchemas from '../pcdl-wiz/form-schemas';
import { TabEducationForm } from '../pcdl-wiz/form-types';
import formValues from '../pcdl-wiz/form-values';

export interface TabEducationProps {
  loading: boolean;
  onNext: VoidFunction;
  onBack: VoidFunction;
}

export default function TabEducation(props: TabEducationProps) {
  const {
    loading,
    onBack,
    onNext,
  } = props;
  const applicationFlowContext = useApplicationFlowContext();

  const methods = useForm<TabEducationForm>({
    resolver: !applicationFlowContext.isPreview
      ? yupResolver(formSchemas.tabEducation)
      : undefined,
    defaultValues: applicationFlowContext.application?.profile
      ? formDefaults.tabEducation(applicationFlowContext.application.profile)
      : undefined,
  });

  const onSubmit = methods.handleSubmit(
    (data: TabEducationForm) => {
      if (!applicationFlowContext.application?.profile) {
        throw new Error('Failed to update education');
      }

      const updatedCandidateProfile = formValues.onNextEducation(
        applicationFlowContext.application.profile,
        data,
      );
      applicationFlowContext.dispatch['applicationFlow/setCandidateProfileEducation']({
        education: updatedCandidateProfile.education,
      });
      onNext();
    },
    (i) => console.error(i),
  );
  const {
    setValue,
    watch,
  } = methods;

  const formValue = watch();

  const handleAddAdditionalEducation = () => {
    setValue('additionalEducation', [...formValue.additionalEducation, { type: '', progress: null }]);
  };

  const handleRemoveAdditionalEducation = (index: number) => {
    if (formValue.additionalEducation.length > 1) {
      setValue('additionalEducation', formValue.additionalEducation.filter((_, i) => i !== index));
    } else {
      setValue('additionalEducation', [{ type: '', progress: null }]);
    }
  };

  const handleUpdateAdditionalEducation = (index: number, type: string, progress: number) => {
    const updated = [...formValue.additionalEducation];
    updated[index] = { type, progress };
    setValue('additionalEducation', updated);
  };

  const renderAdditionalEducation = () => {
    if (!applicationFlowContext.jobListing?.additionalEducation
      || applicationFlowContext.jobListing.additionalEducation.length <= 0) {
      return null;
    }

    return (
      <QuestionCard title="Select additional education or training you may have">
        <Box>
          <AdditionalEducationForm
            fields={formValue.additionalEducation}
            onAdd={handleAddAdditionalEducation}
            onRemove={handleRemoveAdditionalEducation}
            onUpdate={handleUpdateAdditionalEducation}
          />
        </Box>
      </QuestionCard>
    );
  };

  return (
    <FormProvider methods={methods}>
      <Box display="flex" flexDirection="column" rowGap={2}>
        <Box display="flex" flexDirection="row" justifyContent="center" sx={{ py: 3 }}>
          <Image
            height="120px"
            src="/assets/illustrations/candidate-application-job-experience.svg"
          />
        </Box>

        <QuestionCard title="What is your highest level of education?">
          <Box>
            <RHFSelect name="educationLevel" label="" size="small">
              <MenuItem value="" disabled>Choose education level</MenuItem>
              <Divider sx={{ borderStyle: 'dashed' }} />
              {formOptions.educationLevel.map((i) => (
                <MenuItem
                  key={i.label}
                  value={i.value}
                  id={`${i.value}`}
                  sx={{ whiteSpace: 'normal' }}
                >
                  {i.label}
                </MenuItem>
              ))}
            </RHFSelect>
          </Box>
        </QuestionCard>
        {renderAdditionalEducation()}
        <ControlRow
          onBack={onBack}
          onNext={onSubmit}
          loading={loading}
          isEditing={applicationFlowContext.isEditing}
        />
      </Box>
    </FormProvider>
  );
}
